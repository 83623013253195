<div layout="row" layout-margin>
  <div flex>
    <md-input-container flex>
      <label>{{::'order.itemDialog.lastName' | translate}}</label>
      <input
        type="text"
        required
        name="lastName"
        ng-model="itemDialog.item.lastName"
        ng-minlength="2"
        ng-maxlength="50"
      />
      <wb-messages
        form="itemDialog.itemDialogForm"
        server="itemDialog.getServerValidation()"
        field-name="lastName"
      ></wb-messages>
    </md-input-container>

    <md-input-container flex>
      <label>{{::'order.itemDialog.firstName' | translate}}</label>
      <input
        type="text"
        required
        name="firstName"
        ng-model="itemDialog.item.firstName"
        ng-minlength="2"
        ng-maxlength="50"
      />
      <wb-messages
        form="itemDialog.itemDialogForm"
        server="itemDialog.getServerValidation()"
        field-name="firstName"
      ></wb-messages>
    </md-input-container>

    <div flex flex-gt-lg="20" class="multi-input">
      <label
        class="multi-input__custom-label"
        hide-sm
        >{{::'order.itemDialog.dob.full' | translate}}</label
      >
      <div layout="row">
        <md-input-container flex flex-gt-sm="20">
          <label
            class="multi-input__custom-label"
            hide-gt-sm
            >{{::'order.itemDialog.dob.label' | translate}}</label
          >
          <input
            type="number"
            ng-minlength="4"
            ng-maxlength="4"
            min="1900"
            ng-max="itemDialog.maxDateOfBirth()"
            required
            name="dateOfBirth.year"
            ng-model="itemDialog.dateOfBirth.year"
          />
          <ng-messages
            class="ng-messages"
            for="itemDialog.itemDialogForm['dateOfBirth.year'].$error"
            role="alert"
            hide-gt-sm
          >
            <div ng-message="required">
              {{::'form.validation.required' | translate}}
            </div>
            <div ng-message="minlength">
              {{::'form.validation.minlength' | translate}}
            </div>
            <div ng-message="maxlength">
              {{::'form.validation.maxlength' | translate}}
            </div>
            <div ng-message="min">{{::'form.validation.min' | translate}}</div>
            <div ng-message="max">{{::'form.validation.max' | translate}}</div>
          </ng-messages>
          <wb-messages
            form="itemDialog.itemDialogForm"
            server="itemDialog.getServerValidation()"
            class="visible"
            field-name="dateOfBirth"
            hide-gt-sm
          ></wb-messages>
        </md-input-container>
        <md-input-container flex flex-gt-sm="20" offset-gt-sm="5">
          <label
            class="multi-input__custom-label"
            hide-gt-sm
            >{{::'order.itemDialog.dob.month.label' | translate}}</label
          >
          <input
            type="number"
            ng-minlength="1"
            ng-maxlength="2"
            min="1"
            max="12"
            required
            name="dateOfBirth.month"
            ng-model="itemDialog.dateOfBirth.month"
          />
          <ng-messages
            class="ng-messages"
            for="itemDialog.itemDialogForm['dateOfBirth.month'].$error"
            role="alert"
            hide-gt-sm
          >
            <div ng-message="required">
              {{::'form.validation.required' | translate}}
            </div>
            <div ng-message="minlength">
              {{::'form.validation.minlength' | translate}}
            </div>
            <div ng-message="maxlength">
              {{::'form.validation.maxlength' | translate}}
            </div>
            <div ng-message="min">{{::'form.validation.min' | translate}}</div>
            <div ng-message="max">{{::'form.validation.max' | translate}}</div>
          </ng-messages>
          <wb-messages
            form="itemDialog.itemDialogForm"
            server="itemDialog.getServerValidation()"
            class="visible"
            field-name="dateOfBirth"
            hide-gt-sm
          ></wb-messages>
        </md-input-container>
        <md-input-container flex flex-gt-sm="20" offset-gt-sm="5">
          <label
            class="multi-input__custom-label"
            hide-gt-sm
            >{{::'order.itemDialog.dob.day.label' | translate}}</label
          >
          <input
            type="number"
            ng-minlength="1"
            ng-maxlength="2"
            min="1"
            max="31"
            required
            name="dateOfBirth.day"
            ng-model="itemDialog.dateOfBirth.day"
          />
          <ng-messages
            class="ng-messages"
            for="itemDialog.itemDialogForm['dateOfBirth.day'].$error"
            role="alert"
            hide-gt-sm
          >
            <div ng-message="required">
              {{::'form.validation.required' | translate}}
            </div>
            <div ng-message="minlength">
              {{::'form.validation.minlength' | translate}}
            </div>
            <div ng-message="maxlength">
              {{::'form.validation.maxlength' | translate}}
            </div>
            <div ng-message="min">{{::'form.validation.min' | translate}}</div>
            <div ng-message="max">{{::'form.validation.max' | translate}}</div>
          </ng-messages>
          <wb-messages
            form="itemDialog.itemDialogForm"
            server="itemDialog.getServerValidation()"
            class="visible"
            field-name="dateOfBirth"
            hide-gt-sm
          ></wb-messages>
        </md-input-container>
      </div>

      <div layout="column" hide-sm>
        <div class="multi-input__error-messages">
          <wb-messages
            form="itemDialog.itemDialogForm"
            server="itemDialog.getServerValidation()"
            class="visible"
            field-name="dateOfBirth"
          ></wb-messages>
          <ng-messages
            class="ng-messages"
            for="itemDialog.itemDialogForm['dateOfBirth.year'].$error"
            role="alert"
          >
            <div ng-message="required">
              {{::'order.itemDialog.dob.year' | translate}}:
              {{::'form.validation.required' | translate}}
            </div>
            <div ng-message="minlength">
              {{::'order.itemDialog.dob.year' | translate}}:
              {{::'form.validation.minlength' | translate}}
            </div>
            <div ng-message="maxlength">
              {{::'order.itemDialog.dob.year' | translate}}:
              {{::'form.validation.maxlength' | translate}}
            </div>
            <div ng-message="min">
              {{::'order.itemDialog.dob.year' | translate}}:
              {{::'form.validation.min' | translate}}
            </div>
            <div ng-message="max">
              {{::'order.itemDialog.dob.year' | translate}}:
              {{::'form.validation.max' | translate}}
            </div>
          </ng-messages>
          <ng-messages
            class="ng-messages"
            for="itemDialog.itemDialogForm['dateOfBirth.month'].$error"
            role="alert"
          >
            <div ng-message="required">
              {{::'order.itemDialog.dob.month' | translate}}:
              {{::'form.validation.required' | translate}}
            </div>
            <div ng-message="minlength">
              {{::'order.itemDialog.dob.month' | translate}}:
              {{::'form.validation.minlength' | translate}}
            </div>
            <div ng-message="maxlength">
              {{::'order.itemDialog.dob.month' | translate}}:
              {{::'form.validation.maxlength' | translate}}
            </div>
            <div ng-message="min">
              {{::'order.itemDialog.dob.month' | translate}}:
              {{::'form.validation.min' | translate}}
            </div>
            <div ng-message="max">
              {{::'order.itemDialog.dob.month' | translate}}:
              {{::'form.validation.max' | translate}}
            </div>
          </ng-messages>
          <ng-messages
            class="ng-messages"
            for="itemDialog.itemDialogForm['dateOfBirth.day'].$error"
            role="alert"
          >
            <div ng-message="required">
              {{::'order.itemDialog.dob.day' | translate}}:
              {{::'form.validation.required' | translate}}
            </div>
            <div ng-message="minlength">
              {{::'order.itemDialog.dob.day' | translate}}:
              {{::'form.validation.minlength' | translate}}
            </div>
            <div ng-message="maxlength">
              {{::'order.itemDialog.dob.day' | translate}}:
              {{::'form.validation.maxlength' | translate}}
            </div>
            <div ng-message="min">
              {{::'order.itemDialog.dob.day' | translate}}:
              {{::'form.validation.min' | translate}}
            </div>
            <div ng-message="max">
              {{::'order.itemDialog.dob.day' | translate}}:
              {{::'form.validation.max' | translate}}
            </div>
          </ng-messages>
        </div>
      </div>
    </div>

    <md-input-container flex>
      <label>{{::'order.itemDialog.nameOnCard' | translate}}</label>
      <input
        type="text"
        required
        name="nameOnCard"
        ng-model="itemDialog.item.nameOnCard"
        ng-minlength="1"
        ng-maxlength="21"
      />
      <wb-messages
        form="itemDialog.itemDialogForm"
        server="itemDialog.getServerValidation()"
        field-name="nameOnCard"
      ></wb-messages>
    </md-input-container>
  </div>

  <div flex>
    <div layout="row">
      <md-input-container flex="35">
        <label>{{::'order.companyData.zipCode' | translate}}</label>
        <input
          zip-to-city="address.zipCode"
          receive="false"
          type="text"
          required
          name="address.zipCode"
          ng-model="itemDialog.item.address.zipCode"
          ng-minlength="1"
          ng-maxlength="10"
        />
        <wb-messages
          form="itemDialog.itemDialogForm"
          server="itemDialog.getServerValidation()"
          field-name="address.zipCode"
        ></wb-messages>
      </md-input-container>

      <md-input-container flex="65">
        <label>{{::'order.companyData.city' | translate}}</label>
        <input
          zip-to-city="address.zipCode"
          receive="true"
          type="text"
          required
          name="address.city"
          ng-model="itemDialog.item.address.city"
          ng-minlength="1"
          ng-maxlength="100"
        />
        <wb-messages
          form="itemDialog.itemDialogForm"
          server="itemDialog.getServerValidation()"
          field-name="address.city"
        ></wb-messages>
      </md-input-container>
    </div>

    <div layout="row">
      <md-input-container flex="35">
        <label>{{::'account.company.address.countryCode' | translate}}</label>
        <md-select
          placeholder="{{::'account.company.address.countryCode' | translate}}"
          ng-model="itemDialog.item.address.countryCode"
          required
          name="address.countryCode"
          class="full-width-select"
        >
          <md-option
            ng-repeat="country in itemDialog.countries"
            value="{{ country.alpha3 }}"
          >
            {{ country.name }}
          </md-option>
        </md-select>
        <wb-messages
          form="itemDialog.itemDialogForm"
          server="itemDialog.getServerValidation()"
          field-name="address.countryCode"
        ></wb-messages>
      </md-input-container>

      <md-input-container flex="65">
        <label>{{::'order.companyData.address' | translate}}</label>
        <input
          type="text"
          required
          name="address.address"
          ng-model="itemDialog.item.address.address"
          ng-minlength="5"
          ng-maxlength="100"
        />
        <wb-messages
          form="itemDialog.itemDialogForm"
          server="itemDialog.getServerValidation()"
          field-name="address.address"
        ></wb-messages>
      </md-input-container>
    </div>

    <md-input-container flex>
      <label>{{::'account.contact.email' | translate}}</label>
      <input
        required
        ng-model="itemDialog.item.email"
        name="email"
        type="email"
      />
      <wb-messages
        form="itemDialog.itemDialogForm"
        server="itemDialog.getServerValidation()"
        field-name="email"
      ></wb-messages>
    </md-input-container>

    <md-input-container flex>
      <label>{{::'account.contact.phone' | translate}}</label>
      <input
        ng-model="itemDialog.item.phone"
        name="phone"
        type="text"
        ui-mask="+36-99-999-9998"
        mask-fix
        model-view-value="true"
      />
      <wb-messages
        form="itemDialog.itemDialogForm"
        server="itemDialog.getServerValidation()"
        field-name="phone"
      ></wb-messages>
    </md-input-container>
  </div>
</div>

<div layout="row" layout-margin>
  <div flex>
    <h3 class="wb-item-dialog-form-section-title">
      {{::'order.itemDialog.card.title' | translate}}
    </h3>

    <md-input-container flex>
      <label>{{::'order.itemDialog.card.cardType' | translate}}</label>
      <md-select
        placeholder="{{::'order.itemDialog.card.cardType' | translate}}"
        ng-model="itemDialog.item.cardTypeId"
        required
        name="cardTypeId"
        class="full-width-select"
      >
        <md-option
          ng-repeat="cardType in itemDialog.cardTypes"
          value="{{ cardType.id }}"
        >
          {{ cardType.title }}
        </md-option>
      </md-select>
      <wb-messages
        form="itemDialog.itemDialogForm"
        server="itemDialog.getServerValidation()"
        field-name="cardTypeId"
      ></wb-messages>
    </md-input-container>

    <md-input-container flex>
      <label>{{::'order.itemDialog.card.amount' | translate}}</label>
      <input
        type="text"
        ng-required="itemDialog.selectedProductType !== 'family'"
        name="loadAmount"
        ng-model="itemDialog.item.loadAmount"
        ng-minlength="1"
        ng-maxlength="12"
      />
      <wb-messages
        form="itemDialog.itemDialogForm"
        server="itemDialog.getServerValidation()"
        field-name="loadAmount"
      ></wb-messages>
    </md-input-container>
  </div>

  <div ng-if="itemDialog.isNewCustomer()" flex>
    <h3 class="wb-item-dialog-form-section-title">
      {{::'order.itemDialog.cardTypes.title' | translate}}
    </h3>

    <ul>
      <li ng-repeat="cardType in itemDialog.cardTypes">
        <a ng-href="{{ cardType.url }}" target="_blank">{{ cardType.title }}</a>
      </li>
    </ul>
  </div>

  <div ng-if="!itemDialog.isNewCustomer()" flex>
    <md-button
      class="md-raised"
      type="button"
      ng-click="itemDialog.openComparisonDialog()"
    >
      <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-card"></md-icon>
      {{::'order.itemDialog.cardTypes.title' | translate}}
    </md-button>
  </div>
</div>
