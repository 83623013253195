<md-card
  ng-if="::wbOrderHistory.showFilter"
  class="wb-order-history-filter md-background"
>
  <md-card-content>
    <h2 class="md-title">{{::'order.filter' | translate}}</h2>

    <form
      name="orderFilterForm"
      novalidate
      ng-submit="orderFilterForm.$valid && !wbOrderHistory.isProcessing() && wbOrderHistory.filterSubmit()"
    >
      <div
        layout-gt-lg="row"
        layout="column"
        layout-align-gt-lg="space-between start"
      >

        <div flex flex-gt-lg="20" offset-gt-sm="5" class="multi-input">
          <label
            class="multi-input__custom-label"
            hide-sm
            >{{::'order.filter.dateFrom' | translate}}</label
          >
          <div layout="row">
            <md-input-container flex flex-gt-sm="33">
              <label
                class="multi-input__custom-label"
                hide-gt-sm
                >{{::'order.filter.year.label' | translate}}</label
              >
              <input
                type="number"
                ng-minlength="4"
                ng-maxlength="4"
                min="1900"
                max="{{::wbOrderHistory.currentYear}}"
                required
                name="dateFrom.year"
                ng-model="wbOrderHistory.dateFrom.year"
              />
              <ng-messages
                class="ng-messages"
                for="orderFilterForm['dateFrom.year'].$error"
                role="alert"
                hide-gt-sm
              >
                <div ng-message="required">
                  {{::'form.validation.required' | translate}}
                </div>
                <div ng-message="minlength">
                  {{::'form.validation.minlength' | translate}}
                </div>
                <div ng-message="maxlength">
                  {{::'form.validation.maxlength' | translate}}
                </div>
                <div ng-message="min">
                  {{::'form.validation.min' | translate}}
                </div>
                <div ng-message="max">
                  {{::'form.validation.max' | translate}}
                </div>
              </ng-messages>
              <wb-messages
                form="orderFilterForm"
                server="wbOrderHistory.getServerValidation()"
                class="visible"
                field-name="dob"
                hide-gt-sm
              ></wb-messages>
            </md-input-container>
            <md-input-container flex flex-gt-sm="33" offset-gt-sm="5">
              <label
                class="multi-input__custom-label"
                hide-gt-sm
                >{{::'order.filter.month.label' | translate}}</label
              >
              <input
                type="number"
                ng-minlength="1"
                ng-maxlength="2"
                min="1"
                max="12"
                required
                name="dateFrom.month"
                ng-model="wbOrderHistory.dateFrom.month"
              />
              <ng-messages
                class="ng-messages"
                for="orderFilterForm['dateFrom.month'].$error"
                role="alert"
                hide-gt-sm
              >
                <div ng-message="required">
                  {{::'form.validation.required' | translate}}
                </div>
                <div ng-message="minlength">
                  {{::'form.validation.minlength' | translate}}
                </div>
                <div ng-message="maxlength">
                  {{::'form.validation.maxlength' | translate}}
                </div>
                <div ng-message="min">
                  {{::'form.validation.min' | translate}}
                </div>
                <div ng-message="max">
                  {{::'form.validation.max' | translate}}
                </div>
              </ng-messages>
              <wb-messages
                form="orderFilterForm"
                server="wbOrderHistory.getServerValidation()"
                class="visible"
                field-name="dob"
                hide-gt-sm
              ></wb-messages>
            </md-input-container>
            <md-input-container flex flex-gt-sm="33" offset-gt-sm="5">
              <label
                class="multi-input__custom-label"
                hide-gt-sm
                >{{::'order.filter.day.label' | translate}}</label
              >
              <input
                type="number"
                ng-minlength="1"
                ng-maxlength="2"
                min="1"
                max="31"
                required
                name="dateFrom.day"
                ng-model="wbOrderHistory.dateFrom.day"
              />
              <ng-messages
                class="ng-messages"
                for="orderFilterForm['dateFrom.day'].$error"
                role="alert"
                hide-gt-sm
              >
                <div ng-message="required">
                  {{::'form.validation.required' | translate}}
                </div>
                <div ng-message="minlength">
                  {{::'form.validation.minlength' | translate}}
                </div>
                <div ng-message="maxlength">
                  {{::'form.validation.maxlength' | translate}}
                </div>
                <div ng-message="min">
                  {{::'form.validation.min' | translate}}
                </div>
                <div ng-message="max">
                  {{::'form.validation.max' | translate}}
                </div>
              </ng-messages>
              <wb-messages
                form="orderFilterForm"
                server="wbOrderHistory.getServerValidation()"
                class="visible"
                field-name="dob"
                hide-gt-sm
              ></wb-messages>
            </md-input-container>
          </div>

          <div layout="column" hide-sm>
            <div class="multi-input__error-messages">
              <wb-messages
                form="orderFilterForm"
                server="wbOrderHistory.getServerValidation()"
                class="visible"
                field-name="dob"
              ></wb-messages>
              <ng-messages
                class="ng-messages"
                for="orderFilterForm['dateFrom.year'].$error"
                role="alert"
              >
                <div ng-message="required">
                  {{::'order.filter.year' | translate}}:
                  {{::'form.validation.required' | translate}}
                </div>
                <div ng-message="minlength">
                  {{::'order.filter.year' | translate}}:
                  {{::'form.validation.minlength' | translate}}
                </div>
                <div ng-message="maxlength">
                  {{::'order.filter.year' | translate}}:
                  {{::'form.validation.maxlength' | translate}}
                </div>
                <div ng-message="min">
                  {{::'order.filter.year' | translate}}:
                  {{::'form.validation.min' | translate}}
                </div>
                <div ng-message="max">
                  {{::'order.filter.year' | translate}}:
                  {{::'form.validation.max' | translate}}
                </div>
              </ng-messages>
              <ng-messages
                class="ng-messages"
                for="orderFilterForm['dateFrom.month'].$error"
                role="alert"
              >
                <div ng-message="required">
                  {{::'order.filter.month' | translate}}:
                  {{::'form.validation.required' | translate}}
                </div>
                <div ng-message="minlength">
                  {{::'order.filter.month' | translate}}:
                  {{::'form.validation.minlength' | translate}}
                </div>
                <div ng-message="maxlength">
                  {{::'order.filter.month' | translate}}:
                  {{::'form.validation.maxlength' | translate}}
                </div>
                <div ng-message="min">
                  {{::'order.filter.month' | translate}}:
                  {{::'form.validation.min' | translate}}
                </div>
                <div ng-message="max">
                  {{::'order.filter.month' | translate}}:
                  {{::'form.validation.max' | translate}}
                </div>
              </ng-messages>
              <ng-messages
                class="ng-messages"
                for="orderFilterForm['dateFrom.day'].$error"
                role="alert"
              >
                <div ng-message="required">
                  {{::'order.filter.day' | translate}}:
                  {{::'form.validation.required' | translate}}
                </div>
                <div ng-message="minlength">
                  {{::'order.filter.day' | translate}}:
                  {{::'form.validation.minlength' | translate}}
                </div>
                <div ng-message="maxlength">
                  {{::'order.filter.day' | translate}}:
                  {{::'form.validation.maxlength' | translate}}
                </div>
                <div ng-message="min">
                  {{::'order.filter.day' | translate}}:
                  {{::'form.validation.min' | translate}}
                </div>
                <div ng-message="max">
                  {{::'order.filter.day' | translate}}:
                  {{::'form.validation.max' | translate}}
                </div>
              </ng-messages>
            </div>
          </div>
        </div>

        <div flex flex-gt-lg="20" offset-gt-sm="5" class="multi-input">
          <label
            class="multi-input__custom-label"
            hide-sm
            >{{::'order.filter.dateTo' | translate}}</label
          >
          <div layout="row">
            <md-input-container flex flex-gt-sm="33">
              <label
                class="multi-input__custom-label"
                hide-gt-sm
                >{{::'order.filter.year.label' | translate}}</label
              >
              <input
                type="number"
                ng-minlength="4"
                ng-maxlength="4"
                min="1900"
                max="{{::wbOrderHistory.currentYear+4}}"
                required
                name="dateTo.year"
                ng-model="wbOrderHistory.dateTo.year"
              />
              <ng-messages
                class="ng-messages"
                for="orderFilterForm['dateTo.year'].$error"
                role="alert"
                hide-gt-sm
              >
                <div ng-message="required">
                  {{::'form.validation.required' | translate}}
                </div>
                <div ng-message="minlength">
                  {{::'form.validation.minlength' | translate}}
                </div>
                <div ng-message="maxlength">
                  {{::'form.validation.maxlength' | translate}}
                </div>
                <div ng-message="min">
                  {{::'form.validation.min' | translate}}
                </div>
                <div ng-message="max">
                  {{::'form.validation.max' | translate}}
                </div>
              </ng-messages>
              <wb-messages
                form="orderFilterForm"
                server="wbOrderHistory.getServerValidation()"
                class="visible"
                field-name="dob"
                hide-gt-sm
              ></wb-messages>
            </md-input-container>
            <md-input-container flex flex-gt-sm="33" offset-gt-sm="5">
              <label
                class="multi-input__custom-label"
                hide-gt-sm
                >{{::'order.filter.month.label' | translate}}</label
              >
              <input
                type="number"
                ng-minlength="1"
                ng-maxlength="2"
                min="1"
                max="12"
                required
                name="dateTo.month"
                ng-model="wbOrderHistory.dateTo.month"
              />
              <ng-messages
                class="ng-messages"
                for="orderFilterForm['dateTo.month'].$error"
                role="alert"
                hide-gt-sm
              >
                <div ng-message="required">
                  {{::'form.validation.required' | translate}}
                </div>
                <div ng-message="minlength">
                  {{::'form.validation.minlength' | translate}}
                </div>
                <div ng-message="maxlength">
                  {{::'form.validation.maxlength' | translate}}
                </div>
                <div ng-message="min">
                  {{::'form.validation.min' | translate}}
                </div>
                <div ng-message="max">
                  {{::'form.validation.max' | translate}}
                </div>
              </ng-messages>
              <wb-messages
                form="orderFilterForm"
                server="wbOrderHistory.getServerValidation()"
                class="visible"
                field-name="dob"
                hide-gt-sm
              ></wb-messages>
            </md-input-container>
            <md-input-container flex flex-gt-sm="33" offset-gt-sm="5">
              <label
                class="multi-input__custom-label"
                hide-gt-sm
                >{{::'order.filter.day.label' | translate}}</label
              >
              <input
                type="number"
                ng-minlength="1"
                ng-maxlength="2"
                min="1"
                max="31"
                required
                name="dateTo.day"
                ng-model="wbOrderHistory.dateTo.day"
              />
              <ng-messages
                class="ng-messages"
                for="orderFilterForm['dateTo.day'].$error"
                role="alert"
                hide-gt-sm
              >
                <div ng-message="required">
                  {{::'form.validation.required' | translate}}
                </div>
                <div ng-message="minlength">
                  {{::'form.validation.minlength' | translate}}
                </div>
                <div ng-message="maxlength">
                  {{::'form.validation.maxlength' | translate}}
                </div>
                <div ng-message="min">
                  {{::'form.validation.min' | translate}}
                </div>
                <div ng-message="max">
                  {{::'form.validation.max' | translate}}
                </div>
              </ng-messages>
              <wb-messages
                form="orderFilterForm"
                server="wbOrderHistory.getServerValidation()"
                class="visible"
                field-name="dob"
                hide-gt-sm
              ></wb-messages>
            </md-input-container>
          </div>

          <div layout="column" hide-sm>
            <div class="multi-input__error-messages">
              <wb-messages
                form="orderFilterForm"
                server="wbOrderHistory.getServerValidation()"
                class="visible"
                field-name="dob"
              ></wb-messages>
              <ng-messages
                class="ng-messages"
                for="orderFilterForm['dateTo.year'].$error"
                role="alert"
              >
                <div ng-message="required">
                  {{::'order.filter.year' | translate}}:
                  {{::'form.validation.required' | translate}}
                </div>
                <div ng-message="minlength">
                  {{::'order.filter.year' | translate}}:
                  {{::'form.validation.minlength' | translate}}
                </div>
                <div ng-message="maxlength">
                  {{::'order.filter.year' | translate}}:
                  {{::'form.validation.maxlength' | translate}}
                </div>
                <div ng-message="min">
                  {{::'order.filter.year' | translate}}:
                  {{::'form.validation.min' | translate}}
                </div>
                <div ng-message="max">
                  {{::'order.filter.year' | translate}}:
                  {{::'form.validation.max' | translate}}
                </div>
              </ng-messages>
              <ng-messages
                class="ng-messages"
                for="orderFilterForm['dateTo.month'].$error"
                role="alert"
              >
                <div ng-message="required">
                  {{::'order.filter.month' | translate}}:
                  {{::'form.validation.required' | translate}}
                </div>
                <div ng-message="minlength">
                  {{::'order.filter.month' | translate}}:
                  {{::'form.validation.minlength' | translate}}
                </div>
                <div ng-message="maxlength">
                  {{::'order.filter.month' | translate}}:
                  {{::'form.validation.maxlength' | translate}}
                </div>
                <div ng-message="min">
                  {{::'order.filter.month' | translate}}:
                  {{::'form.validation.min' | translate}}
                </div>
                <div ng-message="max">
                  {{::'order.filter.month' | translate}}:
                  {{::'form.validation.max' | translate}}
                </div>
              </ng-messages>
              <ng-messages
                class="ng-messages"
                for="orderFilterForm['dateTo.day'].$error"
                role="alert"
              >
                <div ng-message="required">
                  {{::'order.filter.day' | translate}}:
                  {{::'form.validation.required' | translate}}
                </div>
                <div ng-message="minlength">
                  {{::'order.filter.day' | translate}}:
                  {{::'form.validation.minlength' | translate}}
                </div>
                <div ng-message="maxlength">
                  {{::'order.filter.day' | translate}}:
                  {{::'form.validation.maxlength' | translate}}
                </div>
                <div ng-message="min">
                  {{::'order.filter.day' | translate}}:
                  {{::'form.validation.min' | translate}}
                </div>
                <div ng-message="max">
                  {{::'order.filter.day' | translate}}:
                  {{::'form.validation.max' | translate}}
                </div>
              </ng-messages>
            </div>
          </div>
        </div>

        <div flex-gt-lg="15" offset-gt-lg="5">
          <div flex class="button-wrapper text-center">
            <md-button
              ng-if="!wbOrderHistory.isProcessing()"
              class="md-raised md-primary full-width-button"
              type="submit"
            >
              {{::'order.filter.submit' | translate}}
            </md-button>
            <md-progress-circular
              ng-if="wbOrderHistory.isProcessing()"
              class="md-accent button-loader"
              md-mode="indeterminate"
              md-diameter="33"
            ></md-progress-circular>
          </div>
        </div>
      </div>
    </form>
  </md-card-content>
</md-card>

<md-card class="wb-order-history">
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">{{::'order.history' | translate}}</div>
  </md-toolbar>

  <md-card-content>
    <div class="wb-table">
      <div class="wb-table-header" layout="row">
        <span flex="15">{{::'order.created' | translate}}</span>
        <span flex="25">{{::'order.acceptor' | translate}}</span>
        <span flex="15" class="text-center">{{::'order.status' | translate}}</span>
        <span flex="10">{{::'order.product' | translate}}</span>
        <span flex="20" class="text-right">{{::'order.amount' | translate}}</span>
        <span flex="10" class="text-right"></span>
        <span flex="5" class="text-right"></span>
      </div>

      <div class="wb-table-item"
           layout="row"
           layout-align="center center"
           ng-repeat="order in wbOrderHistory.getOrders() track by order.id">
        <span flex="15">{{::order.orderDate | date:'yyyy.MM.dd'}}</span>
        <span flex="25">{{::order | orderName}}</span>
        <span flex="15" class="text-center">
          <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw {{::order | orderStatusClassName:true}}"></md-icon>
        </span>
        <span flex="10">{{::'order.history.card.' + order.category + '.title' | translate}}</span>
        <span flex="20" class="text-right">{{::order.totalGross | isoCurrency:'HUF':0}}</span>
        <span flex="10" class="text-right">
          <md-icon
            class="wb-generate-from-previous"
            md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-flip-to-front"
            analytics-on="click"
            analytics-event="{{::'order.steps.analytics.typeSelector.authenticated' | translate}}"
            analytics-category="{{::'order.steps.analytics.registration.2' | translate}}"
            analytics-label="{{::'order.steps.analytics.duplicate' | translate}}"
            ng-click="wbOrderHistory.generateNewOrderByItem(order)"
            ng-disabled="wbOrderHistory.isDuplicationDisabled()"
            ng-if="order.type === 2"
          >
            <md-tooltip
              >{{::'order.generateFromPrevious' | translate}}</md-tooltip
            >
          </md-icon>
        </span>
        <span flex="5" class="text-center">
          <md-icon ng-if="order.source && order.source !== 'web'" class="wb-tooltip zmdi zmdi-info zmdi-hc-lg">
            <md-tooltip>{{::'order.history.info.source' | translate}} {{::'order.history.info.source.' + order.source | translate}}</md-tooltip>
          </md-icon>
        </span>
      </div>
    </div>
  </md-card-content>

  <md-card-footer layout="row" layout-align="space-between center">
    <div
      layout="row"
      layout-padding
      flex
      layout-align="start center"
      class="wb-transaction-list-legend"
    >
      <span
        ng-repeat="legend in ::wbOrderHistory.legends"
        class="markable-legend"
      >
        <md-icon
          md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw {{::legend | orderStatusClassName}}"
        ></md-icon>
        {{::'order.type.' + legend.type | translate}}
      </span>
    </div>
    <md-button
      ng-if="::wbOrderHistory.limit"
      ui-sref="orders"
      class="md-primary no-side-margin"
      offset-gt-sm="5"
    >
      {{::'order.more' | translate}}
    </md-button>

    <div
      ng-if="::!wbOrderHistory.limit"
      class="wb-order-history-pagination"
      layout="row"
      layout-align="space-between center"
    >
      <span hide-gt-sm> Page {{ wbOrderHistory.getPage() }} </span>
      <span>
        <md-button
          class="md-icon-button pull-left"
          aria-label="Previous Page"
          ng-click="wbOrderHistory.previousPage()"
          ng-disabled="!wbOrderHistory.isPreviousPageAvailable() || wbOrderHistory.isProcessing()"
        >
          <md-icon
            ng-if="!wbOrderHistory.isProcessing()"
            md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-chevron-left"
          ></md-icon>
        </md-button>

        <md-button
          class="md-icon-button pull-left"
          aria-label="Next Page"
          ng-click="wbOrderHistory.nextPage()"
          ng-disabled="!wbOrderHistory.isNextPageAvailable()  || wbOrderHistory.isProcessing()"
        >
          <md-icon
            ng-if="!wbOrderHistory.isProcessing()"
            md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-chevron-right"
          ></md-icon>
          <md-progress-circular
            ng-if="wbOrderHistory.isProcessing()"
            class="md-accent button-loader"
            md-mode="indeterminate"
            md-diameter="25"
          ></md-progress-circular>
        </md-button>
      </span>
    </div>
  </md-card-footer>
</md-card>
