<md-card>
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'auth.password.change' | translate}}
    </div>
  </md-toolbar>

  <md-card-content layout-gt-sm="row">
    <div flex-gt-sm>
      <p>{{::'card.password.change.legend' | translate}}</p>
    </div>

    <div flex-gt-sm offset-gt-sm="5">
      <form novalidate name="passwordChangeForm" ng-submit="passwordChangeForm.$valid && !PasswordChange.isProcessing() && PasswordChange.submit()">

        <md-input-container flex>
          <label>{{::'auth.password.change.password' | translate}}</label>
          <input type="password" ng-minlength="8" maxlength="20" required name="password" ng-model="PasswordChange.passwordChange.password">
          <wb-messages form="passwordChangeForm" server="PasswordChange.getServerValidation()" field-name="password"></wb-messages>
        </md-input-container>

        <md-input-container flex>
          <md-icon class="wb-tooltip zmdi zmdi-help zmdi-hc-lg"></md-icon>
          <label>{{::'auth.password.change.passwordNew' | translate}}</label>
          <md-tooltip md-direction="top">
            <span ng-bind-html="::'auth.password.tooltip' | translate"></span>
          </md-tooltip>
          <input type="password" ng-minlength="8" maxlength="20" ng-pattern="/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}/" required name="passwordNew" ng-model="PasswordChange.passwordChange.passwordNew">
          <wb-messages form="passwordChangeForm" server="PasswordChange.getServerValidation()" field-name="passwordNew"></wb-messages>
        </md-input-container>

        <md-input-container flex>
          <label>{{::'auth.password.change.passwordConfirm' | translate}}</label>
          <input type="password" ng-minlength="8" maxlength="20" required name="passwordConfirm" match="PasswordChange.passwordChange.passwordNew" ng-model="passwordConfirm">
          <wb-messages form="passwordChangeForm" server="PasswordChange.getServerValidation()" field-name="passwordConfirm"></wb-messages>
        </md-input-container>

        <div layout-gt-sm="row" layout-align-gt-sm="end start">
            <div>
              <md-button ng-hide="PasswordChange.isProcessing()" class="md-raised md-primary full-width-button-sm" type="submit">
                {{::'auth.password.change.submit' | translate}}
              </md-button>
              <div class="button-loader-wrapper" ng-show="PasswordChange.isProcessing()">
                <md-progress-circular class="md-hue-2 button-loader" md-diameter="36px"
                                      md-mode="indeterminate"></md-progress-circular>
              </div>
            </div>
        </div>

      </form>
    </div>

  </md-card-content>
</md-card>
