<md-dialog class="wb-item-dialog wb-dialog-medium">
  <h2 class="wb-item-dialog-title">
    {{::'order.dataReconciliation.process.title' | translate}}
  </h2>

  <div class="wb-data-reconciliation-process-list">
    <div
      class="wb-data-reconciliation-process-item"
      ng-repeat="processItem in dataReconciliationProcessDialog.processList"
    >
      <span class="wb-data-reconciliation-process-item-indicator">{{
        $index + 1
      }}</span>
      <div class="wb-data-reconciliation-process-item-content">
        <h3>{{ ::processItem.title | translate }}</h3>
        <p>{{ ::processItem.info | translate }}</p>
      </div>
    </div>
  </div>

  <div class="md-actions">
    <md-button
      ng-click="dataReconciliationProcessDialog.closeDialog()"
      class="md-primary"
      type="button"
    >
      {{::'order.dataReconciliation.close' | translate}}
    </md-button>
  </div>
</md-dialog>
