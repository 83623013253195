<section class="wb-login" layout-gt-lg="column" layout-align-gt-lg="center center" layout-fill-gt-lg>

  <h1 class="wb-auth-screen-title text-center">{{::'auth.title' | translate}}</h1>

  <div class="wb-container wide">
    <form name="Login.loginForm"
          method="post"
          ng-submit="Login.loginForm.$valid && !Login.isProcessing() && Login.submit()"
          novalidate
          layout-md="column"
          layout-align="center"
          layout-gt-md="row"
          layout-wrap>

      <div class="wb-auth-box md-whiteframe-z1 wb-login-box" flex="100" flex-gt-md="auto" layout-margin>
        <div class="wb-auth-box-heading wb-auth-box-heading-login" ng-click="Login.submit()">
          <h1 class="wb-auth-box-title">{{::'auth.login.title' | translate}}</h1>
          <h2 class="wb-auth-box-subtitle">{{::'auth.login.subtitle' | translate}}</h2>
        </div>

        <div class="wb-auth-box-inner">
          <md-input-container flex>
            <md-icon class="wb-tooltip zmdi zmdi-help zmdi-hc-lg"></md-icon>
            <label>{{::'auth.email' | translate}}</label>
            <md-tooltip md-direction="right">
              <span ng-bind-html="::'auth.login.legend' | translate"></span>
            </md-tooltip>
            <input type="email" ng-maxlength="320" required name="identifier" ng-model="Login.session.identifier">
            <wb-messages form="Login.loginForm" server="Login.getServerValidation()" field-name="identifier"></wb-messages>
          </md-input-container>

          <md-input-container flex>
            <label>{{::'auth.password' | translate}}</label>
            <input type="password" ng-minlength="8" ng-maxlength="20" required name="password" ng-model="Login.session.password">
            <wb-messages form="Login.loginForm" server="Login.getServerValidation()" field-name="password"></wb-messages>
          </md-input-container>

          <div class="wb-auth-box-btn-container" flex layout="row" layout-align="space-between center">
            <md-button class="md-flat no-side-margin"
                       ui-sref="passwordReset"
                       aria-label="{{::'auth.passwordReset' | translate}}">
              {{::'auth.passwordReset' | translate}}
            </md-button>
            <div ng-hide="Login.isProcessing()">
              <md-button type="submit"
                         ng-disabled="Login.errorIsLoginLimit()"
                         class="md-raised md-primary wb-auth-login-btn md-button-lg no-side-margin"
                         aria-label="{{::'auth.login' | translate}}">
                {{::'auth.login.title' | translate}}
              </md-button>
            </div>
            <div class="button-loader-wrapper" ng-show="Login.isProcessing()">
              <md-progress-circular class="md-accent button-loader" md-diameter="36px"
                                    md-mode="indeterminate"></md-progress-circular>
            </div>
          </div>
        </div>
        <div class="wb-auth-box-footer">
          <p>{{::'auth.login.help.legend' | translate}}</p>

          <div layout="row" layout-align="center center">
            <div class="wb-auth-box-footer-contact-part" layout="row" layout-align="center center">
              <md-icon md-font-icon="zmdi zmdi-phone"></md-icon>
              <strong>+36 1 413 3333</strong>
            </div>
            <div class="wb-auth-box-footer-contact-part" layout="row" layout-align="center center">
              <md-icon md-font-icon="zmdi zmdi-email"></md-icon>
              <strong><a href="mailto:info@rewin.hu">info@rewin.hu</a></strong>
            </div>
          </div>
        </div>
      </div>
<!--       <div class="wb-auth-box visual-separator md-whiteframe-z1 wb-reg-box text-center" flex="100" flex-gt-md="auto" layout-margin>
        <div class="wb-auth-box-heading wb-auth-box-heading-reg" ui-sref="orderStart({type: 'new-card', auth: '-with-contract'})">
          <h1 class="wb-auth-box-title">{{'auth.registration.title' | translate}}</h1>
          <h2 class="wb-auth-box-subtitle">{{'auth.registration.subtitle' | translate}}</h2>
        </div>

        <div class="wb-auth-box-inner text-center" layout="column" layout-align="space-between center">
          <div layout="row" layout-align="space-between center" layout-fill>
            <div class="wb-step" flex="auto">
              <md-icon class="wb-step-icon zmdi zmdi-edit zmdi-hc-lg zmdi-hc-fw"></md-icon>
              <p class="wb-step-legend" flex="auto">{{::'auth.steps.first' | translate}}</p>
            </div>

            <div class="wb-step" flex="auto">
              <md-icon class="wb-step-icon zmdi zmdi-shopping-cart zmdi-hc-lg zmdi-hc-fw"></md-icon>
              <p class="wb-step-legend" flex="auto">{{::'auth.steps.second' | translate}}</p>
            </div>

            <div class="wb-step" flex="auto">
              <md-icon class="wb-step-icon zmdi zmdi-trending-up zmdi-hc-fw"></md-icon>
              <p class="wb-step-legend" flex="auto">{{::'auth.steps.third' | translate}}</p>
            </div>
          </div>

          <a class="wb-auth-link" ui-sref="orderStart({type: 'new-card', auth: '-with-contract'})">{{::'auth.registration.title' | translate}}</a>
        </div>
      </div> -->

    </form>
  </div>

</section>
