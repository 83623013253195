<md-dialog class="multi-wallet-card-item-dialog wb-item-dialog wb-dialog-small">
  <form
    name="multiWalletCardItemDialog.itemDialogForm"
    ng-submit="multiWalletCardItemDialog.itemDialogForm.$valid && multiWalletCardItemDialog.submit()"
    novalidate
    method="post"
  >
    <h2 class="wb-item-dialog-title">
      {{::'order.itemDialog.' + multiWalletCardItemDialog.selectedProductType + '.' + multiWalletCardItemDialog.orderType + '.' + (multiWalletCardItemDialog.isNewItem ? 'create' : 'edit') + '.title' | translate}}
    </h2>

    <div layout="row" layout-margin layout-padding>
      <md-input-container>
        <label>{{::'order.itemDialog.lastName' | translate}}</label>
        <input
          type="text"
          required
          name="lastName"
          ng-model="multiWalletCardItemDialog.item.lastName"
        />
        <wb-messages
          form="multiWalletCardItemDialog.itemDialogForm"
          server="multiWalletCardItemDialog.getServerValidation()"
          field-name="lastName"
        ></wb-messages>
      </md-input-container>

      <md-input-container>
        <label>{{::'order.itemDialog.firstName' | translate}}</label>
        <input
          type="text"
          required
          name="firstName"
          ng-model="multiWalletCardItemDialog.item.firstName"
        />
        <wb-messages
          form="multiWalletCardItemDialog.itemDialogForm"
          server="multiWalletCardItemDialog.getServerValidation()"
          field-name="firstName"
        ></wb-messages>
      </md-input-container>
    </div>
    <div layout="column" layout-margin layout-padding>
      <md-input-container>
        <md-datepicker
          name="dateOfBirth"
          required
          ng-model="multiWalletCardItemDialog.item.dateOfBirth"
          md-placeholder="{{::'order.itemDialog.dob.full.label' | translate}}"
        >
        </md-datepicker>
        <small class="form-field-hint">{{::'order.itemDialog.dob.hint' | translate}}</small>
        <wb-messages
          form="multiWalletCardItemDialog.itemDialogForm"
          server="multiWalletCardItemDialog.getServerValidation()"
          field-name="dateOfBirth"
        ></wb-messages>
      </md-input-container>
      <md-input-container>
        <label>{{::'order.itemDialog.email' | translate}}</label>
        <input
          type="email"
          name="email"
          required
          ng-model="multiWalletCardItemDialog.item.email"
        />
        <wb-messages
          form="multiWalletCardItemDialog.itemDialogForm"
          server="multiWalletCardItemDialog.getServerValidation()"
          field-name="email"
        ></wb-messages>
      </md-input-container>
    </div>

    <div class="md-actions">
      <md-button
        ng-click="multiWalletCardItemDialog.closeDialog()"
        class="md-primary"
        type="button"
      >
        {{::'order.itemDialog.close' | translate}}
      </md-button>

      <md-button
        class="md-primary"
        ng-hide="multiWalletCardItemDialog.isProcessing()"
        type="submit"
      >
        {{::'order.itemDialog.save' | translate}}
      </md-button>
      <div
        class="button-loader-wrapper"
        ng-show="multiWalletCardItemDialog.isProcessing()"
      >
        <md-progress-circular
          class="md-hue-2 button-loader"
          md-diameter="36px"
          md-mode="indeterminate"
        ></md-progress-circular>
      </div>
    </div>
  </form>
</md-dialog>
