<div class="wb-sidenav-wrapper">
  <md-sidenav
    class="md-sidenav-left wb-sidebar wb-sidenav-left"
    md-component-id="left"
    md-is-locked-open="true">
    <md-content layout="column" flex role="navigation" class="md-default-theme">
      <md-button
        class="sidenav-toggle"
        ng-click="wbSideMenu.toggleSideNav()"
        hide-gt-lg
        aria-label="{{::'navigation.toggle' | translate}}">
        <md-icon md-font-icon="zmdi zmdi-menu zmdi-hc-lg zmdi-hc-fw"></md-icon>
      </md-button>

      <md-card
        class="wb-sidebar-navigation"
        ng-if="wbSideMenu.hasLoggedInElements()">
        <md-card-content>
          <div class="md-title">
            {{::'navigation.onlineCardManagement' | translate}}
          </div>
          <md-list layout="column" layout-align="center start">
          <md-item
              analytics-on="click"
              analytics-event="{{::'order.steps.analytics.authenticated' | translate}} - {{::'order.steps.analytics.start' | translate}}"
              analytics-category="{{::'order.steps.analytics.registration.1' | translate}}"
              analytics-label="{{ wbSideMenu.getActiveCompanyVatNumber() }}"
              ui-sref="order({type: 'new-card'})"
              class="wb-sidebar-navigation-item wb-sidebar-navigation-item-1">
              <md-button layout-fill>
                <md-button
                  class="md-fab not-a-button md-mini md-accent md-wbTheme-theme"
                  aria-label="{{::'navigation.newCardOrder' | translate}}">
                  <md-icon md-font-icon="zmdi zmdi-shopping-cart zmdi-hc-lg zmdi-hc-fw"></md-icon>
                </md-button>
                <span>{{::'navigation.newCardOrder' | translate}}</span>
              </md-button>
            </md-item>

<!--             <md-item ui-sref="multiWalletDataReconciliationList" ng-if="wbSideMenu.hasContractedMultiwalletCard()" class="wb-sidebar-navigation-item">
              <md-button layout-fill>
                <md-button
                  class="md-fab not-a-button md-mini md-primary md-wbTheme-theme sidemenu-multi-wallet-data-reconciliations"
                  aria-label="{{::'navigation.multiWalletDataReconciliationList' | translate}}">
                  <md-icon md-font-icon="zmdi zmdi-file-text zmdi-hc-lg zmdi-hc-fw"></md-icon>
                </md-button>
                <span>{{::'navigation.multiWalletDataReconciliationList' | translate}}</span>
              </md-button>
            </md-item> -->

            <md-item
              ui-sref="orderReplacementCard"
              class="wb-sidebar-navigation-item wb-sidebar-navigation-item-2">
              <md-button layout-fill>
                <md-button
                  class="md-fab not-a-button md-mini md-primary md-wbTheme-theme sidemenu-order-replacement-card"
                  aria-label="{{::'navigation.orderReplacementCard' | translate}}">
                  <md-icon md-font-icon="zmdi zmdi-refresh zmdi-hc-lg zmdi-hc-fw"></md-icon>
                </md-button>
                <span>{{::'navigation.orderReplacementCard' | translate}}</span>
              </md-button>
            </md-item>

            <md-item
              analytics-on="click"
              analytics-event="{{::'order.steps.analytics.authenticated' | translate}} - {{::'order.steps.analytics.start' | translate}}"
              analytics-category="{{::'order.steps.analytics.registration.2' | translate}}"
              analytics-label="{{ wbSideMenu.getActiveCompanyVatNumber() }}"
              ui-sref="order({type: 'top-up'})"
              class="wb-sidebar-navigation-item wb-sidebar-navigation-item-3">
              <md-button layout-fill>
                <md-button
                  class="md-fab not-a-button md-mini md-primary md-wbTheme-theme"
                  aria-label="{{::'navigation.topUp' | translate}}">
                  <md-icon md-font-icon="zmdi zmdi-trending-up zmdi-hc-lg zmdi-hc-fw"></md-icon>
                </md-button>
                <span>{{::'navigation.topUp' | translate}}</span>
              </md-button>
            </md-item>

            <!-- <md-item
              analytics-on="click"
              analytics-event="{{::'order.steps.analytics.authenticated' | translate}} - {{::'order.steps.analytics.start' | translate}}"
              analytics-category="{{::'order.steps.analytics.registration.3' | translate}}"
              analytics-label="{{ wbSideMenu.getActiveCompanyVatNumber() }}"
              ui-sref="vipUpload({type: 'vip'})"
              class="wb-sidebar-navigation-item wb-sidebar-navigation-item-4">
              <md-button layout-fill>
                <md-button
                  class="md-fab not-a-button md-mini md-primary md-wbTheme-theme sidemenu-vip"
                  aria-label="{{::'navigation.vip' | translate}}">
                  <md-icon md-font-icon="zmdi zmdi-lock zmdi-hc-lg zmdi-hc-fw"></md-icon>
                </md-button>
                <span>{{::'navigation.vip' | translate}}</span>
              </md-button>
            </md-item> -->

            <!-- <md-item
              ui-sref="csnFileDownload"
              class="wb-sidebar-navigation-item wb-sidebar-navigation-item-5">
              <md-button layout-fill>
                <md-button
                  class="md-fab not-a-button md-mini md-primary md-wbTheme-theme sidemenu-csn-file-download"
                  aria-label="{{::'navigation.csnFileDownload' | translate}}">
                  <md-icon md-font-icon="zmdi zmdi-download zmdi-hc-lg zmdi-hc-fw"></md-icon>
                </md-button>
                <span>{{::'navigation.csnFileDownload' | translate}}</span>
              </md-button>
            </md-item> -->

            <!-- Add wbSideMenu.hasCompanyCmoId() as condition-->
            <md-item
              ui-sref="completeCsnLists"
              class="wb-sidebar-navigation-item wb-sidebar-navigation-item-6"
            >
              <md-button layout-fill>
                <md-button
                  class="md-fab not-a-button md-mini md-primary md-wbTheme-theme sidemenu-complete-csn-list"
                  aria-label="{{::'navigation.completeCsnLists' | translate}}">
                  <md-icon md-font-icon="zmdi zmdi-view-list zmdi-hc-lg zmdi-hc-fw"></md-icon>
                </md-button>
                <span>{{::'navigation.completeCsnLists' | translate}}</span>
              </md-button>
            </md-item>

            <md-item ui-sref="orders" class="wb-sidebar-navigation-item wb-sidebar-navigation-item-7">
              <md-button layout-fill>
                <md-button
                  class="md-fab not-a-button md-mini md-warn md-wbTheme-theme"
                  aria-label="{{::'navigation.previousOrders' | translate}}">
                  <md-icon md-font-icon="zmdi zmdi-assignment zmdi-hc-lg zmdi-hc-fw"></md-icon>
                </md-button>
                <span>{{::'navigation.previousOrders' | translate}}</span>
              </md-button>
            </md-item>

            <md-item ui-sref="faq" class="wb-sidebar-navigation-item wb-sidebar-navigation-item-8">
              <md-button layout-fill>
                <md-button
                  class="md-fab not-a-button md-mini md-grey md-wbTheme-theme"
                  aria-label="{{::'navigation.faq' | translate}}">
                  <md-icon md-font-icon="zmdi zmdi-comment-more zmdi-hc-lg zmdi-hc-fw"></md-icon>
                </md-button>
                <span>{{::'navigation.faq' | translate}}</span>
              </md-button>
            </md-item>
          </md-list>
        </md-card-content>
      </md-card>

      <!-- <wb-card-type-info></wb-card-type-info> -->

      <wb-contact></wb-contact>

      <md-card ng-if="!wbSideMenu.hasLoggedInElements()">
        <md-card-content>
          <header class="wb-card-header">
            <h2 class="md-title">
              {{:: 'info.requestCallback.title' | translate}}
            </h2>
            <small class="wb-subtitle" >{{:: 'info.requestCallback.subtitle' | translate}}</small>
          </header>

          <md-button
            class="md-primary md-raised full-width-button no-side-margin"
            type="button"
            ng-click="wbSideMenu.openCTAWidget()"
            analytics-on="click"
            analytics-event="{{::'order.steps.analytics.started' | translate}}"
            analytics-category="{{::'order.steps.analytics.companyCallback.anonymous' | translate}}">
            {{::'info.requestCallback.button' | translate}}
          </md-button>
        </md-card-content>
      </md-card>
    </md-content>
  </md-sidenav>
</div>
