<section class="wb-login" layout-gt-lg="column" layout-align-gt-lg="center center" layout-fill-gt-lg>

  <div class="wb-container wide">
    <form name="AdminLogin.loginForm"
          method="post"
          ng-submit="AdminLogin.loginForm.$valid && !AdminLogin.isProcessing() && AdminLogin.submit()"
          novalidate
          layout-md="column"
          layout-gt-md="row"
          layout-align="center start"
          layout-wrap>

      <div class="wb-auth-box md-whiteframe-z1 wb-login-box" flex="100" flex-gt-md="auto" layout-margin>
        <div class="wb-auth-box-heading wb-auth-box-heading-login" ng-click="AdminLogin.submit()">
          <h1 class="wb-auth-box-title">{{::'auth.login.title' | translate}}</h1>
        </div>

        <div class="wb-auth-box-inner">
          <md-input-container flex>
            <label>{{::'auth.username' | translate}}</label>
            <input type="text" required name="identifier" ng-model="AdminLogin.session.identifier">
            <wb-messages form="AdminLogin.loginForm" server="AdminLogin.getServerValidation()" field-name="identifier"></wb-messages>
          </md-input-container>

          <md-input-container flex>
            <label>{{::'auth.password' | translate}}</label>
            <input
              type="password"
              name="password"
              ng-model="AdminLogin.session.password"
              required
              ng-pattern="/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{16,})/"
            >
            <wb-messages form="AdminLogin.loginForm" server="AdminLogin.getServerValidation()" field-name="password"></wb-messages>
          </md-input-container>

          <md-input-container flex>
            <label>{{::'auth.email' | translate}}</label>
            <input type="email" ng-maxlength="320" required name="clientEmail" ng-model="AdminLogin.session.clientEmail">
            <wb-messages form="AdminLogin.loginForm" server="AdminLogin.getServerValidation()" field-name="clientEmail"></wb-messages>
          </md-input-container>

          <div class="wb-auth-box-btn-container" flex layout="row" layout-align="center start">
            <div ng-hide="AdminLogin.isProcessing()">
              <md-button type="submit"
                         ng-disabled="AdminLogin.errorIsLoginLimit()"
                         class="md-raised wb-auth-login-btn md-button-lg no-side-margin"
                         aria-label="{{::'auth.login' | translate}}">
                {{::'auth.login.title' | translate}}
              </md-button>
            </div>
            <div class="button-loader-wrapper" ng-show="AdminLogin.isProcessing()">
              <md-progress-circular class="md-accent button-loader" md-diameter="36px"
                                    md-mode="indeterminate"></md-progress-circular>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

</section>
