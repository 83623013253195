<md-dialog class="wb-item-dialog wb-dialog-wide wb-dialog-extra-tall">
  <h2 class="wb-item-dialog-title">
    {{::'order.productTypeSelector.title' | translate}}
  </h2>
  <div class="wb-product-type-selector-loader" ng-show="productTypeSelectorDialog.isLoading">
    <md-progress-circular class="md-accent" md-diameter="36px" md-mode="indeterminate"></md-progress-circular>
  </div>
  <div class="wb-product-type-selector-list">
    <div class="wb-product-type-selector-list-item" ng-if="productTypeSelectorDialog.cardTypesBenefit"
      ng-class="{'wb-product-type-selector-list-item-active': productTypeSelectorDialog.selected === 'benefit'}"
      ng-click="productTypeSelectorDialog.selectProductType('benefit')"
    >
      <span class="wb-product-type-selector-list-item-indicator"></span>
<!--       <img class="wb-product-type-selector-list-item-image" src="/images/juttatasi@2x.png"
        alt="{{::'order.productTypes.3.title' | translate}}" /> -->

      <div class="wb-product-type-selector-list-item-content">
        <h3 class="wb-product-type-selector-list-item-name">
          {{::'order.productTypes.3.title' | translate}}
        </h3>

        <div class="wb-product-type-selector-list-item-text"
          ng-bind-html="::'order.productTypes.3.content' | translate"></div>
      </div>
    </div>

<!--
    <div
      class="wb-product-type-selector-list-item"
      ng-if="productTypeSelectorDialog.cardTypesFamily"
      ng-class="{'wb-product-type-selector-list-item-active': productTypeSelectorDialog.selected === 'family'}"
      ng-click="productTypeSelectorDialog.selectProductType('family')"
    >
      <span class="wb-product-type-selector-list-item-indicator"></span>

      <img
        class="wb-product-type-selector-list-item-image"
        src="/images/family@2x.png"
        alt="{{::'order.productTypes.5.title' | translate}}"
      />

      <div class="wb-product-type-selector-list-item-content">
        <h3 class="wb-product-type-selector-list-item-name">
          {{::'order.productTypes.5.title' | translate}}
        </h3>

        <div
          class="wb-product-type-selector-list-item-text"
          ng-bind-html="::'order.productTypes.5.content' | translate"
        ></div>
      </div>
    </div>

    <div
      class="wb-product-type-selector-list-item"
      ng-if="productTypeSelectorDialog.cardTypesComfort"
      ng-class="{'wb-product-type-selector-list-item-active': productTypeSelectorDialog.selected === 'comfort'}"
      ng-click="productTypeSelectorDialog.selectProductType('comfort')"
    >
      <span class="wb-product-type-selector-list-item-indicator"></span>

      <img
        class="wb-product-type-selector-list-item-image"
        src="/images/komfort@2x.png"
        alt="{{::'order.productTypes.4.title' | translate}}"
      />

      <div class="wb-product-type-selector-list-item-content">
        <h3 class="wb-product-type-selector-list-item-name">
          {{::'order.productTypes.4.title' | translate}}
        </h3>

        <div
          class="wb-product-type-selector-list-item-text"
          ng-bind-html="::'order.productTypes.4.content' | translate"
        ></div>
      </div>
    </div>

    <div
      class="wb-product-type-selector-list-item"
      ng-if="productTypeSelectorDialog.cardTypesCafeteria"
      ng-class="{'wb-product-type-selector-list-item-active': productTypeSelectorDialog.selected === 'cafeteria',
                 'wb-product-type-selector-list-item-disabled': productTypeSelectorDialog.isNewCardOrder()}"
      ng-click="productTypeSelectorDialog.selectProductType('cafeteria')"
    >
      <span class="wb-product-type-selector-list-item-indicator"></span>

      <img
        class="wb-product-type-selector-list-item-image"
        src="/images/hagyomanyos@2x.png"
        alt="{{::'order.productTypes.1.title' | translate}}"
      />

      <div class="wb-product-type-selector-list-item-content">
        <h3 class="wb-product-type-selector-list-item-name">
          {{::'order.productTypes.1.title' | translate}}
        </h3>

        <div
          class="wb-product-type-selector-list-item-text"
          ng-bind-html="::'order.productTypes.1.content' | translate"
        ></div>
      </div>
    </div>

    <div
      class="wb-product-type-selector-list-item"
      ng-if="productTypeSelectorDialog.cardTypesMultiWallet"
      ng-class="{'wb-product-type-selector-list-item-active': productTypeSelectorDialog.selected === 'multiWallet'}"
      ng-click="productTypeSelectorDialog.selectProductType('multiWallet')"
    >
      <span class="wb-product-type-selector-list-item-indicator"></span>

      <img
        class="wb-product-type-selector-list-item-image"
        src="/images/szep-plusz-v2.png"
        alt="{{::'order.productTypes.2.title' | translate}}"
      />

      <div class="wb-product-type-selector-list-item-content">
        <h3 class="wb-product-type-selector-list-item-name">
          {{::'order.productTypes.2.title' | translate}}
        </h3>

        <div
          class="wb-product-type-selector-list-item-text"
          ng-bind-html="::'order.productTypes.2.content' | translate"
        ></div>
      </div>
    </div>
  </div>
 -->
  <div class="md-actions">
    <md-button ng-click="productTypeSelectorDialog.closeDialog()" class="md-primary" type="button">
      {{::'order.productTypeSelector.close' | translate}}
    </md-button>
    <md-button ng-click="productTypeSelectorDialog.submit()" ng-disabled="!productTypeSelectorDialog.isValid()"
      class="md-primary" type="button">
      {{::'order.productTypeSelector.submit' | translate}}
    </md-button>
  </div>
</md-dialog>
