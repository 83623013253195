<md-card>
  <md-toolbar class="wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.replacementCard.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <form
      name="orderReplacementCardForm"
      ng-submit="orderReplacementCardForm.$valid && !orderReplacementCard.isProcessing() && orderReplacementCard.submit()"
      novalidate
    >
      <div layout="row" layout-align="start start" layout-margin>
        <div flex layout="column" layout-align="start stretch">
          <p>{{::'order.replacementCard.description' | translate}}</p>
        </div>

        <div flex layout="column" layout-align="start stretch">
          <md-input-container flex>
            <input
              type="text"
              name="name"
              ng-model="orderReplacementCard.name"
              required
            />
            <label>{{::'account.company.staffName' | translate}}</label>
            <wb-messages
              form="orderReplacementCardForm"
              server="orderReplacementCard.getServerValidation()"
              field-name="name"
            ></wb-messages>
          </md-input-container>

          <md-input-container flex>
            <input
              type="text"
              name="csn"
              ng-model="orderReplacementCard.csn"
              required
            />
            <label>{{::'account.company.stolenCard.csn' | translate}}</label>
            <wb-messages
              form="orderReplacementCardForm"
              server="orderReplacementCard.getServerValidation()"
              field-name="csn"
            ></wb-messages>
          </md-input-container>
        </div>
      </div>

      <div layout="row" layout-align="end center" layout-margin>
        <md-button
          type="submit"
          class="md-raised md-accent"
          ng-hide="orderReplacementCard.isProcessing()"
        >
          {{::'order.sendOrder' | translate}}
        </md-button>
        <div
          class="button-loader-wrapper"
          ng-show="orderReplacementCard.isProcessing()"
        >
          <md-progress-circular
            class="md-hue-2 button-loader"
            md-diameter="36px"
            md-mode="indeterminate"
          >
          </md-progress-circular>
        </div>
      </div>
    </form>
  </md-card-content>
</md-card>
