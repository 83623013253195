<md-card>
  <md-toolbar class="wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.orderStart.title.1' | translate}}
    </div>
  </md-toolbar>

  <md-card-content class="wb-order-start-content-wrapper">
    <div class="wb-order-start-content">
      <div ng-bind-html="'order.orderStart.text' | translate"></div>

      <div class="wb-order-start-steps">
        <div class="wb-order-start-step" ng-repeat="step in orderStart.orderSteps">
          <div class="wb-order-start-step-icon-wrapper">
            <md-icon md-font-icon="zmdi {{::step.icon}} zmdi-hc-lg zmdi-hc-fw"></md-icon>
          </div>

          <span class="wb-order-start-step-title">{{::step.title}}</span>
          <span class="wb-order-start-step-legend">{{::step.description}}</span>

          <div class="wb-order-start-step-button-wrapper" ng-if="step.hasCardComparison">
            <md-button class="md-flat md-primary" ng-click="orderStart.openComparisonDialog()">
              {{::'order.orderStart.aboutCards' | translate}}
            </md-button>
          </div>
        </div>
      </div>
    </div>

    <div layout="row" layout-align="end center">
      <md-button class="md-raised md-accent no-side-margin"
                 analytics-on="click" analytics-event="{{::'order.steps.analytics.started' | translate}}" analytics-category="{{::'order.steps.analytics.registration' | translate}}"
                 ui-sref="order({type: 'new-card', auth: '-with-contract'})">
        {{::'order.orderStart.order' | translate}}
      </md-button>
    </div>

  </md-card-content>
</md-card>

<md-card>
  <md-toolbar class="wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.orderStart.callback.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content class="wb-order-start-callback-content-wrapper">
    <div class="wb-order-start-callback-content">
      <div ng-bind-html="::'order.orderStart.callback.text' | translate"></div>

      <div class="wb-vertical-spacer medium"></div>

      <div layout="row" layout-align="end center">
        <md-button class="md-raised md-accent no-side-margin"
                   analytics-on="click" analytics-event="{{::'order.steps.analytics.started' | translate}}" analytics-category="{{::'order.steps.analytics.companyCallback.anonymous' | translate}}"
                   type="button"
                   ng-click="orderStart.openCTAWidget()">
          {{::'order.orderStart.callback.title' | translate}}
        </md-button>
      </div>
    </div>
  </md-card-content>
</md-card>
