<md-card class="multi-wallet-data-reconciliation-list">
  <md-toolbar class="wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.multiWalletDataReconciliationList.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <p>{{::'order.multiWalletDataReconciliationList.info' | translate}}</p>

    <form name="dataReconciliationFilterForm" class="multi-wallet-data-reconciliation-list-filter-form" novalidate
      ng-submit="dataReconciliationFilterForm.$valid && !multiWalletDataReconciliationList.isProcessing() && multiWalletDataReconciliationList.filterSubmit()">
      <div layout="row">
        <div flex offset="50">
          <span class="form-group-label">{{::'order.multiWalletDataReconciliationList.form.date' | translate}}</span>
        </div>
      </div>
      <div layout="row">
        <div layout="row" flex>
          <md-input-container flex>
            <label>{{::'order.multiWalletDataReconciliationList.form.name' | translate}}</label>
            <input type="text" name="staffName" ng-model="multiWalletDataReconciliationList.filter.staffName" />
            <wb-messages form="dataReconciliationFilterForm" server="multiWalletDataReconciliationList.getServerValidation()" field-name="staffName">
            </wb-messages>
          </md-input-container>
          <md-input-container flex>
            <label>{{::'order.multiWalletDataReconciliationList.form.status' | translate}}</label>
            <md-select name="status" ng-model="multiWalletDataReconciliationList.filter.status">
              <md-option ng-value="status.type" ng-repeat="status in multiWalletDataReconciliationList.processStatusList">
                {{ status.title | translate }}
              </md-option>
            </md-select>
            <wb-messages form="dataReconciliationFilterForm" server="multiWalletDataReconciliationList.getServerValidation()" field-name="status">
            </wb-messages>
          </md-input-container>
        </div>
        <div layout="row" flex>
          <md-input-container flex>
            <md-datepicker name="dateFrom"
              ng-model="multiWalletDataReconciliationList.filter.dateFrom"
              md-placeholder="{{::'order.multiWalletDataReconciliationList.form.fromDate' | translate}}">
            </md-datepicker>
            <wb-messages form="dataReconciliationFilterForm" server="multiWalletDataReconciliationList.getServerValidation()" field-name="dateFrom">
            </wb-messages>
          </md-input-container>

          <md-input-container flex>
            <md-datepicker name="dateTo"
              ng-model="multiWalletDataReconciliationList.filter.dateTo"
              md-placeholder="{{::'order.multiWalletDataReconciliationList.form.toDate' | translate}}">
            </md-datepicker>
            <wb-messages form="dataReconciliationFilterForm" server="multiWalletDataReconciliationList.getServerValidation()" field-name="dateTo">
            </wb-messages>
          </md-input-container>
        </div>
      </div>
      <div layout="row" layout-align="end center">
        <md-button class="md-raised md-accent">
          {{::'order.multiWalletDataReconciliationList.filter' | translate}}
        </md-button>
      </div>
    </form>

    <div class="wb-table wb-data-reconciliation-table wb-data-reconciliation-table--has-checkboxes">
      <div class="wb-table-header" layout="row" layout-align="start end">
        <span class="wb-data-reconciliation-checkbox-wrapper" flex="5">
          <md-checkbox class="md-primary" ng-checked="multiWalletDataReconciliationList.allSelected"
            ng-click="multiWalletDataReconciliationList.selectAll()"></md-checkbox>
          <md-tooltip md-direction="bottom">
            {{::'order.multiWalletDataReconciliationList.selection.selectAll' | translate}}</md-tooltip>
        </span>
        <span flex="20">
          {{::'order.multiWalletDataReconciliationList.table.header.orderSent' | translate}}
          <br />
          {{::'order.multiWalletDataReconciliationList.table.header.notificationSent' | translate}}
        </span>
        <span flex="15">
          {{::'order.multiWalletDataReconciliationList.table.header.lastName' | translate}}
          <br />
          {{::'order.multiWalletDataReconciliationList.table.header.firstName' | translate}}
        </span>
        <span flex="20">
          {{::'order.multiWalletDataReconciliationList.table.header.contract' | translate}}
          <br />
          {{::'order.multiWalletDataReconciliationList.table.header.cardOrder' | translate}}
        </span>
        <span flex="25">{{::'order.multiWalletDataReconciliationList.table.header.email' | translate}}</span>
        <span flex="5" class="text-center" layout="row"
          layout-align="center end">{{::'order.multiWalletDataReconciliationList.table.header.status' | translate}}</span>
        <span flex></span>
      </div>

      <div class="wb-table-item wb-data-reconciliation-table-item" layout="row" layout-align="center center"
        ng-repeat="dataReconciliation in multiWalletDataReconciliationList.getDataReconciliationList()"
        ng-class="{'wb-table-item--checked': dataReconciliation.isSelected}">
        <span flex="5" class="wb-data-reconciliation-checkbox-wrapper wb-data-reconciliation-checkbox-wrapper--row">
          <md-checkbox class="md-primary" ng-checked="dataReconciliation.isSelected"
            ng-click="multiWalletDataReconciliationList.allSelected = false; dataReconciliation.isSelected = !dataReconciliation.isSelected"
            ng-hide="dataReconciliation.status > 2"></md-checkbox>
        </span>
        <span flex="20">
          {{ dataReconciliation.orderSent | date: 'yyyy.MM.dd' }}<br />
          {{ dataReconciliation.notificationSent | date: 'yyyy.MM.dd' }}
        </span>
        <span flex="15">
          {{ dataReconciliation.lastName }}<br />
          {{ dataReconciliation.firstName }}
        </span>
        <span flex="20">
          {{ dataReconciliation.contract | date: 'yyyy.MM.dd' }}<br />
          {{ dataReconciliation.cardOrder | date: 'yyyy.MM.dd' }}
        </span>
        <span flex="25">
          {{ dataReconciliation.email }}
        </span>
        <span flex="5" class="text-center">
          <span class="markable-legend-indicator status-{{
              dataReconciliation.status
            }}">
            {{ dataReconciliation.status }}
          </span>
        </span>
        <span flex class="text-right">
          <md-button class="md-icon-button md-button-sm"
            ng-click="multiWalletDataReconciliationList.deleteDataReconciliation(dataReconciliation.id)"
            ng-hide="dataReconciliation.status > 2">
            <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-delete"></md-icon>
          </md-button>
          <md-button class="md-icon-button md-button-sm"
            ng-click="multiWalletDataReconciliationList.requestEmailNotification(dataReconciliation.id)"
            ng-hide="dataReconciliation.status > 2">
            <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-email"></md-icon>
          </md-button>
        </span>
      </div>
    </div>

    <div layout="row" layout-align="space-between center">
      <div layout="row" layout-padding flex layout-align="start center" class="wb-list-legend">
        <span ng-repeat="status in multiWalletDataReconciliationList.processStatusList" class="markable-legend">
          <span class="markable-legend-indicator">{{ status.type }}</span>
          {{ status.title | translate }}
        </span>
      </div>

      <div class="wb-list-pagination" layout="row" layout-align="space-between center">
        <span class="wb-list-current-page">{{ multiWalletDataReconciliationList.getPage() }}
        </span>
        <span>
          <md-button class="md-icon-button pull-left" aria-label="Previous Page"
            ng-click="multiWalletDataReconciliationList.previousPage()"
            ng-disabled="!multiWalletDataReconciliationList.isPreviousPageAvailable() || multiWalletDataReconciliationList.isProcessing()">
            <md-icon ng-if="!multiWalletDataReconciliationList.isProcessing()"
              md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-chevron-left"></md-icon>
          </md-button>

          <md-button class="md-icon-button pull-left" aria-label="Next Page"
            ng-click="multiWalletDataReconciliationList.nextPage()"
            ng-disabled="!multiWalletDataReconciliationList.isNextPageAvailable() || multiWalletDataReconciliationList.isProcessing()">
            <md-icon ng-if="!multiWalletDataReconciliationList.isProcessing()"
              md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-chevron-right"></md-icon>
            <md-progress-circular ng-if="multiWalletDataReconciliationList.isProcessing()"
              class="md-accent button-loader" md-mode="indeterminate" md-diameter="25"></md-progress-circular>
          </md-button>
        </span>
      </div>
    </div>

    <div class="multi-wallet-data-reconciliation-list-actions">
      <md-button class="md-raised md-button-info"
        ng-click="multiWalletDataReconciliationList.deleteDataReconciliation()">
        <md-icon md-font-icon="zmdi zmdi-delete zmdi-hc-lg zmdi-hc-fw"></md-icon>
        {{::'order.multiWalletDataReconciliationList.action.delete' | translate }}
      </md-button>

      <md-button class="md-raised md-button-info"
        ng-click="multiWalletDataReconciliationList.requestEmailNotification()">
        <md-icon md-font-icon="zmdi zmdi-email zmdi-hc-lg zmdi-hc-fw"></md-icon>
        {{::'order.multiWalletDataReconciliationList.action.notify' | translate }}
      </md-button>
    </div>
  </md-card-content>
</md-card>
