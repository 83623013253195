<md-toolbar layout="row" layout-align="center center" class="wb-page-header md-whiteframe-z3">
  <div class="wb-container" layout="row" layout-align="space-between center" flex>
    <span flex-gt-lg="20" class="brand-wrapper">
        <a class="md-toolbar-tools brand" ui-sref="dashboard"><img src="images/rewin-logo-white.svg" alt="Rewin"></a>
    </span>

    <div flex-gt-lg="80" class="toolbar-menu-wrapper">
      <span layout="row" flex layout-align-gt-lg="end center">

        <span layout="column"
              layout-align="space-between center"
              class="toolbar-user-menu-item always-visible">
          <md-menu md-offset="0 72">
            <md-button ng-click="$mdOpenMenu()"
                       aria-label="{{::'navigation.languageSwitcher' | translate}}"
                       title="{{::'navigation.languageSwitcher' | translate}}"
                       style="min-width: 0;">
              <md-icon md-font-icon="zmdi zmdi-globe-alt zmdi-hc-lg zmdi-hc-fw"></md-icon>
            </md-button>

            <md-menu-content>
              <md-menu-item>
                <md-button title="Magyar"
                           aria-label="Magyar"
                           ng-disabled="wbMainMenu.isCurrentLanguage('hu')"
                           ng-click="wbMainMenu.changeLanguageTo('hu')">
                  Magyar
                </md-button>
              </md-menu-item>
              <md-menu-item>
                <md-button title="English"
                           aria-label="English"
                           ng-disabled="wbMainMenu.isCurrentLanguage('en')"
                           ng-click="wbMainMenu.changeLanguageTo('en')">
                  English
                </md-button>
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </span>

        <span ng-if="wbMainMenu.hasLoggedInElements() && wbMainMenu.showSessionTimer()" layout="column" layout-align="space-between center" class="toolbar-user-menu-item">
          <span class="toolbar-user-session-timer">{{ wbMainMenu.getSessionTimer() }}</span>
        </span>

        <span layout="column" layout-align="space-between center" class="toolbar-user-menu-item" ng-if="wbMainMenu.hasLoggedInElements()">
          <md-menu md-position-mode="target-right target" md-offset="-232 87">
            <md-button class="my-account-toggle" ng-click="$mdOpenMenu()" aria-label="{{wbMainMenu.getFullNameOfCurrentUser()}}">
              <span>{{wbMainMenu.getFullNameOfCurrentUser()}}</span>
              <md-icon md-font-icon="zmdi zmdi-caret-down zmdi-hc-lg zmdi-hc-fw"></md-icon>
            </md-button>

            <md-menu-content class="wb-user-menu">
              <md-button title="{{::'account.change' | translate}}"
                         aria-label="{{::'account.change' | translate}}"
                         ui-sref="account">
                <md-icon md-font-icon="zmdi zmdi-edit zmdi-hc-lg zmdi-hc-fw" hide-sm></md-icon>
                {{::'account.change' | translate}}
              </md-button>
              <md-button title="{{::'account.company.change' | translate}}"
                         aria-label="{{::'account.company.change' | translate}}"
                         ui-sref="company">
                <md-icon md-font-icon="zmdi zmdi-edit zmdi-hc-lg zmdi-hc-fw" hide-sm></md-icon>
                {{::'account.company.change' | translate}}
              </md-button>
              <md-button title="{{::'auth.password.change' | translate}}"
                         aria-label="{{::'auth.password.change' | translate}}"
                         ui-sref="passwordChange">
                <md-icon md-font-icon="zmdi zmdi-time-restore-setting zmdi-hc-lg zmdi-hc-fw" hide-sm></md-icon>
                {{::'auth.password.change' | translate}}
              </md-button>
            </md-menu-content>
          </md-menu>
        </span>

        <span layout="row" layout-align="space-between center" class="toolbar-user-menu-item" ng-if="wbMainMenu.hasLoggedInElements()">
          <md-button class="narrow"
                     ui-sref="order({type: 'new-card'})"
                     analytics-on="click" analytics-event="{{::'order.steps.analytics.authenticated' | translate}} - {{::'order.steps.analytics.start' | translate}}" analytics-category="{{::'order.steps.analytics.registration.1' | translate}}" analytics-label="{{wbMainMenu.getActiveCompanyVatNumber()}}"
                     ng-class="{'has-notification': wbMainMenu.cartHasNewCardOrder()}"
                     aria-label="{{::'order.newCardOrder' | translate}}">
            <md-icon md-font-icon="zmdi zmdi-shopping-cart zmdi-hc-lg zmdi-hc-fw"></md-icon>
            <md-tooltip>
              {{::'order.newCardOrder' | translate}}
            </md-tooltip>
          </md-button>

          <md-button class="narrow"
                     ui-sref="order({type: 'top-up'})"
                     analytics-on="click" analytics-event="{{::'order.steps.analytics.authenticated' | translate}} - {{::'order.steps.analytics.start' | translate}}" analytics-category="{{::'order.steps.analytics.registration.2' | translate}}" analytics-label="{{wbMainMenu.getActiveCompanyVatNumber()}}"
                     ng-class="{'has-notification': wbMainMenu.cartHasTopUpOrder()}"
                     aria-label="{{::'order.topUp' | translate}}">
            <md-icon md-font-icon="zmdi zmdi-trending-up zmdi-hc-lg zmdi-hc-fw"></md-icon>
            <md-tooltip>
              {{::'order.topUp' | translate}}
            </md-tooltip>
          </md-button>
        </span>

        <span layout="column" layout-align="space-between center" class="toolbar-user-menu-item" ng-if="wbMainMenu.hasLoggedInElements()">
          <md-button class="logout narrow" ui-sref="logout" aria-label="{{::'auth.logout' | translate}}">
            <md-icon md-font-icon="zmdi zmdi-square-right zmdi-hc-lg zmdi-hc-fw"></md-icon>
          </md-button>
          <md-tooltip>
            {{::'auth.logout' | translate}}
          </md-tooltip>
        </span>
      </span>
    </div>

  </div>
</md-toolbar>
