<md-card>
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'faq' | translate}}
    </div>
  </md-toolbar>

  <md-card-content layout="column" layout-align="space-around start">

    <faq-list></faq-list>

  </md-card-content>
</md-card>
