(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:TypeSelectorCtrl
   *
   * @description Define the used methods of the typeSelector state
   */
  angular
    .module('wbOrder')
    .controller('TypeSelectorCtrl', TypeSelectorCtrl);

  function TypeSelectorCtrl(company, user, LoggedUser, isAuthenticated, orderType, $scope, $mdDialog, cardTypeMap, _, Cart, $translate, $document) {
    var vm = this
      , EdenredECSselectedProductType = 'EdenredEcsSelectedProductType'
      , currentStep = 'typeSelector'
      , nextStep = ''
      , allowedProductTypes = [cardTypeMap.cafeteria, cardTypeMap.benefit, cardTypeMap.comfort, cardTypeMap.family, cardTypeMap.multiWallet]
      , allowedCompanyContracts;

    vm.isNewCardOrder = function isNewCardOrder() {
      return orderType === '1';
    };

    vm.isBulkOrderSelected = function isBulkOrderSelected() {
      return nextStep === 'fileUpload';
    };

    vm.getSelectedProductType = function getSelectedProductType() {
      return localStorage.getItem(EdenredECSselectedProductType);
    };

    vm.setAllowedProductTypes = function setAllowedProductTypes() {
      /**
       * Disable comfort card topup
       */
      if (!vm.isNewCardOrder()) {
        allowedProductTypes = _.filter(allowedProductTypes, function (item) {
          return item !== cardTypeMap.comfort;
        });
      }
    };

    vm.setAllowedProductTypes();

    vm.setAllowedCompanyContracts = function setAllowedCompanyContracts() {
      if (!company || !company.contractedCardTypes) {
        /**
         * Allow selecting comfort, benefit and multiwallet card in registration order
         */
        allowedCompanyContracts = _.filter(allowedProductTypes, function (item) {
          return item !== cardTypeMap.cafeteria;
        });

        return;
      }

      if (company.contractedCardTypes.length < 1) {
        allowedCompanyContracts = [];
        return;
      }

      var uniqueAllowedCompanyContracts = _.uniq(company.contractedCardTypes, 'category');

      allowedCompanyContracts =
        _.filter(uniqueAllowedCompanyContracts, function (item) {
          return allowedProductTypes.indexOf(item.category) !== -1;
        })
        .map(function (item) {
          return item.category;
        });
    };

    vm.setAllowedCompanyContracts();

    vm.triggerProductTypeSelectorDialog = function triggerProductTypeSelectorDialog() {
      vm.openProductTypeSelectorDialog()
        .then(function ($selected) {
          vm.setProductType($selected);
          vm.emitNextStepEvent();
        });
    };

    vm.updateOrderTypeForSessionUser = function updateOrderTypeForSessionUser() {
      var currentProductTypeId = cardTypeMap[vm.getSelectedProductType()];
      return Cart
        .updateOrderTypeForSessionUser({
          cartId: orderType,
          category: currentProductTypeId
        })
        .$promise;
    };

    /**
     * @TODO refactor logic
     * Handle product type select and state logic more efficiently, cleanly
     */
    vm.setNextStep = function setNextStep() {
      switch (vm.getSelectedProductType()) {
        case 'comfort':
          nextStep = 'comfortCardItemList';
          break;
        case 'multiWallet':
          if (vm.isBulkOrderSelected()) {
            if (vm.isNewCardOrder()) {
              nextStep = 'multiWalletCardFileUpload';
            } else {
              nextStep = 'fileUpload';
            }
          } else if (vm.isNewCardOrder()) {
            nextStep = 'multiWalletCardItemList';
          } else {
            nextStep = 'itemList';
          }

          break;
        default:
          return nextStep;
      }
    };

    /**
     * Tell the parent controller to go to nextStep state
     */
    vm.emitNextStepEvent = function emitNextStepEvent() {
      vm.setNextStep();

      // Disable new card order for cafeteria card
      if (localStorage.getItem(EdenredECSselectedProductType) === 'cafeteria' && vm.isNewCardOrder()) {
        vm.openCafeteriaCardOrderDialog();

        return;
      }

      vm.updateOrderTypeForSessionUser()
        .then(function () {
          $scope.$emit('order.next', nextStep, currentStep);
        })
        .catch(function (err) {
          console.error(err);
        });
    };

    vm.openCafeteriaCardOrderDialog = function openCafeteriaCardOrderDialog() {
      $mdDialog.show({
        parent: angular.element(document.body),
        templateUrl: 'wb-order/views/cafeteria-card-order-dialog.tpl.html',
        clickOutsideToClose: true,
        focusOnOpen: false,
        controllerAs: 'cafeteriaCardOrderDialog',
        controller: function cafeteriaCardOrderDialog($mdDialog) {
          var vm = this;

          vm.closeDialog = function closeDialog() {
            $mdDialog.hide();
          };
        }
      });
    };

    /**
     * @TODO update logic
     * if LoggedIn
     *      and has more than 1 contract that is selectable (allowed by Edenred and client has a contract) then show product type selector
     * else
     *      set product type and go to next state
     *
     * @returns {boolean}
     */
    vm.shouldSelectProductType = function shouldSelectProductType() {
      var showProductSelector = false;
      if (allowedCompanyContracts.length > 1) {
        showProductSelector = true;
      }

      return showProductSelector;
    };

    vm.setProductType = function setProductType(productType) {
      localStorage.setItem(EdenredECSselectedProductType, productType);
    };

    vm.handleStateLogic = function handleStateLogic() {
      if (vm.shouldSelectProductType()) {
        vm.triggerProductTypeSelectorDialog();
        return;
      }

      /**
       * If only one allowed company contracts is present, set that one
       */
      if (allowedCompanyContracts.length === 1) {
        var productTypeKey = _.findKey(cardTypeMap, function (item) {
          return item === allowedCompanyContracts[0];
        });

        vm.setProductType(productTypeKey.toString());
      }

      /**
       * If no product type selector should be triggered set the product type to 'benefit'
       */
      if (allowedCompanyContracts.length === 0) {
        vm.setProductType('benefit');
      }

      vm.emitNextStepEvent();
    };


    vm.selectUnique = function selectUnique() {
      nextStep = 'itemList';
      vm.handleStateLogic();
    };

    vm.selectBulk = function selectBulk() {
      nextStep = 'fileUpload';
      vm.handleStateLogic();
    };

    vm.order = {
      status: 'PRG',
      type: orderType
    };

    vm.openComparisonDialog = function openComparisonDialog() {
      $mdDialog.show({
        parent: angular.element(document.body),
        templateUrl: 'wb-order/views/card-comparison-dialog.tpl.html',
        clickOutsideToClose: true,
        focusOnOpen: false,
        controllerAs: 'comparisonDialog',
        controller: function comparisonDialog($mdDialog) {
          var vm = this;

          vm.closeDialog = function closeDialog() {
            $mdDialog.hide();
          };
        }
      });
    };

    vm.openProductTypeSelectorDialog = function openProductTypeSelectorDialog() {
      return $mdDialog.show({
        parent: angular.element(document.body),
        templateUrl: 'wb-order/views/product-type-selector-dialog.tpl.html',
        clickOutsideToClose: false,
        focusOnOpen: false,
        controllerAs: 'productTypeSelectorDialog',
        controller: function productTypeSelectorDialog($mdDialog, CardType, cardTypeMap, _) {
          var vm = this;

          vm.isLoading = true;
          vm.selected = 'benefit';

          CardType.query().$promise
            .then(function (data) {
              if (allowedCompanyContracts.indexOf(cardTypeMap.cafeteria) !== -1) {
                vm.cardTypesCafeteria = _.filter(data, { 'category': cardTypeMap.cafeteria });
              }
              if (allowedCompanyContracts.indexOf(cardTypeMap.multiWallet) !== -1) {
                vm.cardTypesMultiWallet = _.filter(data, { 'category': cardTypeMap.multiWallet });
              }
              if (allowedCompanyContracts.indexOf(cardTypeMap.benefit) !== -1) {
                vm.cardTypesBenefit = _.filter(data, { 'category': cardTypeMap.benefit });
              }
              if (allowedCompanyContracts.indexOf(cardTypeMap.comfort) !== -1) {
                vm.cardTypesComfort = _.filter(data, { 'category': cardTypeMap.comfort });
              }
              if (allowedCompanyContracts.indexOf(cardTypeMap.family) !== -1) {
                vm.cardTypesFamily = _.filter(data, { 'category': cardTypeMap.family });
              }

              vm.isLoading = false;
            });

          vm.isValid = function isValid() {
            return !_.isUndefined(vm.selected);
          };

          vm.isNewCardOrder = function isNewCardOrder() {
            return orderType === '1';
          };

          vm.selectProductType = function selectProductType(cardType) {
            if (cardType === 'cafeteria' && vm.isNewCardOrder()) {
              return;
            }

            vm.selected = cardType;
          };

          vm.submit = function submit() {
            $mdDialog.hide(vm.selected);
          };

          vm.closeDialog = function closeDialog() {
            $mdDialog.cancel();
          };
        }
      });
    };
  }
}());
