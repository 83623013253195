<md-card>
  <md-toolbar class="wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.' + itemList.selectedProductType + '.' + itemList.orderType + '.unique.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <p ng-if="!itemList.selectedProductType === 'multiWallet'">
      {{::'order.itemList.info' | translate}}
    </p>

    <md-button
      class="md-raised md-primary no-side-margin"
      ng-click="itemList.openItemDialog()"
    >
      {{::'order.itemList.addNew' | translate}}
    </md-button>

    <div class="wb-vertical-spacer large"></div>

    <div ng-if="itemList.order.items" ng-include="itemList.itemListView"></div>

    <wb-order-empty-item-list
      ng-if="!itemList.order.items"
      order-type="{{::itemList.orderType}}"
    ></wb-order-empty-item-list>

    <div class="wb-vertical-spacer large"></div>

    <div layout="row" layout-align="space-between center">
      <md-button
        type="button"
        class="md-raised md-background no-side-margin"
        ng-click="order.prevStep('typeSelector', 'itemList')"
        aria-label="{{::'order.back' | translate}}"
      >
        {{::'order.back' | translate}}
      </md-button>
      <md-button
        analytics-on="click"
        analytics-event="tovabb"
        analytics-category="itemlist"
        analytics-if="!order.isAuthenticated"
        ng-disabled="itemList.order.items.length < 1"
        class="md-raised md-primary no-side-margin"
        ng-click="order.nextStep('contract', 'itemList')"
        ng-hide="itemList.isProcessing()"
      >
        {{::'order.more' | translate}}
      </md-button>
      <div class="button-loader-wrapper" ng-show="itemList.isProcessing()">
        <md-progress-circular
          class="md-hue-2 button-loader"
          md-diameter="36px"
          md-mode="indeterminate"
        ></md-progress-circular>
      </div>
    </div>
  </md-card-content>
</md-card>
