<md-card class="wb-modify-user-data">
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'account.company.company.change' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>

    <form name="companyForm" ng-submit="companyForm.$valid && !company.isProcessing() && company.submit()" novalidate>

      <div layout-sm="column" layout-gt-sm="row" layout-align="space-between start">

        <div flex-gt-sm layout-fill-sm>

          <div class="group-label">
            <md-icon class="zmdi zmdi-case zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.company.companyData' | translate}}
          </div>

          <div class="inset">
            <md-input-container flex>
              <label>{{::'account.company.name' | translate}}</label>
              <input required ng-model="company.company.name" name="name" type="text">
              <wb-messages form="companyForm" server="company.getServerValidation()" field-name="name"></wb-messages>
            </md-input-container>

            <div layout="row">
              <md-input-container flex>
                <label>{{::'account.company.companyNumber' | translate}}</label>
                <input required ng-model="company.company.companyNumber" name="companyNumber" type="text" ui-mask="99-99-999999" mask-fix>
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="companyNumber"></wb-messages>
              </md-input-container>

              <md-input-container flex>
                <label>{{::'account.company.vatNumber' | translate}}</label>
                <input required ng-model="company.company.vatNumber" name="vatNumber" type="text" ui-mask="99999999-9-99" mask-fix>
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="vatNumber"></wb-messages>
              </md-input-container>
            </div>

            <md-input-container flex>
              <label>{{::'account.company.bankAccountNumber' | translate}}</label>
              <input required ng-model="company.company.bankAccountNumber" name="bankAccountNumber" type="text" ui-mask="99999999-99999999-99999999" mask-fix>
              <wb-messages form="companyForm" server="company.getServerValidation()" field-name="bankAccountNumber"></wb-messages>
            </md-input-container>
          </div>

        </div>

        <div flex-gt-sm offset-gt-sm="5" layout-fill-sm>

          <div class="group-label">
            <md-icon class="zmdi zmdi-comment-text zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.company.contact' | translate}}
          </div>

          <div class="inset">      
            <div layout="row">
              <md-input-container flex>
                <label>{{::'account.company.contact.invoiceEmail' | translate}}</label>
                <input required ng-model="company.company.invoiceEmail" name="invoiceEmail" type="email">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="invoiceEmail"></wb-messages>
              </md-input-container>
              <md-input-container flex="50">
                <label>{{::'account.company.deliveryName.name' | translate}}</label>
                <input required ng-model="company.company.deliveryName" name="deliveryName" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="deliveryName"></wb-messages>
              </md-input-container>
            </div>
          </div>

          <div class="group-label">
            <md-icon class="zmdi zmdi-pin zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.company.address' | translate}}
          </div>

          <div class="inset">
            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.address.zipCode' | translate}}</label>
                <input zip-to-city="address.zipCode" receive="false" required ng-model="company.company.address.zipCode" name="address.zipCode" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="address.zipCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.address.city' | translate}}</label>
                <input zip-to-city="address.zipCode" receive="true" required ng-model="company.company.address.city" name="address.city" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="address.city"></wb-messages>
              </md-input-container>
            </div>

            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.address.countryCode' | translate}}</label>
                <md-select placeholder="{{::'account.company.address.countryCode' | translate}}"
                           ng-model="company.company.address.countryCode"
                           required
                           name="address.countryCode"
                           class="full-width-select">
                  <md-option ng-repeat="country in company.countries"
                             value="{{country.alpha3}}">
                    {{country.name}}
                  </md-option>
                </md-select>
                <wb-messages form="companyForm"
                             server="company.getServerValidation()"
                             field-name="address.countryCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.address.address' | translate}}</label>
                <input required ng-model="company.company.address.address" name="address.address" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="address.address"></wb-messages>
              </md-input-container>
            </div>
          </div>
        </div>

      </div>

      <div layout-sm="column" layout-gt-sm="row" layout-align="space-between start">
        <div flex-gt-sm layout-fill-sm>

          <div class="group-label">
            <md-icon class="zmdi zmdi-truck zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.company.deliveryAddress' | translate}}
          </div>

          <div class="inset">
            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.deliveryAddress.zipCode' | translate}}</label>
                <input zip-to-city="deliveryAddress.zipCode" receive="false" required ng-model="company.company.deliveryAddress.zipCode" name="deliveryAddress.zipCode" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="deliveryAddress.zipCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.deliveryAddress.city' | translate}}</label>
                <input zip-to-city="deliveryAddress.zipCode" receive="true" required ng-model="company.company.deliveryAddress.city" name="deliveryAddress.city" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="deliveryAddress.city"></wb-messages>
              </md-input-container>
            </div>

            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.address.countryCode' | translate}}</label>
                <md-select placeholder="{{::'account.company.address.countryCode' | translate}}"
                           ng-model="company.company.deliveryAddress.countryCode"
                           required
                           name="deliveryAddress.countryCode"
                           class="full-width-select">
                  <md-option ng-repeat="country in company.countries"
                             value="{{country.alpha3}}">
                    {{country.name}}
                  </md-option>
                </md-select>
                <wb-messages form="companyForm"
                             server="company.getServerValidation()"
                             field-name="deliveryAddress.countryCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.deliveryAddress.address' | translate}}</label>
                <input required ng-model="company.company.deliveryAddress.address" name="deliveryAddress.address" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="deliveryAddress.address"></wb-messages>
              </md-input-container>
            </div>
          </div>

        </div>
        <div flex-gt-sm offset-gt-sm="5" layout-fill-sm>
          <div class="group-label">
            <md-icon class="zmdi zmdi-money zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.company.invoiceAddress' | translate}}
          </div>

          <div class="inset">
            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.invoiceAddress.zipCode' | translate}}</label>
                <input zip-to-city="invoiceAddress.zipCode" receive="false" required ng-model="company.company.invoiceAddress.zipCode" name="invoiceAddress.zipCode" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="invoiceAddress.zipCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.invoiceAddress.city' | translate}}</label>
                <input zip-to-city="invoiceAddress.zipCode" receive="true" required ng-model="company.company.invoiceAddress.city" name="invoiceAddress.city" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="invoiceAddress.city"></wb-messages>
              </md-input-container>
            </div>

            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.address.countryCode' | translate}}</label>
                <md-select placeholder="{{::'account.company.address.countryCode' | translate}}"
                           ng-model="company.company.invoiceAddress.countryCode"
                           required
                           name="invoiceAddress.countryCode"
                           class="full-width-select">
                  <md-option ng-repeat="country in company.countries"
                             value="{{country.alpha3}}">
                    {{country.name}}
                  </md-option>
                </md-select>
                <wb-messages form="companyForm"
                             server="company.getServerValidation()"
                             field-name="invoiceAddress.countryCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.invoiceAddress.address' | translate}}</label>
                <input required ng-model="company.company.invoiceAddress.address" name="invoiceAddress.address" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="invoiceAddress.address"></wb-messages>
              </md-input-container>
            </div>
          </div>
        </div>
      </div>

      <div layout-gt-sm="row" layout-align-gt-sm="end start">
        <div>
          <md-button ng-hide="company.isProcessing()"
                     class="md-raised md-primary full-width-button-sm" type="submit">
            {{::'account.company.save' | translate}}
          </md-button>
          <div class="button-loader-wrapper" ng-show="company.isProcessing()">
            <md-progress-circular class="md-hue-2 button-loader" md-diameter="36px"
                                  md-mode="indeterminate"></md-progress-circular>
          </div>
        </div>
      </div>

    </form>

  </md-card-content>

</md-card>
