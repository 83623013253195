<md-card>
  <md-toolbar class="wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.steps.first.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content class="wb-order-content" layout-gt-sm="row">
    <div flex-gt-sm>
      <h2>{{::'order.steps.first.subtitle' | translate}}</h2>
      <p ng-bind-html="'order.steps.first.legend' | translate"></p>
    </div>

    <div flex-gt-sm offset-gt-sm="5">
      <form name="companyBasic.companyBasicForm"
            ng-submit="companyBasic.submit()"
            novalidate
            method="post">

        <md-autocomplete
          required
          ng-minlength="2"
          ng-maxlength="100"
          md-input-name="name"
          md-no-cache="true"
          md-selected-item="companyBasic.theCompany"
          md-selected-item-change="companyBasic.updateCompany(item)"
          md-search-text="companyName"
          md-items="item in companyBasic.getCompaniesByName(companyName)"
          md-item-text="item.name"
          md-delay="500"
          md-floating-label="{{::'order.company.name' | translate}}"
          ng-if="!companyBasic.isBackendUnreachable()">

          <md-item-template>
            <span md-highlight-text="companyName">
              {{item.name}} ({{item.vatNumber}})
            </span>
          </md-item-template>

          <wb-messages form="companyBasic.companyBasicForm" server="order.getServerValidation()" field-name="name"></wb-messages>
        </md-autocomplete>

        <md-input-container flex ng-if="companyBasic.isBackendUnreachable()">
          <input type="text" required name="name" ng-model="companyBasic.company.name" ng-minlength="2" ng-maxlength="100">
          <label>{{::'order.companyData.companyName' | translate}}</label>
          <wb-messages form="companyBasic.companyBasicForm" server="companyBasic.getServerValidation()" field-name="name"></wb-messages>
        </md-input-container>

        <md-autocomplete
          required
          ng-minlength="5"
          ng-maxlength="20"
          md-input-name="vatNumber"
          md-no-cache="true"
          md-selected-item="companyBasic.theCompany"
          md-selected-item-change="companyBasic.updateCompany(item)"
          md-search-text="companyVatNumber"
          md-items="item in companyBasic.getCompaniesByTaxNum(companyVatNumber)"
          md-item-text="item.vatNumber"
          md-delay="500"
          md-floating-label="{{::'order.company.taxNum' | translate}}"
          ng-if="!companyBasic.isBackendUnreachable()">

          <md-item-template>
            <span md-highlight-text="companyVatNumber">
              {{item.name}}
            </span>
          </md-item-template>

          <wb-messages form="companyBasic.companyBasicForm" server="order.getServerValidation()" field-name="vatNumber"></wb-messages>
        </md-autocomplete>

        <md-input-container flex ng-if="companyBasic.isBackendUnreachable()">
          <input type="text" required name="vatNumber" ng-model="companyBasic.company.vatNumber" ng-minlength="5" ng-maxlength="20">
          <label>{{::'order.companyData.vatNumber' | translate}}</label>
          <wb-messages form="companyBasic.companyBasicForm" server="companyBasic.getServerValidation()" field-name="vatNumber"></wb-messages>
        </md-input-container>

        <div layout-gt-sm="row" layout-align-gt-sm="end start">
          <md-button analytics-on="click" analytics-event="tovabb" analytics-category="companybasic" analytics-if="!order.isAuthenticated" ng-disabled="!companyBasic.companyBasicForm.$valid" class="md-raised md-accent" type="submit">
            {{::'order.more' | translate}}
          </md-button>
        </div>

      </form>
    </div>

  </md-card-content>
</md-card>
