<md-card>
  <md-toolbar class="wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.' + fileUpload.getSelectedProductType() + '.' + fileUpload.order.type + '.bulk.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <p ng-if="fileUpload.getSelectedProductType() !== 'multiWallet'">{{::'order.bulk.files.info' | translate}}</p>

    <div class="wb-file-upload wb-file-upload--regular" layout-gt-sm="row" layout-align="space-around center"
      layout-margin>
      <div class="wb-file-upload-box wb-file-upload-sample text-center" flex="auto" layout="column"
        layout-align="center center">
        <md-button class="md-fab not-a-button md-accent md-wbTheme-theme"
          aria-label="{{::'order.bulk.files.sampleFile' | translate}}">
          <md-icon md-font-icon="zmdi zmdi-download zmdi-hc-lg zmdi-hc-fw"></md-icon>
        </md-button>

        <p>{{::'order.bulk.files.sampleFile.legend' | translate}}</p>

        <a class="md-button md-raised" ng-click="fileUpload.openSampleAlert()" ng-href="{{::fileUpload.sampleFile}}"
          target="_self" download>{{::'order.bulk.files.sampleFile' | translate}}</a>
      </div>
      <div class="wb-file-upload-box wb-file-upload-input text-center" layout="column" layout-align="center center"
        flex="auto" flow-init flow-file-added="!!{xls:1,xlsx:1}[$file.getExtension()]"
        flow-files-submitted="fileUpload.sendToAPI($file, $event, $flow)"
        flow-file-error="fileUpload.handleAPIErrors($file, $message, $flow)"
        flow-file-success="fileUpload.handleAPISuccess($file, $message, $flow)" flow-drop
        flow-drag-enter="class='is-dragging'" flow-drag-leave="class=''"
        ng-class="[{'has-success': fileUpload.isValidFile()}, {'has-error': !fileUpload.isValidFile() && fileUpload.hasFile()}, class]">
        <md-button class="md-fab not-a-button md-grey md-wbTheme-theme" ng-if="!fileUpload.hasFile()"
          aria-label="{{::'order.bulk.files.uploadFile' | translate}}">
          <md-icon md-font-icon="zmdi zmdi-upload zmdi-hc-lg zmdi-hc-fw"></md-icon>
        </md-button>

        <div class="wb-file-upload-list" ng-if="fileUpload.hasFile()">
          <span>{{ fileUpload.getAvailableFileName() }}</span>
        </div>

        <p>{{::'order.bulk.files.uploadFile.legend' | translate}}</p>

        <div ng-if="fileUpload.fileRelatedError()">
          <p class="error-response">
            {{ 'form.validation.' + fileUpload.fileRelatedError() | translate }}
          </p>
        </div>

        <md-button class="md-raised md-accent" ng-if="!fileUpload.hasFile()" flow-btn>
          {{::'order.bulk.files.uploadFile' | translate}}</md-button>
        <div layout="row" ng-if="fileUpload.hasFile()">
          <md-button class="md-raised md-accent narrow" flow-btn>
            <md-tooltip>{{::'order.bulk.files.changeFile' | translate}}</md-tooltip>
            <md-icon md-font-icon="zmdi zmdi-upload"></md-icon>
          </md-button>

          <md-button class="md-raised narrow" target="_self" download="{{ fileUpload.getAvailableFileName() }}"
            ng-href="{{ fileUpload.getUploadedFileUrl() }}" ng-if="fileUpload.isValidFile()">
            <md-tooltip>{{::'order.bulk.files.download' | translate}}</md-tooltip>
            <md-icon md-font-icon="zmdi zmdi-download"></md-icon>
          </md-button>

          <md-button class="md-raised md-warn narrow" ng-if="fileUpload.isValidFile()"
            ng-click="fileUpload.deleteFile()">
            <md-tooltip>{{::'order.bulk.files.delete' | translate}}</md-tooltip>
            <md-icon md-font-icon="zmdi zmdi-delete"></md-icon>
          </md-button>
        </div>
      </div>
    </div>

    <div layout="row" layout-align="space-between center" layout-margin>
      <md-button type="button" class="md-raised md-background" ng-click="order.prevStep('typeSelector', 'fileUpload')"
        aria-label="{{::'order.back' | translate}}">
        {{::'order.back' | translate}}
      </md-button>
      <md-button analytics-on="click" analytics-event="tovabb" analytics-category="itemlist"
        analytics-if="!order.isAuthenticated" ng-disabled="!fileUpload.isValidFile()" class="md-raised md-primary"
        ng-click="order.nextStep('contract', 'fileUpload')" ng-hide="fileUpload.isProcessing()">
        {{::'order.more' | translate}}
      </md-button>
      <div class="button-loader-wrapper" ng-show="fileUpload.isProcessing()">
        <md-progress-circular class="md-hue-2 button-loader" md-diameter="36px" md-mode="indeterminate">
        </md-progress-circular>
      </div>
    </div>
  </md-card-content>
</md-card>
