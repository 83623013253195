<md-card class="comfort-card-item-list">
  <md-toolbar class="wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.comfortCard.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <p>{{::'order.comfortCard.info' | translate}}</p>

    <div class="wb-vertical-spacer large"></div>

    <div class="wb-table" ng-if="comfortCardItemList.order.items">
      <div class="wb-table-header" layout="row" layout-align="start center" flex>
        <span flex="40">{{::'order.comfortCard.itemList.loadAmountPerCard' | translate}}</span>
        <span flex="20" class="text-center">{{::'order.comfortCard.itemList.quantity' | translate}}</span>
        <span flex="25" class="text-right">{{::'order.comfortCard.itemList.loadAmount' | translate}}</span>
        <span flex class="text-right">
          <md-button class="md-icon-button md-button-sm md-background md-fab"
            ng-click="comfortCardItemList.openItemDialog()">
            <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-plus"></md-icon>
          </md-button>
        </span>
      </div>

      <div class="wb-table-item" layout="row" layout-align="start center"
        ng-repeat="orderItem in comfortCardItemList.order.items">
        <span flex="40">{{ orderItem.loadAmount | isoCurrency: 'HUF':0 }}</span>
        <span flex="20" class="text-center">{{ orderItem.quantity }}</span>
        <span flex="25" class="text-right">{{
          orderItem.loadAmount * orderItem.quantity | isoCurrency: 'HUF':0
        }}</span>
        <span flex class="text-right">
          <md-button class="md-icon-button md-button-sm" ng-click="comfortCardItemList.editItem($index)">
            <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-edit"></md-icon>
          </md-button>

          <md-button class="md-icon-button md-button-sm" ng-click="comfortCardItemList.deleteItem($index)">
            <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-delete"></md-icon>
          </md-button>
        </span>
      </div>
    </div>

    <div v-if="comfortCardItemList.order.items.length" class="comfort-card-item-list-sum" layout="row"
      layout-align="start center">
      <span flex="40">
        {{::'order.comfortCard.itemList.sum' | translate}}
      </span>
      <span flex="20" class="text-center">
        {{comfortCardItemList.getSum('quantity')}} db
      </span>
      <span flex="25" class="text-right">{{comfortCardItemList.getSum('loadAmount') | isoCurrency: 'HUF':0}}</span>
      <span flex></span>
    </div>

    <div layout="row" layout-align="space-between center">
      <md-button type="button" class="md-raised md-background no-side-margin"
        ng-click="order.prevStep('typeSelector', 'comfortCardItemList')" aria-label="{{::'order.back' | translate}}">
        {{::'order.back' | translate}}
      </md-button>
      <md-button analytics-on="click" analytics-event="tovabb" analytics-category="itemList"
        analytics-if="!order.isAuthenticated" class="md-raised md-accent no-side-margin"
        ng-click="order.nextStep('contract', 'comfortCardItemList')" ng-hide="comfortCardItemList.isProcessing()">
        {{::'order.comfortCard.submit' | translate}}
      </md-button>
      <div class="button-loader-wrapper" ng-show="comfortCardItemList.isProcessing()">
        <md-progress-circular class="md-hue-2 button-loader" md-diameter="36px" md-mode="indeterminate">
        </md-progress-circular>
      </div>
    </div>
  </md-card-content>
</md-card>
