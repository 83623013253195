<md-card>
  <md-card-content>
    <header class="wb-card-header">
      <h2 class="md-title">
        {{:: 'info.cards.title' | translate}}
      </h2>
      <small class="wb-subtitle">{{:: 'info.cards.description' | translate}}</small>
    </header>
    <ul class="wb-card-types">
      <li class="wb-card-type" ng-repeat="cardType in wbCardTypeInfo.getCardTypesByCategory('comfort')">
        <md-button href="{{::cardType.url}}" target="_blank">{{::cardType.title}}</md-button>
      </li>
      <li class="wb-card-type" ng-repeat="cardType in wbCardTypeInfo.getCardTypesByCategory('multiWallet')">
        <md-button href="{{::cardType.url}}" target="_blank">{{::cardType.title}}</md-button>
      </li>
    </ul>
    <h3 class="wb-card-types-category">{{:: 'info.cards.family.title' | translate}}</h3>
    <ul class="wb-card-types">
      <li class="wb-card-type" ng-repeat="cardType in wbCardTypeInfo.getCardTypesByCategory('family')">
        <md-button href="{{::cardType.url}}" target="_blank">{{::cardType.title}}</md-button>
      </li>
    </ul>
    <h3 class="wb-card-types-category">{{:: 'info.cards.beneficial.title' | translate}}</h3>
    <ul class="wb-card-types">
      <li class="wb-card-type" ng-repeat="cardType in wbCardTypeInfo.getCardTypesByCategory('benefit')">
        <md-button href="{{::cardType.url}}" target="_blank">{{::cardType.title}}</md-button>
      </li>
    </ul>
  </md-card-content>
</md-card>
