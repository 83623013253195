<md-dialog class="wb-item-dialog wb-comparison-table-dialog wb-dialog-extra-tall">
  <h2 class="wb-item-dialog-title">{{::'order.cardComparison.title' | translate}}</h2>

  <div class="wb-comparison-table">
    <div class="wb-comparison-table-row wb-comparison-table-row-header" layout="row" layout-align="space-between stretch">
      <div class="wb-comparison-table-item wb-comparison-table-item-legend"></div>
      <div class="wb-comparison-table-item">
        <img src="../../images/rewin-virtualis-kartya.svg" alt="{{::'order.comparisonTable.cards.1.name' | translate}}" class="wb-comparison-table-item-image">
        <span class="wb-comparison-table-item-name">{{::'order.comparisonTable.cards.1.name' | translate}}</span>
      </div>
      <div class="wb-comparison-table-item">
        <img src="../../images/rewin-fizikai-kartya.svg" alt="{{::'order.comparisonTable.cards.2.name' | translate}}" class="wb-comparison-table-item-image">
        <span class="wb-comparison-table-item-name">{{::'order.comparisonTable.cards.2.name' | translate}}</span>
      </div>
    </div>

    <div class="wb-comparison-table-row" layout="row" layout-align="space-between stretch">
      <div class="wb-comparison-table-item wb-comparison-table-item-legend wb-comparison-table-item-vertical-centered">
        {{::'order.comparisonTable.features.1' | translate}}
      </div>
      <div class="wb-comparison-table-item wb-comparison-table-item-centered wb-comparison-table-item-align-center">
        <md-icon md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-check-circle"></md-icon>
      </div>
      <div class="wb-comparison-table-item wb-comparison-table-item-centered wb-comparison-table-item-align-center">
        <md-icon md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-check-circle"></md-icon>
      </div>
    </div>

    <div class="wb-comparison-table-row" layout="row" layout-align="space-between stretch">
      <div class="wb-comparison-table-item wb-comparison-table-item-legend wb-comparison-table-item-vertical-centered">
        {{::'order.comparisonTable.features.2' | translate}}
      </div>
      <div class="wb-comparison-table-item wb-comparison-table-item-centered wb-comparison-table-item-align-center">
        <md-icon md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-check-circle"></md-icon>
      </div>
      <div class="wb-comparison-table-item wb-comparison-table-item-centered wb-comparison-table-item-align-center">
        <md-icon md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-check-circle"></md-icon>
      </div>
    </div>

    <div class="wb-comparison-table-row" layout="row" layout-align="space-between stretch">
      <div class="wb-comparison-table-item wb-comparison-table-item-legend wb-comparison-table-item-vertical-centered">
        {{::'order.comparisonTable.features.3' | translate}}
      </div>
      <div class="wb-comparison-table-item wb-comparison-table-item-centered wb-comparison-table-item-align-center">
        <md-icon md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-check-circle"></md-icon>
      </div>
      <div class="wb-comparison-table-item wb-comparison-table-item-centered wb-comparison-table-item-align-center">
        <md-icon md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-check-circle"></md-icon>
      </div>
    </div>

    <div class="wb-comparison-table-row" layout="row" layout-align="space-between stretch">
      <div class="wb-comparison-table-item wb-comparison-table-item-legend wb-comparison-table-item-vertical-centered">
        {{::'order.comparisonTable.features.4' | translate}}
      </div>
      <div class="wb-comparison-table-item wb-comparison-table-item-centered wb-comparison-table-item-align-center">
        <md-icon md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-check-circle"></md-icon>
      </div>
      <div class="wb-comparison-table-item wb-comparison-table-item-centered wb-comparison-table-item-align-center">
        <md-icon md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-check-circle"></md-icon>
      </div>
    </div>

    <div class="wb-comparison-table-row" layout="row" layout-align="space-between stretch">
      <div class="wb-comparison-table-item wb-comparison-table-item-legend wb-comparison-table-item-vertical-centered">
        {{::'order.comparisonTable.features.5' | translate}}
      </div>
      <div class="wb-comparison-table-item wb-comparison-table-item-centered wb-comparison-table-item-align-center">
        <md-icon md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-check-circle"></md-icon>
      </div>
      <div class="wb-comparison-table-item wb-comparison-table-item-centered wb-comparison-table-item-align-center">
        <md-icon md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-close-circle"></md-icon>
      </div>
    </div>

    <div class="wb-comparison-table-row" layout="row" layout-align="space-between stretch">
      <div class="wb-comparison-table-item wb-comparison-table-item-legend wb-comparison-table-item-vertical-centered">
        {{::'order.comparisonTable.features.6' | translate}}
      </div>
      <div class="wb-comparison-table-item wb-comparison-table-item-centered wb-comparison-table-item-align-center">
        <md-icon md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-check-circle"></md-icon>
      </div>
      <div class="wb-comparison-table-item wb-comparison-table-item-centered wb-comparison-table-item-align-center">
        <md-icon md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-close-circle"></md-icon>
      </div>
    </div>

  <div class="md-actions">
    <md-button ng-click="comparisonDialog.closeDialog()" class="md-primary" type="button">
      {{::'order.cardComparison.close' | translate}}
    </md-button>
  </div>
</md-dialog>
