<md-dialog class="wb-item-dialog wb-dialog-wide wb-dialog-tall">
  <form
    name="itemDialog.itemDialogForm"
    ng-submit="itemDialog.itemDialogForm.$valid && itemDialog.submit()"
    ng-class="{'ng-submitted': itemDialog.forceSubmittedClass}"
    novalidate
    method="post"
  >
    <h2 class="wb-item-dialog-title">
      {{::'order.itemDialog.' + itemDialog.selectedProductType + '.' + itemDialog.orderType + '.' + (itemDialog.isNewItem ? 'create' : 'edit') + '.title' | translate}}
    </h2>

    <div ng-include="itemDialog.itemForm"></div>

    <div
      ng-if="itemDialog.isOrderNewCardType() && itemDialog.isNewItem"
      class="no-top-margin"
      layout="row"
      layout-margin
    >
      <div class="no-top-margin" flex="50">
        <p class="no-top-margin">{{::'order.itemDialog.card.legend' | translate}}</p>
        <md-button class="md-raised no-side-margin"
                   ng-hide="itemDialog.isProcessing()"
                   type="button"
                   ng-click="itemDialog.submitAndCopy()">
          {{::'order.itemDialog.card.' + itemDialog.selectedProductType + '.saveAndContinue' | translate}}
        </md-button>
        <div class="button-loader-wrapper" ng-show="itemDialog.isProcessing()">
          <md-progress-circular
            class="md-hue-2 button-loader"
            md-diameter="36px"
            md-mode="indeterminate"
          ></md-progress-circular>
        </div>
      </div>
    </div>

    <div class="md-actions">
      <md-button
        ng-click="itemDialog.closeDialog()"
        class="md-primary"
        type="button"
      >
        {{::'order.itemDialog.close' | translate}}
      </md-button>

      <md-button
        class="md-primary"
        ng-hide="itemDialog.isProcessing()"
        type="submit"
      >
        {{::'order.itemDialog.save' | translate}}
      </md-button>
      <div class="button-loader-wrapper" ng-show="itemDialog.isProcessing()">
        <md-progress-circular
          class="md-hue-2 button-loader"
          md-diameter="36px"
          md-mode="indeterminate"
        ></md-progress-circular>
      </div>
    </div>
  </form>
</md-dialog>
