(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ecs.constant:cardTypeMap
   *
   * @description
   *
   */
  angular
    .module('ecs')
    .constant('cardTypeMap', {
      cafeteria: 1,
      multiWallet: 2,
      benefit: 3,
      comfort: 4,
      family: 5,
      default: 3
    });
}());
