<md-card>
  <md-toolbar class="md-accent">
    <div class="md-toolbar-tools">
      {{::'order.thankYou' | translate}}
    </div>
  </md-toolbar>

  <md-card-content class="wb-order-success text-center" layout-gt-sm="column">
    <div ng-if="success.isVipOrder()">
      <h2>{{::'order.success.vipHeading' | translate}}</h2>
      <p ng-bind-html="'order.success.vipContent' | translate : {email: success.user.email}"></p>
    </div>

    <div
      ng-if="!success.isVipOrder() && success.getSelectedProductType() !== 'multiWallet' || (success.getSelectedProductType() === 'multiWallet' && !success.isNewCardOrder())">
      <h2>{{::'order.success.furtherSteps' | translate}}</h2>

      <div class="wb-success-steps"
        ng-class="{'layout-align-center-start': !success.hasCompleteStepProcess(), 'layout-align-space-around-start': success.hasCompleteStepProcess()}"
        layout="row">
        <div class="wb-success-step" flex="30" layout-padding ng-repeat-start="step in success.getFurtherSteps()">
          <md-icon
            md-font-icon="wb-success-step-icon md-{{::step.colorClass}} zmdi zmdi-{{::step.icon}} zmdi-hc-lg zmdi-hc-fw">
          </md-icon>
          <h3>{{::step.title}}</h3>
          <p ng-bind-html="::step.legend"></p>
        </div>

        <div class="wb-success-mark" flex="5" ng-if="!$last" ng-repeat-end>
          <span class="zmdi-hc-stack zmdi-hc-lg">
            <i class="zmdi zmdi-circle zmdi-hc-stack-2x"></i>
            <i class="zmdi zmdi-chevron-right zmdi-hc-stack-1x zmdi-hc-inverse"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="wb-success-step-list text-left"
      ng-if="success.isMultiwalletCardOrder()">
      <h2>{{::'order.success.furtherSteps' | translate}}</h2>

      <div class="wb-success-steps" layout="column">
        <div class="wb-success-step" layout="row" layout-padding ng-repeat="step in success.getFurtherSteps()">
          <md-icon md-font-icon="wb-success-step-icon zmdi zmdi-{{::step.icon}} zmdi-hc-lg zmdi-hc-fw">
          </md-icon>
          <div class="wb-success-step-content" layout="column" layout-align="center start">
            <h3>{{::step.title}}</h3>
            <p ng-bind-html="::step.legend"></p>
          </div>
        </div>
      </div>
    </div>

    <div flex-gt-sm="100" layout="row" layout-align="end center">
      <md-button class="md-raised" type="button" ui-sref="dashboard" ng-if="!success.isMultiwalletCardOrder()">
        {{::'order.backToHome' | translate}}
      </md-button>
      <md-button class="md-raised" type="button" ui-sref="multiWalletDataReconciliationList" ng-if="success.isMultiwalletCardOrder()">
        {{::'order.toDataReconciliationList' | translate}}
      </md-button>
    </div>
  </md-card-content>
</md-card>
