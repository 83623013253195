<div class="wb-empty-order" layout="column" layout-align="center center">
  <md-icon md-font-icon="zmdi zmdi-card"></md-icon>
  <span
    class="wb-empty-order-title"
    >{{::'order.itemList.' + wbOrderEmptyItemList.orderType + '.' + 'emptyList.title' | translate}}</span
  >
  <span
    class="wb-empty-order-legend"
    >{{::'order.itemList.' + wbOrderEmptyItemList.orderType + '.' + 'emptyList.info' | translate}}</span
  >
</div>
