<md-card>
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">{{::'order.invoice.title' | translate}}</div>
  </md-toolbar>

  <md-card-content layout-gt-sm="column">
    <form
      name="invoice.invoiceForm"
      ng-submit="invoice.submit()"
      novalidate
      method="post"
    >
      <h2 class="wb-invoice-section-header">
        {{::'order.invoice.' + invoice.orderType + '.title' | translate}}
      </h2>

      <!-- Új kártyák -->
      <div
        class="wb-table wb-invoice-table"
        ng-if="!invoice.isMultiWalletCardSelected()"
      >
        <div class="wb-table-header" layout="row">
          <span flex="40">{{::'order.invoice.item' | translate}}</span>
          <span flex="15" class="text-center"
            >{{::'order.invoice.qty' | translate}}</span
          >
          <span flex="45" class="text-right"
            >{{::'order.amount.header' | translate}}</span
          >
        </div>

        <div
          class="wb-table-item"
          layout="row"
          ng-repeat="item in invoice.cartOrder.items"
        >
          <span flex="40">
            <span ng-if="!invoice.isRequestOrderType()"
              >{{::'order.invoice.cardType.topup.title' | translate}}</span
            >
            <span ng-if="invoice.isRequestOrderType()"
              >{{::'order.invoice.cardType.' + item.cardTypeId + '.title' |
              translate}}</span
            >
          </span>
          <span flex="15" class="text-center">{{::item.quantity}}</span>
          <span flex="45" class="text-right"
            ><strong>{{::item.loadAmount | isoCurrency:'HUF':0}}</strong></span
          >
        </div>
      </div>

      <div
        class="wb-table wb-invoice-table"
        ng-if="invoice.isMultiWalletCardSelected()"
      >
        <div class="wb-table-header" layout="row">
          <span flex="50">{{::'order.invoice.wallets' | translate}}</span>
          <span flex="50" class="text-right"
            >{{::'order.invoice.sum' | translate}}</span
          >
        </div>

        <div
          class="wb-table-item"
          layout="row"
          ng-repeat="wallet in invoice.wallets"
        >
          <span flex="50"> {{wallet.name | translate}} </span>
          <span flex="50" class="text-right"
            ><strong
              >{{invoice.getTotalAmountByWallet(wallet.field) |
              isoCurrency:'HUF':0}}</strong
            ></span
          >
        </div>
      </div>

      <!-- Egyéb költségek -->
      <!-- <div class="wb-table-fees" ng-if="!invoice.isMultiWalletCardSelected()">
        <h2 class="wb-invoice-section-header">{{::'order.invoice.additionalFees' | translate}}</h2>

        <div class="wb-table">
          <div class="wb-table-header" layout="row">
            <span flex="40">{{::'order.invoice.fees' | translate}}</span>
            <span flex="15" class="text-center">{{::'order.invoice.qty' | translate}}</span>
            <span flex="15" class="text-right">{{::'order.invoice.net' | translate}}</span>
            <span flex="15" class="text-right">{{::'order.invoice.vat' | translate}}</span>
            <span flex="15" class="text-right">{{::'order.invoice.gross' | translate}}</span>
          </div>

          <div class="wb-table-item" layout="row" ng-repeat="handlingFee in invoice.cartOrder.handlingFees">
            <span flex="40">{{::'order.invoice.cardType.' + handlingFee.cardTypeId + '.title' | translate}}
              {{::'order.invoice.topUpFee' | translate:{handlingFeePercent: handlingFee.percent} }}</span>
            <span flex="15" class="text-center">1</span>
            <span flex="15" class="text-right">{{::handlingFee.net | isoCurrency:'HUF':0}}</span>
            <span flex="15" class="text-right">{{::(handlingFee.gross-handlingFee.net) | isoCurrency:'HUF':0}}</span>
            <span flex="15" class="text-right"><strong>{{::handlingFee.gross | isoCurrency:'HUF':0}}</strong></span>
          </div>
          <div class="wb-table-item" layout="row" ng-if="invoice.isRequestOrderType()"
            ng-repeat="cardFee in invoice.cartOrder.cardFees">
            <span flex="40">{{::'order.invoice.cardType.' + cardFee.cardTypeId + '.title' | translate}}
              {{::'order.invoice.cardFee' | translate}}</span>
            <span flex="15" class="text-center">{{::cardFee.quantity}}</span>
            <span flex="15" class="text-right">{{::cardFee.net | isoCurrency:'HUF':0}}
              ({{cardFee.net / cardFee.quantity | isoCurrency:'HUF':0}}/db)</span>
            <span flex="15" class="text-right">{{::(cardFee.gross-cardFee.net) | isoCurrency:'HUF':0}}</span>
            <span flex="15" class="text-right"><strong>{{::cardFee.gross | isoCurrency:'HUF':0}}</strong></span>
          </div>
          <div class="wb-table-item" layout="row" ng-if="invoice.isRequestOrderType()">
            <span flex="40">{{::'order.invoice.shipping' | translate}}</span>
            <span flex="15" class="text-center">1</span>
            <span flex="15" class="text-right">{{::invoice.cartOrder.deliveryFeeNet | isoCurrency:'HUF':0}}</span>
            <span flex="15"
              class="text-right">{{::(invoice.cartOrder.deliveryFeeGross-invoice.cartOrder.deliveryFeeNet) | isoCurrency:'HUF':0}}</span>
            <span flex="15"
              class="text-right"><strong>{{::invoice.cartOrder.deliveryFeeGross | isoCurrency:'HUF':0}}</strong></span>
          </div>
        </div>
      </div> -->

      <div
        class="wb-invoice-sum"
        layout="row"
        layout-align="space-between center"
      >
        <span>{{::'order.invoice.total' | translate}}</span>
        <span class="sum"
          >{{::invoice.cartOrder.totalGross | isoCurrency:'HUF':0}}</span
        >
      </div>

      <div
        class="wb-order-card-activation-notice"
        ng-if="invoice.shouldShowActivationAlert()"
      >
        <md-icon md-font-icon="zmdi zmdi-alert-circle-o zmdi-hc-3x"></md-icon>
        <div
          ng-bind-html="::'order.invoice.cardActivationNotice' | translate"
        ></div>
      </div>

      <div
        class="wb-additional-information"
        layout="row"
        layout-wrap
        ng-if="!invoice.isMultiWalletCardSelected()"
      >
        <div
          class="item"
          flex="50"
          layout="row"
          layout-align="start start"
          ng-if="invoice.isRequestOrderType()"
        >
          <div class="icon-wrapper" flex>
            <md-icon
              md-font-icon="zmdi zmdi-pin zmdi-hc-lg zmdi-hc-fw"
            ></md-icon>
          </div>
          <div flex="auto">
            <h3>{{::'order.invoice.shippingAddress' | translate}}</h3>

            <p ng-if="!invoice.hasCompanyDeliveryAddress()">
              {{invoice.company.name}} {{invoice.company.address.zipCode}},
              {{invoice.company.address.city}}
              {{invoice.company.address.address}}
            </p>
            <p ng-if="invoice.hasCompanyDeliveryAddress()">
              {{invoice.company.name}}
              {{invoice.company.deliveryAddress.zipCode}},
              {{invoice.company.deliveryAddress.city}}
              {{invoice.company.deliveryAddress.address}}
            </p>
          </div>
        </div>

        <div
          class="item"
          flex="50"
          layout="row"
          layout-align="start start"
          ng-if="invoice.isRequestOrderType()"
        >
          <div class="icon-wrapper" flex>
            <md-icon
              md-font-icon="zmdi zmdi-time zmdi-hc-lg zmdi-hc-fw"
            ></md-icon>
          </div>
          <div flex="auto">
            <h3>{{::'order.invoice.estShipping' | translate}}</h3>

            <p>{{::'order.invoice.estShippingLegend' | translate}}</p>
          </div>
        </div>

        <div
          class="item"
          flex="50"
          layout="row"
          layout-align="start start"
          ng-if="invoice.isRequestOrderType()"
        >
          <div class="icon-wrapper" flex>
            <md-icon
              md-font-icon="zmdi zmdi-account zmdi-hc-lg zmdi-hc-fw"
            ></md-icon>
          </div>
          <div flex="auto">
            <h3>{{::'order.invoice.recipient' | translate}}</h3>

            <p>
              {{invoice.company.deliveryLastName}}
              {{invoice.company.deliveryFirstName}}
            </p>
          </div>
        </div>

        <div
          class="item"
          flex="50"
          layout="row"
          layout-align="start start"
          ng-class="{'offset-50': !invoice.isRequestOrderType()}"
        >
          <div class="icon-wrapper" flex>
            <md-icon
              md-font-icon="zmdi zmdi-comment-more zmdi-hc-lg zmdi-hc-fw"
            ></md-icon>
          </div>
          <div flex="auto">
            <h3>{{::'order.invoice.notes' | translate}}</h3>
            <md-input-container flex>
              <label>{{::'order.invoice.notes' | translate}}</label>
              <textarea
                ng-model="invoice.cartOrder.note"
                columns="1"
                rows="3"
                md-maxlength="500"
                ng-maxlength="500"
              ></textarea>
              <wb-messages
                form="invoice.invoiceForm"
                server="invoice.getServerValidation()"
                field-name="invoiceEmail"
              >
              </wb-messages>
            </md-input-container>
          </div>
        </div>
      </div>

      <div flex-gt-sm="100" layout="row" layout-align="space-between center">
        <md-button
          type="button"
          class="md-raised md-background"
          ng-click="order.prevStep(invoice.prevState)"
          aria-label="{{::'order.back' | translate}}"
        >
          {{::'order.back' | translate}}
        </md-button>

        <md-button
          analytics-on="click"
          analytics-event="kattintas"
          analytics-category="megrendeles"
          analytics-if="!order.isAuthenticated"
          ng-disabled="!invoice.invoiceForm.$valid"
          ng-hide="invoice.isProcessing()"
          class="md-raised md-primary"
          type="submit"
        >
          {{::'order.sendOrder' | translate}}
        </md-button>
        <div class="button-loader-wrapper" ng-show="invoice.isProcessing()">
          <md-progress-circular
            class="md-hue-2 button-loader"
            md-diameter="36px"
            md-mode="indeterminate"
          >
          </md-progress-circular>
        </div>
      </div>
    </form>
  </md-card-content>
</md-card>
