<form class="wb-contract-form"
      name="contract.contractForm"
      ng-submit="contract.submit()"
      method="post">

  <div>
    <ng-form name="firstBox">
      <md-card>
        <md-toolbar ng-class="{'md-accent': firstBox.$valid, 'md-warn': !firstBox.$valid}">
          <div class="md-toolbar-tools">
            <span>{{::'order.companyData.details' | translate}}</span>
            <span flex></span>
            <md-button class="wb-status-icon-wrapper not-a-button" aria-label="{{::'order.validation' | translate}}">
              <md-icon md-font-icon="zmdi zmdi-alert-circle zmdi-hc-lg zmdi-hc-fw" ng-class="{'zmdi-mood': firstBox.$valid, 'zmdi-alert-circle': !firstBox.$valid}"></md-icon>
            </md-button>
          </div>
        </md-toolbar>

        <md-card-content layout="row" layout-margin layout-wrap>
          <div layout-fill layout-padding>
            <h3 class="wb-form-section-title">{{::'order.companyData.details' | translate}}</h3>
          </div>

          <div flex>
            <div layout="row">
              <md-autocomplete
                class="wb-autocomplete"
                required
                ng-minlength="2"
                ng-maxlength="100"
                md-input-name="name"
                md-no-cache="true"
                md-selected-item="contract.selectedCompany"
                md-selected-item-change="contract.updateCompany(item)"
                md-search-text="companyName"
                md-items="item in contract.getCompaniesByName(companyName)"
                md-item-text="item.name"
                md-delay="500"
                md-floating-label="{{::'order.companyData.companyName' | translate}}"
                ng-if="!contract.backendUnreachable">

                <md-item-template>
                  <span md-highlight-text="companyName">
                    {{item.name}}
                  </span>
                </md-item-template>

                <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="name"></wb-messages>
              </md-autocomplete>

              <md-input-container flex ng-if="contract.backendUnreachable">
                <input type="text" required name="name" ng-model="contract.company.name" ng-minlength="2" ng-maxlength="100">
                <label>{{::'order.companyData.companyName' | translate}}</label>
                <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="name"></wb-messages>
              </md-input-container>

              <md-input-container flex>
                <md-icon class="wb-tooltip zmdi zmdi-help zmdi-hc-lg"></md-icon>

                <md-tooltip md-direction="bottom">
                  <span ng-bind-html="::'account.company.nameOnCard.tooltip' | translate"></span>
                </md-tooltip>

                <input type="text" name="nameOnCard" ng-model="contract.company.nameOnCard" ng-minlength="2" ng-maxlength="21" required>
                <label>{{::'order.companyData.nameOnCard' | translate}}</label>
                <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="nameOnCard"></wb-messages>
              </md-input-container>
            </div>

            <md-input-container flex>
              <input type="text" required name="bankAccountNumber" ng-model="contract.company.bankAccountNumber" ng-minlength="10" ng-maxlength="30" ui-mask="99999999-99999999-99999999" mask-fix>
              <label>{{::'order.administration.proforma.bankAccount' | translate}}</label>
              <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="bankAccountNumber"></wb-messages>
            </md-input-container>
          </div>

          <div flex>
            <div layout="row">
              <md-input-container flex>
                <input type="text" required name="companyNumber" ng-model="contract.company.companyNumber" ng-minlength="5" ng-maxlength="20" ui-mask="99-99-999999" mask-fix>
                <label>{{::'order.companyData.companyNumber' | translate}}</label>
                <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="companyNumber"></wb-messages>
              </md-input-container>

              <md-autocomplete
                class="wb-autocomplete"
                required
                ng-minlength="5"
                ng-maxlength="20"
                md-input-name="vatNumber"
                md-no-cache="true"
                md-selected-item="contract.selectedCompany"
                md-selected-item-change="contract.updateCompany(item)"
                md-search-text="companyVatNumber"
                md-items="item in contract.getCompaniesByTaxNum(companyVatNumber)"
                md-item-text="item.vatNumber"
                md-delay="500"
                md-floating-label="{{::'order.companyData.vatNumber' | translate}}"
                ng-if="!contract.backendUnreachable">

                <md-item-template>
                  <span md-highlight-text="companyVatNumber">
                    {{item.name}}
                  </span>
                </md-item-template>

                <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="vatNumber"></wb-messages>
              </md-autocomplete>

              <md-input-container flex ng-if="contract.backendUnreachable">
                <input type="text" required name="vatNumber" ng-model="contract.company.vatNumber" ng-minlength="5" ng-maxlength="20">
                <label>{{::'order.companyData.vatNumber' | translate}}</label>
                <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="vatNumber"></wb-messages>
              </md-input-container>
            </div>

            <md-input-container flex>
              <input type="text" required name="staffCount" ng-model="contract.company.staffCount" ng-minlength="1" ng-maxlength="10">
              <label>{{::'order.administration.proforma.employeeNumber' | translate}}</label>
              <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="staffCount"></wb-messages>
            </md-input-container>
          </div>

          <div layout="row" layout-align="start start" layout-fill>
            <div flex layout="row" layout-margin layout-wrap>
              <div class="wb-form-section-header">
                <h3 class="wb-form-section-title" flex="50">{{::'order.companyData.hq' | translate}}</h3>
              </div>

              <div layout="row" layout-fill>
                <md-input-container flex="35">
                  <input zip-to-city="address.zipCode" receive="false" type="text" required name="address.zipCode" ng-model="contract.company.address.zipCode" ng-minlength="1" ng-maxlength="10">
                  <label>{{::'order.companyData.zipCode' | translate}}</label>
                  <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="address.zipCode"></wb-messages>
                </md-input-container>

                <md-input-container flex="65">
                  <input zip-to-city="address.zipCode" receive="true" type="text" required name="address.city" ng-model="contract.company.address.city" ng-minlength="1" ng-maxlength="100">
                  <label>{{::'order.companyData.city' | translate}}</label>
                  <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="address.city"></wb-messages>
                </md-input-container>
              </div>

              <div layout="row" layout-fill>
                <md-input-container flex="35">
                  <md-select placeholder="{{::'account.company.address.countryCode' | translate}}"
                             ng-model="contract.company.address.countryCode"
                             required
                             name="address.countryCode"
                             class="full-width-select">
                    <md-option ng-repeat="country in contract.countries"
                               value="{{country.alpha3}}">
                      {{country.name}}
                    </md-option>
                  </md-select>
                  <label>{{::'account.company.address.countryCode' | translate}}</label>
                  <wb-messages form="firstBox"
                               server="contract.getServerValidation()"
                               field-name="address.countryCode"></wb-messages>
                </md-input-container>

                <md-input-container flex="65">
                  <input type="text" required name="address.address" ng-model="contract.company.address.address" ng-minlength="5" ng-maxlength="100">
                  <label>{{::'order.companyData.address' | translate}}</label>
                  <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="address.address"></wb-messages>
                </md-input-container>
              </div>

              <div layout="row" layout-fill>
                <md-input-container flex>
                  <input type="text" required name="company.deliveryLastName" ng-model="contract.company.deliveryLastName" ng-minlength="2" ng-maxlength="50">
                  <label>{{::'order.deliveredTo.lastName' | translate}}</label>
                  <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="company.deliveryLastName"></wb-messages>
                </md-input-container>

                <md-input-container flex>
                  <input type="text" required name="company.deliveryFirstName" ng-model="contract.company.deliveryFirstName" ng-minlength="2" ng-maxlength="50">
                  <label>{{::'order.deliveredTo.firstName' | translate}}</label>
                  <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="company.deliveryFirstName"></wb-messages>
                </md-input-container>
              </div>

              <md-checkbox
                name="differentInvoiceAddress"
                class="md-align-top-left"
                ng-model="contract.differentInvoiceAddress">
                {{::'order.companyData.billingDifferentFromHq' | translate}}
              </md-checkbox>

              <md-checkbox
                name="differentDeliveryAddress"
                class="md-align-top-left"
                ng-model="contract.differentDeliveryAddress">
                {{::'order.companyData.shippingDifferentFromHq' | translate}}
              </md-checkbox>
            </div>

            <div flex layout="row" layout-margin layout-wrap>
              <div ng-show="contract.differentInvoiceAddress" layout-fill>
                <div class="wb-form-section-header">
                  <h3 class="wb-form-section-title">{{::'order.companyData.billingData' | translate}}</h3>
                </div>

                <div layout="row">
                  <md-input-container flex="35">
                    <input zip-to-city="invoiceAddress.zipCode" receive="false" type="text" name="invoiceAddress.zipCode" ng-model="contract.company.invoiceAddress.zipCode" ng-minlength="1" ng-maxlength="10">
                    <label>{{::'order.companyData.zipCode' | translate}}</label>
                    <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="invoiceAddress.zipCode"></wb-messages>
                  </md-input-container>

                  <md-input-container flex="65">
                    <input zip-to-city="invoiceAddress.zipCode" receive="true" type="text" name="invoiceAddress.city" ng-model="contract.company.invoiceAddress.city" ng-minlength="1" ng-maxlength="100">
                    <label>{{::'order.companyData.city' | translate}}</label>
                    <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="invoiceAddress.city"></wb-messages>
                  </md-input-container>
                </div>

                <div layout="row">
                  <md-input-container flex="35">
                    <md-select placeholder="{{::'account.company.address.countryCode' | translate}}"
                               ng-model="contract.company.invoiceAddress.countryCode"
                               name="invoiceAddress.countryCode"
                               class="full-width-select">
                      <md-option ng-repeat="country in contract.countries"
                                 value="{{country.alpha3}}">
                        {{country.name}}
                      </md-option>
                    </md-select>
                    <label>{{::'account.company.address.countryCode' | translate}}</label>
                    <wb-messages form="firstBox"
                                 server="contract.getServerValidation()"
                                 field-name="invoiceAddress.countryCode"></wb-messages>
                  </md-input-container>

                  <md-input-container flex="65">
                    <input type="text" name="invoiceAddress.address" ng-model="contract.company.invoiceAddress.address" ng-minlength="5" ng-maxlength="100">
                    <label>{{::'order.companyData.address' | translate}}</label>
                    <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="invoiceAddress.address"></wb-messages>
                  </md-input-container>
                </div>
              </div>

              <div ng-show="contract.differentDeliveryAddress" layout-fill>
                <div class="wb-form-section-header">
                  <h3 class="wb-form-section-title">{{::'order.companyData.shippingData' | translate}}</h3>
                </div>

                <div layout="row">
                  <md-input-container flex="35">
                    <input zip-to-city="deliveryAddress.zipCode" receive="false" type="text" name="deliveryAddress.zipCode" ng-model="contract.company.deliveryAddress.zipCode" ng-minlength="1" ng-maxlength="10">
                    <label>{{::'order.companyData.zipCode' | translate}}</label>
                    <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="deliveryAddress.zipCode"></wb-messages>
                  </md-input-container>

                  <md-input-container flex="65">
                    <input zip-to-city="deliveryAddress.zipCode" receive="true" type="text" name="deliveryAddress.city" ng-model="contract.company.deliveryAddress.city" ng-minlength="1" ng-maxlength="100">
                    <label>{{::'order.companyData.city' | translate}}</label>
                    <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="deliveryAddress.city"></wb-messages>
                  </md-input-container>
                </div>

                <div layout="row">
                  <md-input-container flex="35">
                    <md-select placeholder="{{::'account.company.address.countryCode' | translate}}"
                               ng-model="contract.company.deliveryAddress.countryCode"
                               name="deliveryAddress.countryCode"
                               class="full-width-select">
                      <md-option ng-repeat="country in contract.countries"
                                 value="{{country.alpha3}}">
                        {{country.name}}
                      </md-option>
                    </md-select>
                    <label>{{::'account.company.address.countryCode' | translate}}</label>
                    <wb-messages form="firstBox"
                                 server="contract.getServerValidation()"
                                 field-name="deliveryAddress.countryCode"></wb-messages>
                  </md-input-container>

                  <md-input-container flex="65">
                    <input type="text" name="deliveryAddress.address" ng-model="contract.company.deliveryAddress.address" ng-minlength="5" ng-maxlength="100">
                    <label>{{::'order.companyData.address' | translate}}</label>
                    <wb-messages form="firstBox" server="contract.getServerValidation()" field-name="deliveryAddress.address"></wb-messages>
                  </md-input-container>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </ng-form>

    <ng-form name="secondBox">
      <md-card>
        <md-toolbar ng-class="{'md-accent': secondBox.$valid, 'md-warn': !secondBox.$valid}">
          <div class="md-toolbar-tools">
            <span>{{::'order.ownerStatement.title' | translate}}</span>
            <span flex></span>
            <md-button class="wb-status-icon-wrapper not-a-button" aria-label="{{::'order.validation' | translate}}">
              <md-icon md-font-icon="zmdi zmdi-alert-circle zmdi-hc-lg zmdi-hc-fw" ng-class="{'zmdi-mood': secondBox.$valid, 'zmdi-alert-circle': !secondBox.$valid}"></md-icon>
            </md-button>
          </div>
        </md-toolbar>

        <md-card-content layout-gt-sm="row" layout-margin>
          <div flex-gt-sm="auto">
            <div class="wb-form-section-header">
              <h3 class="wb-form-section-title">{{::'order.ownerStatement.ownerDetails.title' | translate}}</h3>
            </div>

            <div layout="row">
              <md-input-container flex>
                <md-select placeholder="{{::'order.ownerStatement.ownerDetails' | translate}}"
                           ng-model="contract.selectedOwnerIndex"
                           md-on-close="contract.updateSelectedOwner()"
                           class="full-width-select">
                  <md-option ng-repeat="owner in contract.company.owners" value="{{$index}}">
                    {{owner.name}}
                  </md-option>
                </md-select>
                <label>{{::'order.ownerStatement.ownerDetails' | translate}}</label>
              </md-input-container>
            </div>

            <div layout="row">
              <md-input-container flex="40">
                <md-select placeholder="{{::'order.ownerStatement.ownerDetails.titleId' | translate}}"
                           ng-model="contract.selectedOwner.titleId"
                           required
                           name="selectedOwner.titleId"
                           class="full-width-select">
                  <md-option value="1">
                    {{::'account.titleId.1' | translate}}
                  </md-option>
                  <md-option value="2">
                    {{::'account.titleId.2' | translate}}
                  </md-option>
                </md-select>
                <label>{{::'order.ownerStatement.ownerDetails.titleId' | translate}}</label>
                <wb-messages form="secondBox"
                             server="contract.getServerValidation()"
                             field-name="selectedOwner.titleId"></wb-messages>
              </md-input-container>

              <md-input-container flex="60">
                <input type="text" required name="selectedOwner.name" ng-model="contract.selectedOwner.name" ng-minlength="2" ng-maxlength="100">
                <label>{{::'order.ownerStatement.ownerDetails.name' | translate}}</label>
                <wb-messages form="secondBox"
                             server="contract.getServerValidation()"
                             field-name="selectedOwner.name"></wb-messages>
              </md-input-container>
            </div>

            <md-input-container flex>
              <input type="text" required name="owners.post" ng-model="contract.selectedOwner.post" ng-minlength="5" ng-maxlength="100">
              <label>{{::'order.ownerStatement.ownerDetails.occupation' | translate}}</label>
              <wb-messages form="secondBox" server="contract.getServerValidation()" field-name="owners.post"></wb-messages>
            </md-input-container>
          </div>

          <div flex-gt-sm="auto">
            <div class="wb-form-section-header">
              <h3 class="wb-form-section-title">&nbsp;</h3>
            </div>

            <md-input-container flex>
              <input type="text" name="owners.phone" ng-model="contract.selectedOwner.phone" ng-minlength="5" ng-maxlength="20" ui-mask="+36-99-999-9998" mask-fix model-view-value="true">
              <label>{{::'order.ownerStatement.ownerAddress.phoneNumber' | translate}}</label>
              <wb-messages form="secondBox" server="contract.getServerValidation()" field-name="owners.phone"></wb-messages>
            </md-input-container>

            <md-input-container flex>
              <input type="email" name="owners.email" ng-model="contract.selectedOwner.email" ng-minlength="4" ng-maxlength="500">
              <label>{{::'order.ownerStatement.ownerAddress.email' | translate}}</label>
              <wb-messages form="secondBox" server="contract.getServerValidation()" field-name="owners.email"></wb-messages>
            </md-input-container>
          </div>

        </md-card-content>
      </md-card>
    </ng-form>

    <ng-form name="thirdBox">
      <md-card>
        <md-toolbar ng-class="{'md-accent': thirdBox.$valid, 'md-warn': !thirdBox.$valid}">
          <div class="md-toolbar-tools">
            {{::'order.administration.title' | translate}}
            <span flex></span>
            <md-button class="wb-status-icon-wrapper not-a-button" aria-label="{{::'order.validation' | translate}}">
              <md-icon md-font-icon="zmdi zmdi-alert-circle zmdi-hc-lg zmdi-hc-fw" ng-class="{'zmdi-mood': thirdBox.$valid, 'zmdi-alert-circle': !thirdBox.$valid}"></md-icon>
            </md-button>
          </div>
        </md-toolbar>

        <md-card-content layout-gt-sm="column" layout-margin>
          <div layout-gt-sm="row" layout-margin>
            <div flex-gt-sm="auto">
              <div class="wb-form-section-header">
                <h3 class="wb-form-section-title">{{::'order.administration.proforma.title' | translate}}</h3>
              </div>

              <div layout="row">
                <md-input-container flex="30">
                  <md-select placeholder="{{::'order.administration.credentials.titleId' | translate}}"
                             ng-model="contract.user.titleId"
                             required
                             name="user.titleId"
                             class="full-width-select">
                    <md-option value="1">
                      {{::'account.titleId.1' | translate}}
                    </md-option>
                    <md-option value="2">
                      {{::'account.titleId.2' | translate}}
                    </md-option>
                  </md-select>
                  <label>{{::'order.administration.credentials.titleId' | translate}}</label>
                  <wb-messages form="thirdBox"
                               server="contract.getServerValidation()"
                               field-name="user.titleId"></wb-messages>
                </md-input-container>

                <md-input-container flex="35">
                  <input required type="text" name="user.lastName" ng-model="contract.user.lastName" ng-minlength="2" ng-maxlength="50">
                  <label>{{::'order.administration.credentials.lastName' | translate}}</label>
                  <wb-messages form="thirdBox" server="contract.getServerValidation()" field-name="user.lastName"></wb-messages>
                </md-input-container>
                <md-input-container flex="35">
                  <input required type="text" name="user.firstName" ng-model="contract.user.firstName" ng-minlength="2" ng-maxlength="50">
                  <label>{{::'order.administration.credentials.firstName' | translate}}</label>
                  <wb-messages form="thirdBox" server="contract.getServerValidation()" field-name="user.firstName"></wb-messages>
                </md-input-container>
              </div>

              <md-input-container flex>
                <input required type="email" name="invoiceEmail" ng-model="contract.company.invoiceEmail" ng-minlength="4" ng-maxlength="500">
                <label>{{::'account.contact.email' | translate}}</label>
                <wb-messages form="thirdBox" server="contract.getServerValidation()" field-name="invoiceEmail"></wb-messages>
              </md-input-container>

              <div layout="row">
                <md-input-container flex>
                  <input type="text" name="phone" ng-model="contract.user.phone" ng-minlength="5" ng-maxlength="20" ui-mask="+36-99-999-9998" mask-fix model-view-value="true">
                  <label>{{::'order.administration.proforma.phoneNumber' | translate}}</label>
                  <wb-messages form="thirdBox" server="contract.getServerValidation()" field-name="phone"></wb-messages>
                </md-input-container>

                <md-input-container flex>
                  <input type="text" name="fax" ng-model="contract.user.fax" ng-minlength="5" ng-maxlength="20" ui-mask="+36-99-999-999" mask-fix model-view-value="true">
                  <label>{{::'order.administration.proforma.faxNumber' | translate}}</label>
                  <wb-messages form="thirdBox" server="contract.getServerValidation()" field-name="fax"></wb-messages>
                </md-input-container>
              </div>
            </div>

            <div flex-gt-sm="auto">
              <div class="wb-form-section-header">
                <h3 class="wb-form-section-title">{{::'order.administration.credentials.title' | translate}}</h3>
              </div>

              <md-input-container flex>
                <input type="email" required name="email" ng-model="contract.user.email" ng-minlength="4" ng-maxlength="100">
                <label>{{::'order.administration.proforma.email' | translate}}</label>
                <wb-messages form="thirdBox" server="contract.getServerValidation()" field-name="email"></wb-messages>
              </md-input-container>

              <p class="wb-password-legend">{{::'order.administration.credentials.password.legend' | translate}}</p>

              <md-input-container flex>
                <md-icon class="wb-tooltip zmdi zmdi-help zmdi-hc-lg"></md-icon>

                <md-tooltip md-direction="bottom">
                  <span ng-bind-html="::'order.administration.credentials.password.tooltip' | translate"></span>
                </md-tooltip>

                <input type="password" ng-pattern="/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}/" required name="password" ng-model="contract.user.password">
                <label>{{::'order.administration.credentials.password' | translate}}</label>
                <wb-messages form="thirdBox" server="contract.getServerValidation()" field-name="password"></wb-messages>
              </md-input-container>
            </div>
          </div>

      <div layout="row" layout-align="space-between center" layout-margin>
        <md-button
          type="button"
          class="md-raised md-background"
          ng-click="order.prevStep('typeSelector', 'contract')"
          aria-label="{{::'order.back' | translate}}">
          {{::'order.back' | translate}}
        </md-button>
        <md-button analytics-on="click" analytics-event="tovabb" analytics-category="contract" analytics-if="!order.isAuthenticated" ng-disabled="!contract.contractForm.$valid" ng-hide="contract.isProcessing()" class="md-raised md-accent" type="submit">
          {{::'order.more' | translate}}
        </md-button>
        <div class="button-loader-wrapper" ng-show="contract.isProcessing()">
          <md-progress-circular class="md-hue-2 button-loader" md-diameter="36px"
                                md-mode="indeterminate"></md-progress-circular>
        </div>
      </div>

        </md-card-content>
      </md-card>
    </ng-form>
  </div>
</form>
