<footer class="wb-footer" layout="column" layout-align="end center" ng-hide="app.hasNavigation" ng-cloak>
  <nav>
    <md-button ng-href="{{::'auth.privacyPolicy.url' | translate}}" target="_blank">{{::'auth.privacyPolicy.title' | translate}}</md-button>
    <md-button ng-href="{{::'auth.legalNotice.url' | translate}}" target="_blank">{{::'auth.legalNotice.title' | translate}}</md-button>
    <md-button ng-href="{{::'auth.imprint.url' | translate}}" target="_blank">{{::'auth.imprint.title' | translate}}</md-button>
  </nav>

  <small class="wb-copyright-notice">
    &copy; Copyright 2014 Rewin {{::'auth.copyrightNotice' | translate}}
  </small>
</footer>
