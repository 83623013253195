<md-card class="wb-modify-user-data">
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'account.change' | translate}}
    </div>
  </md-toolbar>
  <md-card-content>

    <form name="accountForm" ng-submit="accountForm.$valid && !Account.isProcessing() && Account.submit()" novalidate>

      <div layout-sm="column" layout-gt-sm="row" layout-align="space-around start">

        <div flex-gt-sm layout-fill-sm>

          <div class="group-label">
            <md-icon class="zmdi zmdi-account zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.personalData' | translate}}
          </div>

          <div class="inset">
            <div layout="row">
              <md-input-container flex="30">
                <label>{{::'account.titleId' | translate}}</label>
                <md-select placeholder="{{::'account.titleId' | translate}}"
                           ng-model="Account.user.titleId"
                           required
                           name="titleId">
                  <md-option value="1">
                    {{::'account.titleId.1' | translate}}
                  </md-option>
                  <md-option value="2">
                    {{::'account.titleId.2' | translate}}
                  </md-option>
                </md-select>
                <wb-messages form="accountForm"
                             server="Account.getServerValidation()"
                             field-name="titleId"></wb-messages>
              </md-input-container>

              <md-input-container flex="35">
                <label>{{::'account.lastName' | translate}}</label>
                <input required ng-model="Account.user.lastName" name="lastName" type="text">
                <wb-messages form="accountForm" server="Account.getServerValidation()" field-name="lastName"></wb-messages>
              </md-input-container>

              <md-input-container flex="35">
                <label>{{::'account.firstName' | translate}}</label>
                <input required ng-model="Account.user.firstName" name="firstName" type="text">
                <wb-messages form="accountForm" server="Account.getServerValidation()" field-name="firstName"></wb-messages>
              </md-input-container>
            </div>
          </div>
        </div>

        <div flex-gt-sm layout-fill-sm>

          <div class="group-label">
            <md-icon class="zmdi zmdi-comment-text zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.contact' | translate}}
          </div>

          <div class="inset">
            <md-input-container flex>
              <label>{{::'account.contact.email' | translate}}</label>
              <input required ng-model="Account.user.email" name="email" type="email">
              <wb-messages form="accountForm" server="Account.getServerValidation()" field-name="email"></wb-messages>
            </md-input-container>

            <div layout="row">
              <md-input-container flex>
                <label>{{::'account.contact.phone' | translate}}</label>
                <input ng-model="Account.user.phone" name="phone" type="text" ui-mask="+36-99-999-9998" mask-fix model-view-value="true">
                <wb-messages form="accountForm" server="Account.getServerValidation()" field-name="phone"></wb-messages>
              </md-input-container>

              <md-input-container flex>
                <label>{{::'account.contact.fax' | translate}}</label>
                <input ng-model="Account.user.fax" name="fax" type="text" ui-mask="+36-99-999-999" mask-fix model-view-value="true">
                <wb-messages form="accountForm" server="Account.getServerValidation()" field-name="fax"></wb-messages>
              </md-input-container>
            </div>
          </div>
        </div>

      </div>

      <div layout-gt-sm="row" layout-align-gt-sm="end start">
        <div>
          <md-button ng-hide="Account.isProcessing()"
                     class="md-raised md-primary full-width-button-sm" type="submit">
            {{::'account.save' | translate}}
          </md-button>
          <div class="button-loader-wrapper" ng-show="Account.isProcessing()">
            <md-progress-circular class="md-hue-2 button-loader" md-diameter="36px"
                                  md-mode="indeterminate"></md-progress-circular>
          </div>
        </div>
      </div>

    </form>

  </md-card-content>

</md-card>
