<div class="wb-table">
  <div class="wb-table-header" layout="row" layout-align="start center" flex>
    <span flex="10">{{::'order.itemList.lastName' | translate}}</span>
    <span flex="15" class="text-center">{{::'order.itemList.firstName' | translate}}</span>
    <span flex="20" class="text-center"
      ng-if="itemList.selectedProductType !== 'multiWallet'">{{::'order.itemList.csn' | translate}}</span>
    <span flex="30" class="text-center"
      ng-if="itemList.selectedProductType === 'multiWallet'">{{::'order.itemList.multiWallet.csn' | translate}}</span>
    <span flex="15"
      ng-if="itemList.selectedProductType === 'multiWallet'">{{::'order.itemList.wallets' | translate}}</span>
    <span flex="15" class="text-right">{{::'order.amount' | translate}}</span>
    <span flex></span>
  </div>

  <div class="wb-table-item" layout="row" layout-align="start center" ng-repeat="orderItem in itemList.order.items"
    ng-hide="orderItem.fileName.length">
    <span flex="10">{{::orderItem.lastName}}</span>
    <span flex="15" class="text-center">{{::orderItem.firstName}}</span>
    <span flex="30" class="text-center">{{::orderItem.csn}}</span>
    <span flex="15">
      <ul class="wb-table-item-wallets">
        <li ng-repeat="wallet in itemList.wallets" ng-if="orderItem[wallet.field] > 0">
          <img ng-src="{{ itemList.findWalletById(wallet.id).icon }}"
            alt="{{ itemList.findWalletById(wallet.id).name }}" />
          <md-tooltip>
            {{ itemList.findWalletById(wallet.id).name | translate }}:
            {{ orderItem[wallet.field] | isoCurrency: 'HUF':0 }}
          </md-tooltip>
        </li>
      </ul>
    </span>
    <span flex="15" class="text-right">{{::orderItem.loadAmount | isoCurrency:'HUF':0}}</span>
    <span flex="15" class="text-right">
      <md-button class="md-icon-button md-button-sm" ng-click="itemList.editItem($index)">
        <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-edit"></md-icon>
      </md-button>

      <md-button class="md-icon-button md-button-sm" ng-click="itemList.deleteItem($index)">
        <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-delete"></md-icon>
      </md-button>
    </span>
  </div>
</div>
