<section class="wb-login" layout-gt-lg="column" layout-align-gt-lg="center center" layout-fill-gt-lg>

  <div class="wb-container wide">
    <form novalidate
          method="post"
          name="passwordResetForm"
          ng-submit="passwordResetForm.$valid && PasswordReset.submit()"
          layout-md="column"
          layout-gt-md="row"
          layout-align="center center">

      <div class="wb-auth-box md-whiteframe-z1 wb-login-box" flex="50" layout-margin>
        <div class="wb-auth-box-heading wb-auth-box-heading-login">
          <h1 class="wb-auth-box-title">{{'auth.passwordReset' | translate}}</h1>
        </div>

        <div class="wb-auth-box-inner">
          <p class="wb-auth-legend">{{::'auth.passwordReset.confirm.legend' | translate}}</p>

          <div layout="row" layout-sm="row">
            <div flex flex-sm="100" class="button-wrapper text-center">
              <md-button class="md-raised md-background md-button-lg no-side-margin" flex ui-sref="login">
                <md-icon class="zmdi zmdi-arrow-left zmdi-hc-lg"></md-icon>
                {{::'auth.passwordReset.back' | translate}}
              </md-button>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</section>
