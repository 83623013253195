<md-list>
  <md-list-item class="faq-list-item md-3-line" ng-repeat="faq in faqList.faqs" layout="row">
    <md-button class="md-fab not-a-button md-primary" aria-label="Question">
      <md-icon class="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-comment-more"></md-icon>
    </md-button>
    <div class="md-list-item-text" flex>
      <h3>{{::faq.question}}</h3>

      <p ng-bind-html="faq.answer"></p>
    </div>
    <md-divider ng-if="!$last"></md-divider>
  </md-list-item>
</md-list>
