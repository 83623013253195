<md-dialog class="wb-exit-intent-dialog">
  <div class="wb-exit-intent-dialog-header">
    <h2 class="wb-exit-intent-dialog-header-title">{{::'order.exitIntentDialog.title' | translate}}</h2>
  </div>

  <div class="wb-exit-intent-dialog-content">
    <form class="wb-exit-intent-dialog-form"
          name="exitIntentDialog.exitDataForm"
          ng-submit="exitIntentDialog.exitDataForm.$valid && !exitIntentDialog.isProcessing() && exitIntentDialog.saveExitData()"
          novalidate>
      <md-input-container flex>
        <label>{{::'order.exitIntentDialog.form.name' | translate}}</label>
        <input type="text" name="name" ng-model="exitIntentDialog.exitData.name" required ng-minlength="2" ng-maxlength="100">
        <wb-messages form="exitIntentDialog.exitDataForm" server="exitIntentDialog.getServerValidation()" field-name="name"></wb-messages>
      </md-input-container>

      <md-input-container flex>
        <label>{{::'order.exitIntentDialog.form.companyName' | translate}}</label>
        <input type="text" name="companyName" ng-model="exitIntentDialog.exitData.companyName" required ng-minlength="2" ng-maxlength="100">
        <wb-messages form="exitIntentDialog.exitDataForm" server="exitIntentDialog.getServerValidation()" field-name="companyName"></wb-messages>
      </md-input-container>

      <md-input-container flex>
        <label>{{::'order.exitIntentDialog.form.phone' | translate}}</label>
        <input type="tel" name="phone" ng-model="exitIntentDialog.exitData.phone" required ng-minlength="5" ng-maxlength="20">
        <wb-messages form="exitIntentDialog.exitDataForm" server="exitIntentDialog.getServerValidation()" field-name="phone"></wb-messages>
      </md-input-container>

      <md-input-container flex>
        <label>{{::'order.exitIntentDialog.form.email' | translate}}</label>
        <input type="email" name="email" ng-model="exitIntentDialog.exitData.email" ng-minlength="4" ng-maxlength="50">
        <wb-messages form="exitIntentDialog.exitDataForm" server="exitIntentDialog.getServerValidation()" field-name="email"></wb-messages>
      </md-input-container>
    </form>

    <p class="wb-exit-intent-dialog-legend">{{::'order.exitIntentDialog.legend' | translate}}</p>

    <div class="wb-exit-intent-dialog-contacts">
      <a href="tel:{{::'order.exitIntentDialog.contacts.phone' | translate}}" class="wb-exit-intent-dialog-contact">{{::'order.exitIntentDialog.contacts.phone' | translate}}</a><br>
      <a href="mailto:{{::'order.exitIntentDialog.contacts.email' | translate}}" class="wb-exit-intent-dialog-contact">{{::'order.exitIntentDialog.contacts.email' | translate}}</a>
    </div>

    <div layout="row" layout-align="space-between center">
      <md-button ng-click="exitIntentDialog.closeDialog()"
                 class="md-raised md-default"
                 type="button">
        {{::'order.exitIntentDialog.actions.close' | translate}}
      </md-button>

      <md-button ng-click="exitIntentDialog.saveExitData()"
                 class="md-raised md-accent"
                 ng-disabled="exitIntentDialog.exitDataForm.$pristine || !exitIntentDialog.exitDataForm.$valid || exitIntentDialog.isProcessing()"
                 type="button">
        {{::'order.exitIntentDialog.actions.submit' | translate}}
      </md-button>
    </div>
  </div>
</md-dialog>
