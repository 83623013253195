<div layout="row" layout-margin>

  <div flex>
    <h3 class="wb-item-dialog-form-section-title">{{::'order.itemDialog.cardOwner.title' | translate}}</h3>

    <md-input-container flex>
      <label>{{::'order.itemList.lastName' | translate}}</label>
      <input type="text" required name="lastName" ng-model="itemDialog.item.lastName" ng-minlength="2"
             ng-maxlength="50">
      <wb-messages form="itemDialog.itemDialogForm" server="itemDialog.getServerValidation()"
                   field-name="lastName"></wb-messages>
    </md-input-container>

    <md-input-container flex>
      <label>{{::'order.itemList.firstName' | translate}}</label>
      <input type="text" required name="firstName" ng-model="itemDialog.item.firstName" ng-minlength="2"
             ng-maxlength="50">
      <wb-messages form="itemDialog.itemDialogForm" server="itemDialog.getServerValidation()"
                   field-name="firstName"></wb-messages>
    </md-input-container>
  </div>

  <div flex>
    <h3 class="wb-item-dialog-form-section-title">{{::'order.itemDialog.cardData.title' | translate}}</h3>

    <md-input-container flex>
      <label>{{::'order.itemList.csn' | translate}}</label>
      <input type="text" required name="csn" ng-model="itemDialog.item.csn" ng-minlength="10" ng-maxlength="10">
      <wb-messages form="itemDialog.itemDialogForm" server="itemDialog.getServerValidation()"
                   field-name="csn"></wb-messages>
    </md-input-container>

    <md-input-container flex>
      <label>{{::'order.itemList.sum' | translate}}</label>
      <input type="text" required name="loadAmount" ng-model="itemDialog.item.loadAmount" ng-minlength="1"
             ng-maxlength="12">
      <wb-messages form="itemDialog.itemDialogForm" server="itemDialog.getServerValidation()"
                   field-name="loadAmount"></wb-messages>
    </md-input-container>
  </div>

</div>
