<md-toolbar
  class="md-tall wb-order-toolbar md-whiteframe-z1"
  layout="row"
  layout-align="center center"
  ng-class="{'logged-in': wbOrderToolbar.isAuthenticated}">
  <div class="wb-container wb-order-toolbar-steps" layout="row" layout-align="space-between center" flex>
    <div class="wb-order-toolbar-need-help text-center" flex="20">
      <a class="text-center" ng-click="wbOrderToolbar.openFaqDialog()">
        <span class="zmdi zmdi-comments wb-order-toolbar-step-icon "></span>
        <span class="wb-order-toolbar-step-legend">{{::'order.stuckWhileOrdering' | translate}}</span>
      </a>
    </div>
    <div class="wb-order-toolbar-step text-center" ng-class="{done: (wbOrderToolbar.getActiveStateIndex() >= $index)}" ng-repeat="step in wbOrderToolbar.steps" flex="grow">
      <md-icon class="zmdi zmdi-{{(wbOrderToolbar.getActiveStateIndex() >= $index) ? 'check' : step.icon}} wb-order-toolbar-step-icon "></md-icon>
      <span class="wb-order-toolbar-step-legend" translate>{{step.name}}</span>
    </div>
  </div>
</md-toolbar>
