<section class="wb-login wb-company-selector" layout-gt-lg="column" layout-align-gt-lg="center center" layout-fill-gt-lg>
  <div class="wb-container wide">
    <form novalidate
          method="post"
          name="companySelectorForm"
          ng-submit="companySelector.submit()"
          layout="row"
          layout-align="center center">

      <div class="wb-auth-box wb-auth-box-company-selector md-whiteframe-z1 wb-login-box" flex="50">
        <div class="wb-auth-box-heading wb-auth-box-heading-login">
          <h1 class="wb-auth-box-title">{{::'auth.companySelector' | translate}}</h1>
        </div>

        <div class="wb-auth-box-inner">
          <p class="wb-auth-legend text-center">{{::'auth.companySelector.legend' | translate}}</p>

          <div layout="row" layout-align="center center">
            <md-input-container flex="60">
              <label>{{::'auth.companySelector.selectedCompany' | translate}}</label>
              <md-select placeholder="{{::'auth.companySelector.text' | translate}}"
                         ng-model="companySelector.selectedCompany"
                         required
                         name="selectedCompany">
                <md-option ng-repeat="company in companySelector.companies" value="{{company.id}}">
                  {{company.name}}
                </md-option>
              </md-select>
            </md-input-container>
          </div>

          <div class="wb-auth-box-btn-container" layout="column" layout-align="space-between center" layout-gt-xs="row" layout-align-gt-xs="center center" flex>
            <md-button class="md-button-lg md-flat wb-auth-box-btn no-side-margin"
                       ng-disabled="companySelector.isProcessing()"
                       ng-class="{'md-raised md-primary': !companySelector.isProcessing()}"
                       type="submit"
                       layout="row"
                       layout-align="center center"
                       flex-xs>
              <span ng-if="!companySelector.isProcessing()">{{::'auth.companySelector.send' | translate}}</span>
              <md-progress-circular class="md-primary" md-mode="indeterminate" md-diameter="36px" ng-if="companySelector.isProcessing()"></md-progress-circular>
            </md-button>
          </div>
        </div>

    </form>
  </div>
</section>
