<div layout="row" layout-margin>
  <div flex layout-margin>
    <h3 class="wb-item-dialog-subtitle">
      {{::'order.itemDialog.cardOwner.title' | translate}}
    </h3>

    <md-input-container flex>
      <label>{{::'order.itemDialog.lastName' | translate}}</label>
      <input
        type="text"
        required
        name="lastName"
        ng-model="itemDialog.item.lastName"
        ng-minlength="2"
        ng-maxlength="50"
      />
      <wb-messages
        form="itemDialog.itemDialogForm"
        server="itemDialog.getServerValidation()"
        field-name="lastName"
      ></wb-messages>
    </md-input-container>

    <md-input-container flex>
      <label>{{::'order.itemDialog.firstName' | translate}}</label>
      <input
        type="text"
        required
        name="firstName"
        ng-model="itemDialog.item.firstName"
        ng-minlength="2"
        ng-maxlength="50"
      />
      <wb-messages
        form="itemDialog.itemDialogForm"
        server="itemDialog.getServerValidation()"
        field-name="firstName"
      ></wb-messages>
    </md-input-container>

    <md-input-container flex>
      <label>{{::'order.itemDialog.csn' | translate}}</label>
      <input type="text" required name="csn" ng-model="itemDialog.item.csn" />
      <wb-messages
        form="itemDialog.itemDialogForm"
        server="itemDialog.getServerValidation()"
        field-name="csn"
      ></wb-messages>
    </md-input-container>
  </div>

  <div flex layout-margin>
    <h3 class="wb-item-dialog-subtitle">
      {{::'order.itemDialog.amountsByWallet' | translate}}
    </h3>

    <div class="wb-order-itemdialog-wallets-list">
      <div
        class="wb-order-itemdialog-wallets-list-item"
        ng-repeat="wallet in itemDialog.wallets"
        ng-class="{'wb-order-itemdialog-wallets-list-item-expanded': itemDialog.expandedWalletId === wallet.id}"
      >
        <div
          class="wb-order-itemdialog-wallets-list-item-header"
          layout="row"
          layout-align="start center"
          ng-click="itemDialog.expandedWalletId === wallet.id ? itemDialog.expandedWalletId = null : itemDialog.expandedWalletId = wallet.id"
        >
          <div class="wb-order-itemdialog-wallets-list-item-header-image">
            <img
              ng-src="{{ wallet.icon }}"
              alt="{{ wallet.name | translate }}"
            />
          </div>

          <div class="wb-order-itemdialog-wallets-list-item-header-name">
            <span>{{ wallet.name | translate }}</span>
          </div>

          <div flex></div>

          <div class="wb-order-itemdialog-wallets-list-item-header-amount">
            <span>{{
              itemDialog.item[wallet.field] || 0 | isoCurrency: 'HUF':0
            }}</span>
          </div>

          <div class="wb-order-itemdialog-wallets-list-item-header-expander">
            <md-icon
              md-font-icon="zmdi zmdi-chevron-down zmdi-hc-lg"
              ng-hide="itemDialog.expandedWalletId === wallet.id"
            ></md-icon>
            <md-icon
              md-font-icon="zmdi zmdi-chevron-up zmdi-hc-lg"
              ng-show="itemDialog.expandedWalletId === wallet.id"
            ></md-icon>
          </div>
        </div>

        <div
          class="wb-order-itemdialog-wallets-list-item-content"
          ng-show="itemDialog.expandedWalletId === wallet.id"
        >
          <md-input-container>
            <label>{{::'order.itemDialog.amountToTopup' | translate}}</label>
            <input
              ng-model="itemDialog.item[wallet.field]"
              name="{{ wallet.id }}.amount"
              type="number"
              ng-min="0"
            />
          </md-input-container>
          <wb-messages
            form="itemDialog.itemDialogForm"
            server="itemDialog.getServerValidation()"
            field-name="{{ wallet.id }}.amount"
          ></wb-messages>
        </div>
      </div>
    </div>
  </div>
</div>
