<md-card>
  <md-toolbar class="wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.multiWallet.bulk.files.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <div class="wb-alert wb-alert--warning wb-alert--bold">
      <md-icon md-font-icon="zmdi zmdi-alert-triangle zmdi-hc-3x"></md-icon>
      <div ng-bind-html="::'order.multiWallet.info' | translate"></div>
    </div>

    <div
      class="wb-file-upload wb-file-upload--regular"
      layout-gt-sm="row"
      layout-align="space-around center"
      layout-margin
    >
      <div
        class="wb-file-upload-box wb-file-upload-sample text-center"
        flex="auto"
        layout="column"
        layout-align="center center"
      >
        <md-button
          class="md-fab not-a-button md-primary md-wbTheme-theme"
          aria-label="{{::'order.bulk.files.sampleFile' | translate}}"
        >
          <md-icon
            md-font-icon="zmdi zmdi-download zmdi-hc-lg zmdi-hc-fw"
          ></md-icon>
        </md-button>

        <p>{{::'order.bulk.files.sampleFile.legend' | translate}}</p>

        <a
          class="md-button md-raised"
          ng-click="multiWalletCardFileUpload.openSampleAlert()"
          ng-href="{{::multiWalletCardFileUpload.sampleFile}}"
          target="_self"
          download
          >{{::'order.bulk.files.sampleFile' | translate}}</a
        >
      </div>
      <div
        class="wb-file-upload-box wb-file-upload-input text-center"
        layout="column"
        layout-align="center center"
        flex="auto"
        flow-init
        flow-file-added="!!{xls:1,xlsx:1}[$file.getExtension()]"
        flow-files-submitted="multiWalletCardFileUpload.sendToAPI($file, $event, $flow)"
        flow-file-error="multiWalletCardFileUpload.handleAPIErrors($file, $message, $flow)"
        flow-file-success="multiWalletCardFileUpload.handleAPISuccess($file, $message, $flow)"
        flow-drop
        flow-drag-enter="class='is-dragging'"
        flow-drag-leave="class=''"
        ng-class="[{'has-success': multiWalletCardFileUpload.isValidFile()}, {'has-error': !multiWalletCardFileUpload.isValidFile() && multiWalletCardFileUpload.hasFile()}, class]"
      >
        <md-button
          class="md-fab not-a-button md-grey md-wbTheme-theme"
          ng-if="!multiWalletCardFileUpload.hasFile()"
          aria-label="{{::'order.bulk.files.uploadFile' | translate}}"
        >
          <md-icon
            md-font-icon="zmdi zmdi-upload zmdi-hc-lg zmdi-hc-fw"
          ></md-icon>
        </md-button>

        <div
          class="wb-file-upload-list"
          ng-if="multiWalletCardFileUpload.hasFile()"
        >
          <span>{{ multiWalletCardFileUpload.getAvailableFileName() }}</span>
        </div>

        <p>{{::'order.bulk.files.uploadFile.legend' | translate}}</p>

        <div ng-if="multiWalletCardFileUpload.fileRelatedError()">
          <p class="error-response">
            {{
              'form.validation.' + multiWalletCardFileUpload.fileRelatedError()
                | translate
            }}
          </p>
        </div>

        <md-button
          class="md-raised md-accent"
          ng-if="!multiWalletCardFileUpload.hasFile()"
          flow-btn
          >{{::'order.bulk.files.uploadFile' | translate}}</md-button
        >
        <div layout="row" ng-if="multiWalletCardFileUpload.hasFile()">
          <md-button class="md-raised md-accent narrow" flow-btn>
            <md-tooltip
              >{{::'order.bulk.files.changeFile' | translate}}</md-tooltip
            >
            <md-icon md-font-icon="zmdi zmdi-upload"></md-icon>
          </md-button>

          <md-button
            class="md-raised md-warn narrow"
            ng-if="multiWalletCardFileUpload.isValidFile()"
            ng-click="multiWalletCardFileUpload.deleteFile()"
          >
            <md-tooltip>{{::'order.bulk.files.delete' | translate}}</md-tooltip>
            <md-icon md-font-icon="zmdi zmdi-delete"></md-icon>
          </md-button>
        </div>
      </div>
    </div>

    <div layout="row" layout-align="space-between center" layout-margin>
      <md-button
        type="button"
        class="md-raised md-background"
        ng-click="order.prevStep('typeSelector', 'multiWalletCardFileUpload')"
        aria-label="{{::'order.back' | translate}}"
      >
        {{::'order.back' | translate}}
      </md-button>
      <md-button
        analytics-on="click"
        analytics-event="tovabb"
        analytics-category="itemlist"
        analytics-if="!order.isAuthenticated"
        ng-disabled="!multiWalletCardFileUpload.isValidFile()"
        class="md-raised md-accent"
        ng-click="order.nextStep('contract', 'multiWalletCardFileUpload')"
        ng-hide="multiWalletCardFileUpload.isProcessing()"
      >
        {{::'order.more' | translate}}
      </md-button>
      <div
        class="button-loader-wrapper"
        ng-show="multiWalletCardFileUpload.isProcessing()"
      >
        <md-progress-circular
          class="md-hue-2 button-loader"
          md-diameter="36px"
          md-mode="indeterminate"
        ></md-progress-circular>
      </div>
    </div>
  </md-card-content>
</md-card>
