<md-card class="wb-csn-file-download-info">
  <md-card-content>
    <p ng-bind-html="::'csnFile.info' | translate"></p>
  </md-card-content>
</md-card>

<md-card class="wb-order-history">
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'csnFile.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <div class="wb-table">
      <div class="wb-table-header" layout="row">
        <span flex="15">{{::'csnFile.date' | translate}}</span>
        <span flex="75">{{::'csnFile.file' | translate}}</span>
        <span
          flex="10"
          class="text-center"
          >{{::'csnFile.download' | translate}}</span
        >
      </div>

      <div
        class="wb-table-item"
        layout="row"
        layout-align="center center"
        ng-repeat="csnFile in csnFileDownload.csnFiles track by csnFile.id"
      >
        <span flex="15">{{::csnFile.created | date:'yyyy.MM.dd'}}</span>
        <span flex="75">{{::csnFile.name}}</span>
        <span flex="10" class="text-right">
          <md-button
            class="md-icon-button"
            aria-label="{{::'csnFile.download' | translate}}"
            ng-click="csnFileDownload.download(csnFile)"
          >
            <md-icon
              ng-if="!csnFile.isProcessing"
              ng-class="{'md-accent': csnFile.downloadCount == 0}"
              md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-download"
            ></md-icon>
            <md-progress-circular
              ng-if="csnFile.isProcessing"
              class="md-accent button-loader"
              md-mode="indeterminate"
              md-diameter="25"
            ></md-progress-circular>
          </md-button>
        </span>
      </div>
    </div>
  </md-card-content>

  <md-card-footer
    class="wb-csn-file-download-footer"
    layout="row"
    layout-align="space-between center"
  ></md-card-footer>
</md-card>
