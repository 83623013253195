<md-card class="wb-company-callback">
  <md-toolbar class="wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.callback.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content class="wb-order-content" layout-gt-sm="row">
    <div flex-gt-sm>
      <h2 ng-if="companyCallback.isStandalone">{{::'order.callback.' + companyCallback.companyCallbackType + '.heading' | translate}}</h2>

      <p ng-bind-html="(companyCallback.isStandalone ? 'order.callback.' + companyCallback.companyCallbackType + '.legend' : 'order.callback.legend') | translate"></p>
    </div>

    <div flex-gt-sm offset-gt-sm="5">
      <form name="companyCallback.companyCallbackForm"
            ng-submit="companyCallback.submit()"
            novalidate
            method="post">

        <md-input-container flex
                            ng-if="companyCallback.isStandalone && !(companyCallback.isStandalone && companyCallback.companyCallbackType === 'merchant')">
          <label>{{::'order.callback.customerType' | translate}}</label>
          <md-select placeholder="{{::'order.callback.customerType' | translate}}"
                     ng-model="companyCallback.data.customerType"
                     required
                     name="customerType">
            <md-option value="{{::'order.callback.customerType.1.analytics' | translate}}">
              {{::'order.callback.customerType.1' | translate}}
            </md-option>
            <md-option value="{{::'order.callback.customerType.3.analytics' | translate}}">
              {{::'order.callback.customerType.3' | translate}}
            </md-option>
          </md-select>
          <wb-messages form="companyCallback.companyCallbackForm"
                       server="companyCallback.getServerValidation()"
                       field-name="customerType"></wb-messages>
        </md-input-container>

        <md-input-container flex>
          <input type="text" required name="name" ng-model="companyCallback.data.name" ng-minlength="2" ng-maxlength="100">
          <label>{{::'order.callback.name' | translate}}</label>
          <wb-messages form="companyCallback.companyCallbackForm" server="companyCallback.getServerValidation()" field-name="name"></wb-messages>
        </md-input-container>

        <md-input-container flex>
          <label>{{::'order.callback.phone' | translate}}</label>
          <input required ng-model="companyCallback.data.phone" name="phone" type="text" ui-mask="+36-99-999-9998" mask-fix model-view-value="true">
          <wb-messages form="companyCallback.companyCallbackForm" server="companyCallback.getServerValidation()" field-name="phone"></wb-messages>
        </md-input-container>

        <md-autocomplete
          required
          ng-minlength="2"
          ng-maxlength="100"
          md-input-name="companyName"
          md-no-cache="true"
          md-selected-item="companyCallback.theCompany"
          md-selected-item-change="companyCallback.updateCompany(item)"
          md-search-text="companyName"
          md-items="item in companyCallback.getCompaniesByName(companyName)"
          md-item-text="item.name"
          md-delay="500"
          md-floating-label="{{::'order.callback.companyName' | translate}}"
          ng-if="!companyCallback.customerTypeIsBeneficiary() && !companyCallback.isBackendUnreachable()">

          <md-item-template>
            <span md-highlight-text="companyName">
              {{item.name}} ({{item.vatNumber}})
            </span>
          </md-item-template>

          <md-progress-linear class="yco-autocomplete-progress" ng-if="companyCallback.isLoadingCompanies()" md-mode="indeterminate"></md-progress-linear>
          <wb-messages form="companyCallback.companyCallbackForm" server="companyCallback.getServerValidation()" field-name="companyName"></wb-messages>
        </md-autocomplete>

        <md-input-container flex ng-if="companyCallback.isBackendUnreachable()">
          <input type="text" required name="companyName" ng-model="companyCallback.theCompany.name" ng-minlength="2" ng-maxlength="100">
          <label>{{::'order.callback.companyName' | translate}}</label>
          <wb-messages form="companyCallback.companyCallbackForm" server="companyCallback.getServerValidation()" field-name="companyName"></wb-messages>
        </md-input-container>

        <md-input-container flex
                            ng-if="!companyCallback.customerTypeIsBeneficiary() && !companyCallback.customerTypeIsAffiliatePartner()">
          <label>{{::'account.company.staffCount' | translate}}</label>
          <input required ng-model="companyCallback.data.staffCount" name="staffCount" type="text" ng-minlength="1" ng-maxlength="10">
          <wb-messages form="companyCallback.companyCallbackForm" server="companyCallback.getServerValidation()" field-name="staffCount"></wb-messages>
        </md-input-container>

        <div layout-gt-sm="row" layout-align-gt-sm="end start">
          <md-button class="md-raised" ng-if="app.hasNavigation" ui-sref="orderStart({type: 'new-card', auth: '-with-contract'})">
            {{::'order.callback.cancel' | translate}}
          </md-button>
          <div ng-hide="companyCallback.isProcessing()">
            <md-button ng-disabled="!companyCallback.companyCallbackForm.$valid || companyCallback.companyNotSelected() || companyCallback.isProcessing()" class="md-raised md-accent" type="submit">
              {{::'order.callback.submit' | translate}}
            </md-button>
          </div>
          <div class="button-loader-wrapper" ng-show="companyCallback.isProcessing()">
            <md-progress-circular class="md-accent button-loader"
                                  md-diameter="36px"
                                  md-mode="indeterminate">
            </md-progress-circular>
          </div>
        </div>

      </form>
    </div>

  </md-card-content>
</md-card>
