(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular.module('wbOrder').config(config);

  function config($translateProvider) {
    $translateProvider.translations('en', {
      'order.type.1': 'order card',
      'order.type.2': 'top up card',
      'order.status.S': 'successful',
      'order.status.F': 'unsuccessful',
      'order.legend': '{{type}} {{ status}}',
      'order.validation': 'Validation',

      'order.company.name': 'Company name',
      'order.company.taxNum': 'VAT number',

      'order.stuckWhileOrdering': 'Stuck while ordering?',
      'order.steps.beforeYouBegin': 'Before you begin...',
      'order.steps.orderType': 'Order type',
      'order.steps.cardData': 'Card information',
      'order.steps.billingData': 'Billing information',
      'order.steps.sendOrder': 'Send order',

      'order.steps.analytics.registrationStep': 'Regisztrációs lépés',
      'order.steps.analytics.registration':
        'Új kártya rendelés regisztrációval',
      'order.steps.analytics.registration.1': 'Új kártya rendelés',
      'order.steps.analytics.registration.2': 'Kártya feltöltés',
      'order.steps.analytics.registration.3': 'Biztonságos file küldés',
      'order.steps.analytics.stepStarted': 'Elkezdve',
      'order.steps.analytics.stepComplete': 'Teljesítve',
      'order.steps.analytics.started': 'Indítás',
      'order.steps.analytics.start': 'Megrendelés elkezdve',
      'order.steps.analytics.duplicate': 'Megrendelés elkezdve másolással',
      'order.steps.analytics.ended': 'Megrendelés elküldve',
      'order.steps.analytics.stepNext': 'Tovább lépés',
      'order.steps.analytics.stepBack': 'Visszalépve erre',
      'order.steps.analytics.companySelected': 'Cég kijelölve',
      'order.steps.analytics.authenticated': '1. lépés',
      'order.steps.analytics.vipUpload.authenticated': '2. lépés',
      'order.steps.analytics.typeSelector.authenticated': '2. lépés',
      'order.steps.analytics.itemList.authenticated': '3. lépés',
      'order.steps.analytics.fileUpload.authenticated': '3. lépés',
      'order.steps.analytics.invoice.authenticated': '4. lépés',
      'order.steps.analytics.companyBasic.anonymous': '1. lépés',
      'order.steps.analytics.typeSelector.anonymous': '2. lépés',
      'order.steps.analytics.itemList.anonymous': '3. lépés',
      'order.steps.analytics.fileUpload.anonymous': '3. lépés',
      'order.steps.analytics.contract.anonymous': '4. lépés',
      'order.steps.analytics.invoice.anonymous': '5. lépés',
      'order.steps.analytics.success': 'Megrendelés befejezve',

      'order.steps.first.title': 'First step before you order',
      'order.steps.first.subtitle': 'Cut down on your ordering process!',
      'order.steps.first.legend':
        'Please enter the company name your would like to order to! <strong>With these information ordering process would be much shorter</strong>, because we fill in the data required instead of you.',

      'order.soon': 'Soon!',

      'order.newCardOrder': 'New card',
      'order.topUp': 'Top up card',

      'order.bulk.files.title': 'New card with top up',
      'order.bulk.files.info':
        'Only one card design can be included on the file.',
      'order.bulk.files.sampleFile': 'Excel file sample',
      'order.bulk.files.sampleFile.legend':
        'Please enter the sample and fill in all of the fields. The chart can not be edited with extra columns. After filling in please save the document',
      'order.bulk.files.uploadFile': 'Upload file',
      'order.bulk.files.uploadFile.legend':
        'Please upload the sample you filled out and click ‘Next’.',
      'order.bulk.files.changeFile': 'Replace file',

      'order.bulk.files.error.fields.lastName': 'Last name',
      'order.bulk.files.error.fields.firstName': 'First name',
      'order.bulk.files.error.fields.dateOfBirth': 'Date of birth',
      'order.bulk.files.error.fields.nameOnCard': 'Name on card',
      'order.bulk.files.error.fields.address': 'Address',
      'order.bulk.files.error.fields.city': 'City',
      'order.bulk.files.error.fields.zipCode': 'Zip code',
      'order.bulk.files.error.fields.phoneNumber': 'Telephone',
      'order.bulk.files.error.fields.email': 'Card owner e-mail',
      'order.bulk.files.error.fields.cardData': 'Card information',
      'order.bulk.files.error.fields.cardType': 'Type of card',
      'order.bulk.files.error.fields.loadAmount': 'Top up amount',
      'order.bulk.files.error.fields.csn': 'Card serial',

      'order.bulk.files.error.field':
        '{{::field | translate}} error in columns:',

      'order.companyData.details': 'Company information',
      'order.companyData.hq': 'Company address',

      'order.companyData.companyName': 'Company name',
      'order.companyData.companyNumber': 'Company number',
      'order.companyData.vatNumber': 'VAT number',
      'order.companyData.nameOnCard': 'Company name on card',
      'order.companyData.zipCode': 'Zip code',
      'order.companyData.city': 'City',
      'order.companyData.address': 'Address',
      'order.deliveredTo': 'Recipient name',
      'order.deliveredTo.lastName': 'Recipient last name',
      'order.deliveredTo.firstName': 'Recipient first name',
      'order.companyData.billingDifferentFromHq':
        'Billing address differs from the company’s address',
      'order.companyData.shippingDifferentFromHq':
        'Shipping address differs from the company’s address',
      'order.companyData.billingData': 'Billing information',
      'order.companyData.shippingData': 'Shipping information',

      'order.ownerStatement.title': 'Beneficiary Owner Declaration (TTNY)',

      'order.ownerStatement.ownerDetails':
        'Dully Authorized Manager / General Manager',
      'order.ownerStatement.ownerDetails.title': 'Owner’s information',
      'order.ownerStatement.ownerDetails.titleId': 'Title',
      'order.ownerStatement.ownerDetails.name':
        'Name of Dully Authorized Manager / General Manager',
      'order.ownerStatement.ownerDetails.birthName': 'Name of birth',
      'order.ownerStatement.ownerDetails.occupation': 'Occupation',
      'order.ownerStatement.ownerDetails.citizenship': 'Citizenship',
      'order.ownerStatement.ownerDetails.identification.type': 'ID type',
      'order.ownerStatement.ownerDetails.identification.number': 'ID number',

      'order.ownerStatement.ownerAddress.title': 'Address',
      'order.ownerStatement.ownerAddress.hungarianAddress.title':
        'Hungarian address',
      'order.ownerStatement.ownerAddress.hungarianAddress.subtitle':
        'Temporary hungarian address is required in case of foreign citizenship',
      'order.ownerStatement.ownerAddress.foreigner': 'Foreign citizenship',
      'order.ownerStatement.ownerAddress.phoneNumber':
        'Phone number (optional)',
      'order.ownerStatement.ownerAddress.faxNumber': 'Fax (optional)',
      'order.ownerStatement.ownerAddress.email': 'E-mail address (optional)',

      'order.administration.title': 'Administration information',
      'order.administration.proforma.title': 'Prepayment information',
      'order.administration.proforma.bankAccount': 'Bank account',
      'order.administration.proforma.employeeNumber': 'Number of employees',
      'order.administration.proforma.phoneNumber': 'Phone number (optional)',
      'order.administration.proforma.faxNumber': 'Fax (optional)',
      'order.administration.proforma.email': 'Administrator email',

      'order.administration.credentials.title': 'Information for login',
      'order.administration.credentials.titleId': 'Title',
      'order.administration.credentials.name': 'Administrator’s name',
      'order.administration.credentials.lastName': 'Last name',
      'order.administration.credentials.firstName': 'First name',
      'order.administration.credentials.password': 'Password',
      'order.administration.credentials.password.legend':
        'Please enter your password which you can use later for order in two easy steps. In case you have already registered with this e-mail address, please enter the password required.',
      'order.administration.credentials.password.tooltip':
        'Your password has to be longer than 8 digits <br />and has to contain lower and upper case and numbers as well.',

      'order.invoice.title': 'Your invoice',
      'order.invoice.1.title': 'New cards',
      'order.invoice.2.title': 'Topped up cards',
      'order.invoice.additionalFees': 'Additional fees',
      'order.invoice.notes': 'Note',

      'order.invoice.cardType.5.title': 'Gift and shopping card',
      'order.invoice.cardType.6.title': 'Enrollment card',
      'order.invoice.cardType.7.title': 'White Gift Card',
      'order.invoice.cardType.8.title': 'Gold Gift Crad',
      'order.invoice.cardType.9.title': 'Culture and Sport Card',
      'order.invoice.cardType.10.title': 'Silver Gift Card',

      'order.invoice.cardType.15.title': 'Rewin Standard Virtual Card',
      'order.invoice.cardType.16.title': 'Rewin Standard Physical Card',
      'order.invoice.cardType.17.title': 'Rewin Platinum Card',

      'order.invoice.cardType.28.title': 'Rewin Individual Card',
      'order.invoice.cardType.29.title': 'Rewin Comfort Card',
      'order.invoice.cardType.30.title': 'Multiwallet Card',

      'order.invoice.cardType.31.title': 'Rewin Kids Card',
      'order.invoice.cardType.32.title': 'Rewin Spouse Card',
      'order.invoice.cardType.33.title': 'Rewin Parent Card',

      'order.invoice.cardType.undefined.title': 'Unknown type',
      'order.invoice.cardType.topup.title': 'Top ups',

      'order.invoice.item': 'Item',
      'order.invoice.cardType': 'Card type',
      'order.invoice.fees': 'Fees',
      'order.invoice.qty': 'Quantity',
      'order.invoice.net': 'Net',
      'order.invoice.vat': 'VAT',
      'order.invoice.gross': 'Gross',
      'order.invoice.total': 'Total',
      'order.invoice.wallets': 'Wallets',
      'order.invoice.sum': 'Total',
      'order.invoice.topUpDate': 'A kívánt szállítási dátum',
      'order.invoice.topUpDate.info': 'Kérjük, válassza ki azt az időpontot, amikorra szeretné, hogy a Rewin Ticket Restaurant SZÉP Kártyák a rátöltött juttatással együtt leszállításra kerül a megadott szállítási címre. Ez az időpont az adategyeztetés leadását követő 13. és 60. munkanap közé eshet. Ennél korábban nem tudjuk a kártyák gyártását és feltöltését garantálni; ez az idő szükséges ahhoz, hogy munkavállalói online szerződést kössenek és a kártyák gyártása elkezdődjön. Felhívjuk figyelmét, hogy csak azon munkavállalóknak tudjuk a feltöltést elindítani, akik a feltöltési dátumig sikeresen online szerződést kötöttek.',
      'order.invoice.shippingAddress': 'Billing address of cards (In the case of a physical card)',
      'order.invoice.estShipping': 'Estimated delivery (In the case of a physical card)',
      'order.invoice.estShippingLegend':
        'The sixth working day from the transfer in between 8 am - 6 pm.',
      'order.invoice.recipient': 'Recipient’s name (In the case of a physical card)',
      'order.invoice.topUpFee': 'Top up fee ({{handlingFeePercent}}%)',
      'order.invoice.cardFee': 'Card fee',
      'order.invoice.shipping': 'Shipping fee',
      'order.invoice.cardActivationNotice': '<h3>Dear Client!</h3><p>We would like to inform you that the card tokens of the virtual cards are sent by e-mail directly to the Cardholders to their e-mail address specified in the order file.</p><p>In the case of orders for new cards, Cardholders can complete the activation process online, according to the cover letter attached to the card (sent by e-mail in the case of a virtual card).</p>',

      'order.sendOrder': 'Send order',

      'order.thankYou': 'We saved your order in our system, thank you for ordering',
      'order.thankYou.title': 'Thank you for your order!',
      'order.thankYou.description': 'We have started to process it. We will send the order confirmation by email, which may take up to 10 minutes.',
      'order.backToHome': 'Back to Home',

      'order.success.vipHeading': 'Our Customer Service received sour files.',
      'order.success.vipContent':
        'Once processed, we will send you information on the following e-mail address: <strong>{{email}}</strong>.',
      'order.success.furtherSteps': 'Next steps',
      'order.success.furtherStepsLegend': 'We saved your order in our system.',

      'order.success.registered.first.title': 'Prepayment send',
      'order.success.registered.first.legend':
        'Within 24 hour <strong>we will send you the proforma to the email address you provided before.</strong>',
      'order.success.registered.second.title': 'Payment with transfer',
      'order.success.registered.second.legend':
        'Please transfer the amount <strong>to the bank account given in the proforma.</strong>',
      'order.success.registered.newCard.title': 'Shipping',
      'order.success.registered.newCard.legend':
        'After the production cards will be delivered by courier. <strong>The electronic invoice will be sent to you via email.</strong> You can add extra e-invoice recipient via email to info@rewin.hu',
      'order.success.registered.newCard.legend.2': 'Megkezdjük a kártyák gyártását, és azok elkészülte után <strong>futár fogja Önnek</strong> kiszállítani.',
      'order.success.sendVerificationEmail.title': 'Utalási Értesítő Kiküldése',
      'order.success.sendVerificationEmail.legend': 'Az Ön által megadott <strong>{{email}}</strong> e-mail-címre 1 munkanapon belül utalási értesítőt kap.',
      'order.success.paymentBankTransfer.legend': 'Az ott feltüntetett összeget kérjük a megadott számlaszámra 24 órán belül átutalni. Késedelmes utalás esetén nem tudjuk garantálni, hogy a kívánt szállítási dátumig a kártyákon megtörténjen a feltöltés.',
      'order.success.registered.topUp.title': 'Upload',
      'order.success.topUp.legend': 'Cards top up will be fulfilled.',
      'order.success.registered.topUp.legend':
        'Cards top up will be fulfilled and <strong>the electronic invoice will be sent to you via email.</strong> You can add extra e-invoice recipient via email to info@rewin.hu',
      'order.success.newUser.first.title': 'Payment with transfer',
      'order.success.newUser.first.legend':
        'After you sent back the signed contract to info@rewin.hu we will send you proforma to the entered <strong>{{email}}</strong> e-mail address within 1 working day. Please <strong>transfer the amount</strong> to the given bank account.',
      'order.success.newUser.second.title': 'Shipping',
      'order.success.newUser.second.legend':
        'After we received the given amount we will start to produce the cards, and as soon as they are ready courier will deliver them to you. <strong>We will delivery the original invoice separately</strong>.',
      'order.success.newUser.third.title': 'Order status',
      'order.success.newUser.third.legend':
        'Thanks for your Rewin order. We received it. Our sales representative going to call you soon with futher details.',
      'order.success.sendVerificationEmail.2.title': ' Visszaigazoló kiküldése',
      'order.success.sendVerificationEmail.2.legend': 'Az Ön által megadott <strong>{{email}}</strong> e-mail-címre 1 órán belül visszaigazolót kap, a munkavállalóknak kiküldött egyedi szerződéskötési linkek listájával együtt.',
      'order.success.contractStaff.title': 'Mukavállalók szerződtetése',
      'order.success.contractStaff.legend': 'Az adategyeztetés során megadott munkavállalói e-mail-címekre elküldjük az egyedi szerződéskötési linket. Munkavállalói a kapott e-mailben a linkre kattintva megkötik online szerződésüket.',
      'order.success.sendNotification.title': 'Emlékeztetők küldése',
      'order.success.sendNotification.legend': 'A még nem szerződött munkaválllalóknak e-mailben emlékeztetőket küldünk a folyamat során 3 alkalommal. A nem véglegesített szerződők listáját Önnek is megküldjük a szerződtetés lezárása előtt 2 alkalommal.',
      'order.success.productionOfCards.title': 'Kártyák gyártása',
      'order.success.productionOfCards.legend': 'A kívánt szállítási dátumot megelőző 5. munkanapon lezárjuk a munkavállalói szerződtetést, és megkezdődik a kártyák gyártása.',
      'order.success.topUpCards.title': 'Kártyák feltöltése',
      'order.success.topUpCards.multiwallet.legend': 'A kívánt szállítási dátumot megelőző 3. munkanapon feltöltjük Önnek a legyártott kártyák szériaszámos listáját, ennek megtörténtéről e-mailben értesítjük Önt. A szériaszámos lista segítségével tudja leadni feltöltésre vonatkozó megrendelését. A kívánt szállítási határidő tartásához kérjük, hogy az értesítést követő 24 órán belül adja le megrendelését! Amennyiben késve érkezik a kártyák feltöltésére vonatkozó megrendelés, a kártyákon a szállítás időpontjában nem lesz juttatás feltöltve, úgy a kártyák egyenleg feltöltése nélkül kerülnek kiszállításra.',
      'order.success.sendTransferNotification.title': 'Megrendelés összesítő megküldése/utalás',
      'order.success.sendTransferNotification.legend': 'Feltöltésre vonatkozó megrendelése után elküldjük Önnek e-mailben az utalási értesítőt. Kérjük, az utalási értesítő megérkezése után 24 órán belül utalja el az értesítőn szereplő számlaszámra a juttatási összeget. A kívánt szállítási dátumot késői utalás esetén nem tudjuk garantálni.',
      'order.success.delivery.title': 'Kiszállítás',
      'order.success.delivery.legend': 'A kártyák gyártása és feltöltése után a kártyákat futárral szállítjuk ki az Ön által megadott címre.',

      'order.history': 'Order history',
      'order.created': 'Date',
      'order.acceptor': 'Acceptor',
      'order.status': 'Type',
      'order.product': 'Product',
      'order.history.card.1.title': 'Cafeteria',
      'order.history.card.2.title': 'Multiwallet',
      'order.history.card.3.title': 'Benefit',
      'order.history.card.4.title': 'Comfort',
      'order.history.card.5.title': 'Family',
      'order.history.info.source': 'Automatically generated order, source:',
      'order.history.info.source.nitro': 'Nitro discount platform',
      'order.history.info.source.ic': 'Instant Cafeteria',
      'order.amount': 'Amount',
      'order.amount.header': 'Top up amount',
      'order.more': 'Next',
      'order.filter': 'Filter',
      'order.filter.dateFrom': 'Start date',
      'order.filter.dateTo': 'End date',
      'order.filter.submit': 'Submit',

      'order.filter.year': 'Year',
      'order.filter.month': 'Month',
      'order.filter.day': 'Day',
      'order.filter.year.label': 'Birth year',
      'order.filter.month.label': 'Birth month',
      'order.filter.day.label': 'Birth day',

      'order.cart': 'Cart',

      'order.1.unique.title': 'Individual card order',
      'order.benefit.1.unique.title': 'Unique order',
      'order.cafeteria.1.unique.title': 'Unique order',
      'order.multiWallet.1.unique.title': 'Multiwallet unique order',
      'order.family.1.unique.title': 'Unique order',
      'order.1.unique.subtitle': '(Maximum 25 cards)',
      'order.1.unique.legend':
        'For orders with maximum 25 cards we suggest to save information one-by-one for the cards.',

      'order.1.bulk.title': 'Bulk order',
      'order.benefit.1.bulk.title': 'Bulk order',
      'order.cafeteria.1.bulk.title': 'Bulk order',
      'order.multiWallet.1.bulk.title': 'Multiwallet Bulk order',
      'order.family.1.bulk.title': 'Bulk order',
      'order.1.bulk.subtitle': '(1-9999 cards)',
      'order.1.bulk.legend':
        'You can upload the cards to our system with the help of an Excel file. We suggest this primarily for ordering 25 cards or more.',

      'order.benefit.2.unique.title': 'Unique order',
      'order.cafeteria.2.unique.title': 'Unique order',
      'order.multiWallet.2.unique.title': 'Multiwallet unique order',
      'order.family.2.unique.title': 'Unique order',
      'order.2.unique.subtitle': '(Maximum 25 cards)',
      'order.2.unique.legend':
        'For orders with maximum 25 cards we suggest to add the amount one-by-one for the cards.',

      'order.2.bulk.title': 'Bulk order',
      'order.benefit.2.bulk.title': 'Bulk order',
      'order.cafeteria.2.bulk.title': 'Bulk order',
      'order.multiWallet.2.bulk.title': 'Multiwallet Bulk order',
      'order.family.2.bulk.title': 'Bulk order',
      'order.2.bulk.subtitle': '(1-9999 cards)',
      'order.2.bulk.legend':
        'You can upload the cards to our system with the help of an Excel file. We suggest this primarily for ordering 25 cards ormore.',

      'order.unique.toast.save': 'Added',
      'order.unique.toast.edit': 'Saved',
      'order.unique.toast.delete': 'Deleted',

      'order.back': 'Back',

      'order.itemList.info':
        'Only one card design can be included on the file.',
      'order.itemList.addNew': 'Add new',
      'order.itemList.firstName': 'First name',
      'order.itemList.lastName': 'Last name',
      'order.itemList.dob': 'Date of Birth',
      'order.itemList.email': 'E-mail',
      'order.itemList.csn': 'Card number',
      'order.itemList.multiWallet.csn': 'Card serial number',
      'order.itemList.vatNumber': 'Vat Number',
      'order.itemList.wallets': 'Wallet',
      'order.itemList.cardType.full': 'Card type',
      'order.itemList.sum': 'Amount',

      'order.itemDialog.1.create.title': 'Add new card',
      'order.itemDialog.2.create.title': 'New card top up',
      'order.itemDialog.1.edit.title': 'Edit card',
      'order.itemDialog.2.edit.title': 'Edit card',

      'order.itemDialog.benefit.1.create.title': 'Add new card',
      'order.itemDialog.cafeteria.1.create.title': 'Add new card',
      'order.itemDialog.multiWallet.1.create.title': 'Add new card',
      'order.itemDialog.comfort.1.create.title': 'Add new card',
      'order.itemDialog.family.1.create.title': 'Add new card',

      'order.itemDialog.benefit.2.create.title': 'New card top up',
      'order.itemDialog.cafeteria.2.create.title': 'New card top up',
      'order.itemDialog.multiWallet.2.create.title': 'New multi wallet card top up',

      'order.itemDialog.benefit.1.edit.title': 'Edit card',
      'order.itemDialog.cafeteria.1.edit.title': 'Edit card',
      'order.itemDialog.multiWallet.1.edit.title': 'Edit card',
      'order.itemDialog.comfort.1.edit.title': 'Edit card',
      'order.itemDialog.family.1.edit.title': 'Edit card',

      'order.itemDialog.benefit.2.edit.title': 'Edit card',
      'order.itemDialog.cafeteria.2.edit.title': 'Edit card',
      'order.itemDialog.multiWallet.2.edit.title': 'Edit card',
      'order.itemDialog.comfort.2.edit.title': 'Edit card',
      'order.itemDialog.family.2.edit.title': 'Edit card',

      'order.itemDialog.multiWallet.2.title': 'New multi wallet card top up',
      'order.loader.inProgress': 'Your order is being processed. Please wait!',

      'order.itemDialog.amountsByWallet': 'Zsebek szerinti feltöltési összeg',
      'order.itemDialog.amountToTopup': 'Feltöltendő összeg',

      'order.itemDialog.cardOwner.title': 'Owner’s information',
      'order.itemDialog.cardData.title': 'Card information',

      'order.itemDialog.close': 'Close window',
      'order.itemDialog.save': 'Save',

      'order.itemList.name': 'Name',
      'order.itemList.address': 'Address',
      'order.itemList.city': 'City',
      'order.itemList.zip': 'Zip code',
      'order.itemList.cardType': 'C.type',
      'order.itemList.amount': 'Amount',

      'order.itemDialog.lastName': 'Last name',
      'order.itemDialog.firstName': 'First name',
      'order.itemDialog.csn': 'Card number',
      'order.itemDialog.email': 'E-mail',
      'order.itemDialog.nameOnCard': 'Name on card',
      'order.itemDialog.address': 'Address',

      'order.itemDialog.dob.full': 'Date of birth',
      'order.itemDialog.dob.full.label': 'Date of birth (eg. 1971.06.28)',
      'order.itemDialog.dob.hint': 'The correct date format: 1980.01.01',
      'order.itemDialog.dob.year': 'Year',
      'order.itemDialog.dob.month': 'Month',
      'order.itemDialog.dob.day': 'Day',
      'order.itemDialog.dob.year.label': 'Birth year',
      'order.itemDialog.dob.month.label': 'Birth month',
      'order.itemDialog.dob.day.label': 'Birth day',

      'order.itemDialog.card.title': 'Cards ordered',
      'order.itemDialog.card.cardType': 'Card type',
      'order.itemDialog.card.amount': 'Top up amount',
      'order.itemDialog.card.legend': 'By clicking the button below you can save the information added to your card, and can add new card to the order.',
      'order.itemDialog.card.benefit.saveAndContinue': 'Save and add new employee',
      'order.itemDialog.card.family.saveAndContinue': 'Savev and add new person',

      'order.itemDialog.cardTypes.title': 'Get to know our cards',

      'order.itemDialog.delete': 'Delete',
      'order.itemDialog.confirmDelete': 'Sure deleting requested items?',
      'order.itemDialog.cancel': 'Cancel',

      'order.generateFromPrevious': 'Order again',
      'order.bulk.files.download': 'Download',
      'order.bulk.files.delete': 'Remove uploaded file',
      'order.bulk.files.delete.dialog':
        'Do you really want to delete the uploaded file ({{fileName}})?',

      'order.errorDialog.title': 'Error',
      'order.errorDialog.legend':
        'The Excel file uploaded is invalid. We collected the possible failures for the faster correction. Please correct the failures and upload again the file!',

      'order.sampleFileDownloadAlert':
        'Please, do not reformat the excel file and do not delete any column, or puncture. The sample data help for accurate filling, please do not wipe it out.',

      'order.cardInfo.open': 'Click here to see a comparison of our cards!',

      'order.cardComparison.title': 'Comparison of cards',
      'order.cardComparison.close': 'Close',
      'order.cardComparison.info': 'A részletes kártyainformációkért kattintson az oldal bal alsó menüjében az egyes kártyákra.',

      'order.comparisonTable.services': 'What can you use it for?',
      'order.comparisonTable.affiliates': 'Where can you use it?',
      'order.comparisonTable.taxRate': 'Taxation',
      'order.comparisonTable.taxRate.text': 'Taxation same as for the wage.',

      'order.comparisonTable.features.1': 'Payment at a POS terminal',
      'order.comparisonTable.features.2': 'Compatible with Apple Pay and Google wallet ',
      'order.comparisonTable.features.3': 'E-commerce transactions',
      'order.comparisonTable.features.4': 'Valid for 3 years',
      'order.comparisonTable.features.5': 'No delivery fee',
      'order.comparisonTable.features.6': 'No distribution complexity',
      'order.comparisonTable.features.7': 'Családtagok támogatására használható kártyáink',
      'order.comparisonTable.features.8': 'Megszemélyesített kártyák (a kártyán szerepelnie kell a kártyabirtokos nevének)',
      'order.comparisonTable.features.9': 'Nem névre szóló kártyáink',
      'order.comparisonTable.features.10': 'Csekély értékű ajándék kiadására használható kártyáink',

      'order.comparisonTable.cards.benefit.1': 'Rewin Ezüst Kártya',
      'order.comparisonTable.cards.benefit.2': 'Rewin Arany Kártya',
      'order.comparisonTable.cards.benefit.3': 'Rewin Platina Kártya',
      'order.comparisonTable.cards.comfort': 'Rewin Komfort Kártya',
      'order.comparisonTable.cards.family.1': 'Rewin Gyerek Kártya',
      'order.comparisonTable.cards.family.2': 'Rewin Társ Kártya',
      'order.comparisonTable.cards.family.3': 'Rewin Szülő Kártya',

      'order.comparisonTable.cardFee': 'Card fee',
      'order.comparisonTable.cardFee.text': '1000 HUF',

      'order.comparisonTable.cardReplacementFee': 'Card renewal fee',
      'order.comparisonTable.cardReplacementFee.text': '1 000 HUF',

      'order.comparisonTable.deliveryFee': 'Delivery cost',
      'order.comparisonTable.deliveryFee.text': '999 HUF',

      'order.comparisonTable.handlingFee': 'Handling fee',
      'order.comparisonTable.cards.6.handlingFee': 'Based on custom enquiry',

      'order.comparisonTable.cards.1.name': 'Rewin Standard Virtual Card',
      'order.comparisonTable.cards.1.services':
        'Limited usage as for the traditional cafeteria cards, but still quite wide usage possibilities.',
      'order.comparisonTable.cards.1.affiliates':
        'At more than 70.000 affiliate partners',
      'order.comparisonTable.cards.1.taxRate': '40,71%',

      'order.comparisonTable.cards.2.name': 'Rewin Standard Physical Card',
      'order.comparisonTable.cards.2.services': 'Without limitation.',
      'order.comparisonTable.cards.2.affiliates':
        'All shops with POS terminal in Hungary and online shopping in HUF.',
      'order.comparisonTable.cards.2.taxRate': '40,71%',

      'order.comparisonTable.cards.3.name': 'Rewin Platina Card',
      'order.comparisonTable.cards.3.services': 'Without limitation.',
      'order.comparisonTable.cards.3.affiliates':
        'Same as for the Gold Card. Cash withdrawal opportunity form all ATM in Hungary.',
      'order.comparisonTable.cards.3.taxRate': '40,71%',

      'order.comparisonTable.cards.4.name': 'Rewin Schooling Card',
      'order.comparisonTable.cards.4.services':
        'School books, school supplies & clothing, shoes, bags, sportswear, optical products, home furnishing, computer devices, do-it-yourself goods.',
      'order.comparisonTable.cards.4.affiliates': '30 000',
      'order.comparisonTable.cards.4.taxRate': '40,71%',

      'order.comparisonTable.cards.5.name': 'Rewin Culture and Sport Card',
      'order.comparisonTable.cards.5.services':
        'Culture (museum, festivals, dance performances, teather, concert, circus) & sport tickets',
      'order.comparisonTable.cards.5.affiliates': '1 000',
      'order.comparisonTable.cards.5.taxRate': '0% limitless',

      'order.comparisonTable.cards.6.name': 'Rewin MotivAction Card',
      'order.comparisonTable.cards.6.services':
        'Incentive & rewards: motivation of employees, business partners, maintenance of key persons: gift, anniversary payment, year-end or ad-hoc rewards, 13th month payment, game etc.',
      'order.comparisonTable.cards.6.affiliates': '70 000',
      'order.comparisonTable.cards.6.taxRate': '40,71%',

      'order.callback.title': 'Request a callback',
      'order.callback.standalone.heading':
        "We'd like to have You amongst our clients too!",
      'order.callback.merchant.heading':
        'Would you like to become Rewin affiliate merchant?',
      'order.callback.legend':
        'If you can’t complete your order now, because you don’t have all the data, we kindly ask you to fill out the following form!<br/>Then our sales representative will call You back soon and will help You to go through the online contracting and ordering process.',
      'order.callback.standalone.legend':
        'In case our product has caught your interest as an employer, we kindly ask you to fill out your contact details on the right, so our sales representative can call you to provide more information.',
      'order.callback.merchant.legend':
        'Please fill out this form and we will call you back soon!',
      'order.callback.name': 'Name',
      'order.callback.companyName': 'Company name',
      'order.callback.phone': 'Phone (direct contact)',
      'order.callback.customerType': 'Client type',
      'order.callback.customerType.1': 'Employer',
      'order.callback.customerType.2': 'Cardholder',
      'order.callback.customerType.3': 'Affiliate partner',
      'order.callback.customerType.1.analytics': 'munkáltató',
      'order.callback.customerType.2.analytics': 'munkavállaló',
      'order.callback.customerType.3.analytics': 'elfogadóhely',
      'order.callback.customerType.1.analytics.full':
        'Munkáltatói igény beküldve',
      'order.callback.customerType.2.analytics.full':
        'Munkavállalói igény beküldve',
      'order.callback.customerType.3.analytics.full':
        'Elfogadóhelyi igény beküldve',
      'order.callback.submit': 'Call me back',
      'order.callback.cancel': 'Cancel',
      'order.callback.success': 'Your request has been sent!',

      'order.orderStart.title.1': 'Card ordering with Online contracting',
      'order.orderStart.title.2': 'Request a callback',
      'order.orderStart.aboutCards': 'Compare cards',
      'order.orderStart.order': 'Order with contract',
      'order.orderStart.text':
        'Your online contract will automatically be created when the first order is submitted.<br/>The order contains <strong>5 simple steps</strong> and approx. it takes <strong>10 minutes</strong>.<br/>Before proceeding, please complete the following steps:',
      'order.orderStart.steps.1.title': 'Preparation of company data',
      'order.orderStart.steps.1.description':
        '(company name, tax number, owner and contact data)',
      'order.orderStart.steps.2.title': 'Select the cards to be ordered',
      'order.orderStart.steps.3.title':
        "Collecting prospective cardholders, employee's data",
      'order.orderStart.steps.3.description':
        '(name, date of birth, address, email address)',
      'order.orderStart.steps.4.title':
        'Determine the amount to be uploaded per card',
      'order.orderStart.callback.title': 'Write to us',
      'order.orderStart.callback.text':
        'If you do not yet have all the above-mentioned data or the final list of cards to order, please fill in the following form and our sales representative will reach out to You soon!',

      'order.productTypeSelector.title': 'Card selector',
      'order.productTypes.3.title': 'Rewin Standard cards',
      'order.productTypes.3.content': '<strong>Physical card</strong>: A modern Mastercard card, which is an excellent benefit and incentive solution for all age groups.<br /> <strong>Virtual card</strong>: A modern means of payment which can be distributed quickly and cost-effectively for those who like innovative solutions.',
      'order.productTypes.1.title': 'Traditional cafeteria cards',
      'order.productTypes.1.content': 'From 1st February 2020 <strong>you can order only much more modern contactless Rewin benefit cards</strong>. In case of card renewal or card replacement we can provide also our new benefit cards instead of the traditional cafeteria cards. If you do <strong>not have contract for Rewin Benefit Card thus please contact your sales representative</strong>. The <strong>traditional cafeteria cards issued before February 1st 2020 remain valid</strong> untill their expiration date.',
      'order.productTypes.1.dialog.title': 'Dear Client,',
      'order.productTypes.1.dialog.close': 'Close',
      'order.productTypes.2.title': 'Multi wallet cards',
      'order.productTypes.2.content':
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus animi dolorem dolorum eveniet fugit inventore iusto magni molestias, neque optio perferendis possimus, praesentium quam quas repellendus sed sequi, voluptatum. Adipisci animi aspernatur autem fugit illo illum labore minus nostrum placeat?',
      'order.productTypes.2.wallets.0': 'Szálláshely zseb',
      'order.productTypes.2.wallets.1': 'Vendéglátás zseb',
      'order.productTypes.2.wallets.2': 'Szabadidő zseb',
      'order.productTypes.4.title': 'Rewin Comfort cards',
      'order.productTypes.4.content': 'A modern disposable card for one-shot benefits. Simplicity of a paper voucher combined with a flexiblity of a card. Upload limit: min. 500 HUF, max. 45.000 HUF.',
      'order.productTypes.5.title': 'Rewin Family cards',
      'order.productTypes.5.content': 'Direct support for the employees’ families.',
      'order.productTypeSelector.close': 'Close window',
      'order.productTypeSelector.submit': 'Next',

      'order.replacementCard.title': 'Order replacement card',
      'order.replacementCard.description':
        "Here you can reorder your employee's lost/stolen/damaged card by filling in the cardholder name and the card token.",
      'order.replacementCard.successText': 'Thank you, we received your order.',

      'order.comfortCard.title': 'Rewin Comfort card order',
      'order.comfortCard.info': 'Upload limit: min. 500 HUF, max. 45.000 HUF. Additional upload is not avaiable.',
      'order.comfortCard.itemList.quantity': 'Quantity',
      'order.comfortCard.itemList.loadAmount': 'Load Amount',
      'order.comfortCard.itemList.sum': 'Total',

      'order.multiWallet.recreation.title': 'Recreation',
      'order.multiWallet.accommodation.title': 'Accomodation',
      'order.multiWallet.meal.title': 'Meal',

      'order.multiWallet.title': 'Rewin Ticket Restaurant SZÉP Kártya adategyeztetés',
      'order.multiWallet.info':
        '<p>SZÉP+ kártya esetében a kártyabirtokossal szükséges szerződni.<br> A leadott rendelési igény alapján a leendő kártyabirtokosnak e-mail üzenetben értesítjük. A kártyák megrendelése a kártyabirtokosok jóváhagyása után történik meg.</p> <p>A szerződéshez szükséges egyedi internetes címek a cég kapcsolati e-mail-címére is megküldésre kerülnek.</p>Fontos:<ul><li>info@-cal nem kezdődhet e-mail-cím</li><li>egy e-mail-címre egy Rewin Ticket Restaurant SZÉP Kártya igényelhető csak</li></ul>',
      'order.multiWallet.submit': 'Next',

      'order.multiWallet.bulk.files.title': 'Kártyarendelés fájlfeltöltéssel',
      'order.multiWallet.bulk.files.info':
        'Egy fájlon belül csak azonos típusú kártyák szerepelhetnek.',

      'order.dataReconciliation.process.title': 'Rewin Ticket Restaurant SZÉP Kártya - kártyarendelés és kártyafeltöltés folyamata',
      'order.dataReconciliation.close': 'Close',
      'order.dataReconciliation.process.1.title': 'Adategyeztető lap feltöltése',
      'order.dataReconciliation.process.2.title': 'Kártyabirtokosok szerződéskötése',
      'order.dataReconciliation.process.3.title': 'Szériaszámok megérkeznek',
      'order.dataReconciliation.process.4.title': 'Feltöltés',

      'order.dataReconciliation.process.1.info': 'Kérjük, a mintaadatbázisnak megfelelően készítse el azon munkavállalóinak adatbázisát, akiknek Rewin Ticket Restaurant SZÉP Kártyát szeretne igényelni, majd töltse fel a felületre.',
      'order.dataReconciliation.process.2.info': 'Munkavállalói az adategyeztető lapon szereplő e-mail-címükre automatikus értesítést kapnak. Az abban lévő linkre kattintva rövid adategyeztetés után a munkavállalók és az Rewin között a szerződés elektronikus úton létrejön.',
      'order.dataReconciliation.process.3.info': 'Miután munkavállalóival létrejött a szerződés, a munkavállalók Rewin Ticket Restaurant SZÉP Kártyáihoz tartozó szériaszámokat felhasználói fiókjában megtekintheti és letöltheti.',
      'order.dataReconciliation.process.4.info': 'A szériaszámok alapján kezdeményezheti a Rewin Ticket Restaurant SZÉP Kártyák feltöltését. A rendelési mintafájl alapján állítsa össze a feltöltendő kártyák listáját, adja meg a feltöltendő összegeket és töltse fel a fájlt.',

      'order.multiWalletDataReconciliationList.title': 'Korábbi Ticket Restaurant SZÉP Kártya adategyeztetések',
      'order.multiWalletDataReconciliationList.info': 'Itt elindíthatja dolgozói részére a Rewin Ticket Restaurant SZÉP Kártya igénylést, ledhatja megrendelését a már kész kártyák feltöltésére illetve ellenőrizheti munkavállalói Rewin Ticket Restaurant SZÉP Kártya rendelésének státuszát. Új kártyák rendelésének elindításához kattintson az ÚJ ADATEGYEZTETÉS gombra, feltöltés esetén válassza a KÁRTYAFELTÖLTÉS gombot. Az elindított szerződéskötések/kártyarendelések státuszáról e-mailben értesítjük Önt. A státuszokat emellett bármikor ellenőrizheti a lenti táblázatban, különböző szűrési paraméterek alapján. Itt kezdeményezheti még nem szerződőtt dolgozói újraértesítését, vagy pl. munkaviszony megszűnése esetén a szerződékötési folyamat megszakítását/kártyarendelés törlését.',

      'order.multiWalletDataReconciliationList.new': 'New Datareconciliation',
      'order.multiWalletDataReconciliationList.topup': 'Card Topup',
      'order.multiWalletDataReconciliationList.filter': 'Filter',

      'order.multiWalletDataReconciliationList.form.name': 'Munkavállaló neve',
      'order.multiWalletDataReconciliationList.form.status': 'Státusz',
      'order.multiWalletDataReconciliationList.form.date': 'Beküldés dátuma',
      'order.multiWalletDataReconciliationList.form.fromDate': '-tól',
      'order.multiWalletDataReconciliationList.form.toDate': '-ig',

      'order.multiWalletDataReconciliationList.process.0.title': 'Minden státusz',
      'order.multiWalletDataReconciliationList.process.1.title': 'Rögzítve',
      'order.multiWalletDataReconciliationList.process.2.title': 'Munkavállaló értesítve',
      'order.multiWalletDataReconciliationList.process.3.title': 'Munkavállaló szerződtetve',
      'order.multiWalletDataReconciliationList.process.4.title': 'Kártya rendelés leadva',
      'order.multiWalletDataReconciliationList.process.5.title': 'Törölt',
      'order.multiWalletDataReconciliationList.process.6.title': 'Lejárt',

      'order.multiWalletDataReconciliationList.table.header.orderSent': 'Beküldés dátuma',
      'order.multiWalletDataReconciliationList.table.header.notificationSent': 'Értesítés dátuma',
      'order.multiWalletDataReconciliationList.table.header.lastName': 'Családi név',
      'order.multiWalletDataReconciliationList.table.header.firstName': 'Utónév',
      'order.multiWalletDataReconciliationList.table.header.contract': 'Szerződéskötés',
      'order.multiWalletDataReconciliationList.table.header.cardOrder': 'Kártyarendelés',
      'order.multiWalletDataReconciliationList.table.header.email': 'E-mail-cím',
      'order.multiWalletDataReconciliationList.table.header.status': 'Státusz',

      'order.multiWalletDataReconciliationList.action.delete': 'Delete',
      'order.multiWalletDataReconciliationList.action.notify': 'Notify',

      'order.multiWalletDataReconciliationList.selection.selectAll': 'Select all / deselect all',

      'order.multiWalletDataReconciliationList.toast.delete': 'Sikeres törlés',
      'order.multiWalletDataReconciliationList.toast.email': 'Az értesítéseket kiküldtük'
    });
  }
})();
