<md-dialog>
  <md-dialog-content>
    <h2 class="md-title">{{::'faq' | translate}}</h2>
    <faq-list></faq-list>
  </md-dialog-content>
  <div class="md-actions">
    <md-button ng-click="faqDialog.closeDialog()" class="md-primary">
      OK
    </md-button>
  </div>
</md-dialog>
