<md-dialog class="wb-item-dialog wb-dialog-small">
  <form
    name="comfortCardItemDialog.itemDialogForm"
    ng-submit="comfortCardItemDialog.itemDialogForm.$valid && comfortCardItemDialog.submit()"
    novalidate
    method="post"
  >
    <h2 class="wb-item-dialog-title">
      {{::'order.itemDialog.' + comfortCardItemDialog.selectedProductType + '.' + comfortCardItemDialog.orderType + '.' + (comfortCardItemDialog.isNewItem ? 'create' : 'edit') + '.title' | translate}}
    </h2>

    <div layout="row" layout-margin>
      <md-input-container flex="60">
        <label>{{::'order.comfortCard.itemList.loadAmount' | translate}}</label>
        <input
          type="number"
          name="loadAmount"
          min="500"
          max="45000"
          required
          pattern="^\d+$"
          ng-model="comfortCardItemDialog.item.loadAmount"
        />
        <ng-messages
          class="ng-messages"
          for="comfortCardItemDialog.itemDialogForm['loadAmount'].$error"
          role="alert"
        >
          <div ng-message="required">
            {{::'form.validation.required' | translate}}
          </div>
          <div ng-message="min">{{::'form.validation.min' | translate}}</div>
          <div ng-message="max">{{::'form.validation.max' | translate}}</div>
          <div ng-message="pattern">
            {{::'form.validation.pattern' | translate}}
          </div>
        </ng-messages>
        <wb-messages
          form="comfortCardItemDialog.itemDialogForm"
          server="comfortCardItemDialog.getServerValidation()"
          field-name="loadAmount"
        ></wb-messages>
      </md-input-container>

      <md-input-container flex="25">
        <label>{{::'order.comfortCard.itemList.quantity' | translate}}</label>
        <input
          type="number"
          name="quantity"
          min="1"
          required
          pattern="^\d+$"
          ng-model="comfortCardItemDialog.item.quantity"
        />
        <ng-messages
          class="ng-messages"
          for="comfortCardItemDialog.itemDialogForm['quantity'].$error"
          role="alert"
        >
          <div ng-message="required">
            {{::'form.validation.required' | translate}}
          </div>
          <div ng-message="min">{{::'form.validation.min' | translate}}</div>
          <div ng-message="pattern">
            {{::'form.validation.pattern' | translate}}
          </div>
        </ng-messages>
        <wb-messages
          form="comfortCardItemDialog.itemDialogForm"
          server="comfortCardItemDialog.getServerValidation()"
          field-name="quantity"
        ></wb-messages>
      </md-input-container>
    </div>

    <div class="md-actions">
      <md-button
        ng-click="comfortCardItemDialog.closeDialog()"
        class="md-primary"
        type="button"
      >
        {{::'order.itemDialog.close' | translate}}
      </md-button>

      <md-button
        class="md-primary"
        ng-hide="comfortCardItemDialog.isProcessing()"
        type="submit"
      >
        {{::'order.itemDialog.save' | translate}}
      </md-button>
      <div
        class="button-loader-wrapper"
        ng-show="comfortCardItemDialog.isProcessing()"
      >
        <md-progress-circular
          class="md-hue-2 button-loader"
          md-diameter="36px"
          md-mode="indeterminate"
        ></md-progress-circular>
      </div>
    </div>
  </form>
</md-dialog>
