<md-card class="multi-wallet-card-item-list">
  <md-toolbar class="wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.multiWallet.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <form
      name="multiWalletCardItemList.itemListForm"
      ng-submit="multiWalletCardItemList.itemListForm.$valid && multiWalletCardItemList.submit()"
      novalidate
      method="post"
    >
      <div class="wb-alert wb-alert--warning wb-alert--bold">
        <md-icon md-font-icon="zmdi zmdi-alert-triangle zmdi-hc-3x"></md-icon>
        <div ng-bind-html="::'order.multiWallet.info' | translate"></div>
      </div>

      <md-button
        class="md-raised md-primary no-side-margin"
        ng-click="multiWalletCardItemList.openItemDialog()"
        type="button"
      >
        {{::'order.itemList.addNew' | translate}}
      </md-button>

      <div class="wb-table" ng-if="multiWalletCardItemList.getSize()">
        <div
          class="wb-table-header"
          layout="row"
          layout-align="start center"
          flex
        >
          <span flex="20">{{::'order.itemList.lastName' | translate}}</span>
          <span flex="20">{{::'order.itemList.firstName' | translate}}</span>
          <span flex="20">{{::'order.itemList.dob' | translate}}</span>
          <span flex="25">{{::'order.itemList.email' | translate}}</span>
          <span flex="15"></span>
        </div>

        <div
          class="wb-table-item"
          layout="row"
          layout-align="start center"
          ng-repeat="item in multiWalletCardItemList.items"
        >
          <span flex="20">{{ item.lastName }}</span>
          <span flex="20">{{ item.firstName }}</span>
          <span flex="20">{{ item.dateOfBirth | date: 'yyyy.MM.dd' }}</span>
          <span flex="25">{{ item.email }}</span>
          <span flex="15" class="text-right">
            <md-button
              class="md-icon-button md-button-sm"
              ng-click="multiWalletCardItemList.editItem($index)"
              type="button"
            >
              <md-icon
                md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-edit"
              ></md-icon>
            </md-button>

            <md-button
              class="md-icon-button md-button-sm"
              ng-click="multiWalletCardItemList.openDeleteItemDialog(item)"
              type="button"
            >
              <md-icon
                md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-delete"
              ></md-icon>
            </md-button>
          </span>
        </div>
      </div>

      <wb-order-empty-item-list
        ng-if="!multiWalletCardItemList.getSize()"
        order-type="{{::multiWalletCardItemList.orderType}}"
      ></wb-order-empty-item-list>

      <div layout="row" layout-align="space-between center">
        <md-button
          type="button"
          class="md-raised md-background no-side-margin"
          ng-click="order.prevStep('typeSelector', 'multiWalletCardItemList')"
          aria-label="{{::'order.back' | translate}}"
        >
          {{::'order.back' | translate}}
        </md-button>
        <md-button
          analytics-on="click"
          analytics-event="tovabb"
          analytics-category="itemList"
          analytics-if="!order.isAuthenticated"
          class="md-raised md-accent no-side-margin"
          ng-disabled="!multiWalletCardItemList.getSize()"
          ng-hide="multiWalletCardItemList.isProcessing()"
          type="submit"
        >
          {{::'order.multiWallet.submit' | translate}}
        </md-button>
        <div
          class="button-loader-wrapper"
          ng-show="multiWalletCardItemList.isProcessing()"
        >
          <md-progress-circular
            class="md-hue-2 button-loader"
            md-diameter="36px"
            md-mode="indeterminate"
          >
          </md-progress-circular>
        </div>
      </div>
    </form>
  </md-card-content>
</md-card>
