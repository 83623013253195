<md-dialog class="wb-error-dialog wb-dialog-medium">

  <md-dialog-content>
    <h2 class="md-title">{{::'order.errorDialog.title' | translate}}</h2>

    <div class="wb-error-legend" layout="row" layout-align="space-around center">
      <div flex>
        <md-button class="md-fab md-warn not-a-button">
          <md-icon md-font-icon="zmdi zmdi-alert-triangle zmdi-hc-fw zmdi-hc-lg"></md-icon>
        </md-button>
      </div>
      <div flex="85">{{::'order.errorDialog.legend' | translate}}</div>
    </div>

    <div class="wb-error-list" >
      <ul ng-repeat="(field, errors) in fileBulkErrorDialog.errors" ng-if="field !== 'file'">
        <li class="wb-error-section-header">{{::'order.bulk.files.error.field' | translate: {field: 'order.bulk.files.error.fields.' + field} }}</li>

        <li ng-repeat="(error, columns) in errors">
          {{::'form.validation.' + error | translate}} - {{columns}}
        </li>
      </ul>
    </div>
  </md-dialog-content>

  <div class="md-actions">
    <md-button ng-click="fileBulkErrorDialog.closeDialog()" class="md-primary" type="button">
      {{::'order.itemDialog.close' | translate}}
    </md-button>
  </div>
</md-dialog>
