<md-card>
  <md-toolbar class="wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.vip.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <div class="wb-file-upload wb-file-upload--vip" layout="row" layout-align="start start" layout-margin>
      <div flex layout="column" layout-align="start stretch">
        <p>{{::'order.vip.sampleFile.legend' | translate}}</p>

        <md-input-container flex>
          <input type="text" name="note" ng-model="vipUpload.note" ng-minlength="4" ng-maxlength="500">
          <label>{{::'order.vip.note' | translate}}</label>
          <wb-messages form="vipUpload.vipUploadForm" server="vipUpload.getServerValidation()" field-name="note"></wb-messages>
        </md-input-container>
      </div>

      <div class="wb-file-upload-box wb-file-upload-input text-center"
           layout="column"
           layout-align="center center"
           flex="auto"
           offset="5"
           flow-init
           flow-file-added="vipUpload.validateFile($file, $event, $flow)"
           flow-files-submitted="vipUpload.sendToAPI($file, $event, $flow)"
           flow-file-error="vipUpload.handleAPIErrors($file, $message, $flow)"
           flow-file-success="vipUpload.handleAPISuccess($file, $message, $flow)"
           flow-drop
           flow-drag-enter="class='is-dragging'"
           flow-drag-leave="class=''"
           ng-class="[{'has-success': vipUpload.isValidFile()}, {'has-error': !vipUpload.isValidFile() && vipUpload.hasFile()}, class]">
        <md-button class="md-fab not-a-button md-grey md-wbTheme-theme" ng-if="!vipUpload.hasFile()" aria-label="{{::'order.bulk.files.uploadFile' | translate}}">
          <md-icon md-font-icon="zmdi zmdi-upload zmdi-hc-lg zmdi-hc-fw"></md-icon>
        </md-button>

        <div class="wb-file-upload-list"
             ng-if="vipUpload.hasFile()">
          <span>{{vipUpload.getAvailableFileName()}}</span>
        </div>

        <p>{{::'order.bulk.files.uploadFile.legend' | translate}}</p>

        <div ng-if="vipUpload.getFileRelatedError()">
          <p class="error-response">{{'form.validation.' + vipUpload.getFileRelatedError() | translate}}</p>
        </div>

        <md-button class="md-raised md-accent" ng-if="!vipUpload.hasFile()" flow-btn>{{::'order.bulk.files.uploadFile' | translate}}</md-button>
        <div layout="row" ng-if="vipUpload.hasFile()">
          <md-button class="md-raised md-accent" flow-btn>{{::'order.bulk.files.changeFile' | translate}}</md-button>
        </div>
      </div>
    </div>

    <div layout="row" layout-align="end center" layout-margin>
      <md-button ng-disabled="!vipUpload.isValidFile()"
                 class="md-raised md-accent"
                 ng-click="vipUpload.submit()"
                 ng-hide="vipUpload.isProcessing()">
        {{::'order.more' | translate}}
      </md-button>
      <div class="button-loader-wrapper" ng-show="vipUpload.isProcessing()">
        <md-progress-circular class="md-hue-2 button-loader"
                              md-diameter="36px"
                              md-mode="indeterminate">
        </md-progress-circular>
      </div>
    </div>
  </md-card-content>
</md-card>
