<div layout="row" layout-align="space-around center">
  <div flex="50">
    <md-card class="wb-featured-action new-card-order">
      <md-card-content layout="row" layout-align="center start">
        <div flex="20">
          <md-icon md-font-icon="wb-featured-action-icon zmdi zmdi-shopping-cart zmdi-hc-lg zmdi-hc-fw"></md-icon>
        </div>
        <div flex="80">
          <h2 class="md-title">{{::'navigation.newCardOrder' | translate}}</h2>
          <p>{{::'account.newCardOrder.legend' | translate}}</p>
        </div>
      </md-card-content>
      <div class="md-actions" layout="row" layout-align="end center">
        <md-button
          analytics-on="click" analytics-event="{{::'order.steps.analytics.authenticated' | translate}} - {{::'order.steps.analytics.start' | translate}}" analytics-category="{{::'order.steps.analytics.registration.1' | translate}}" analytics-label="{{dashboard.getActiveCompanyVatNumber()}}"
          ui-sref="order({type: 'new-card'})">
          {{::'account.more' | translate}}
        </md-button>
      </div>
    </md-card>
  </div>
  <div flex="50">
    <md-card class="wb-featured-action top-up">
      <md-card-content layout="row" layout-align="center start">
        <div flex="20">
          <md-icon md-font-icon="wb-featured-action-icon zmdi zmdi-trending-up zmdi-hc-lg zmdi-hc-fw"></md-icon>
        </div>
        <div flex="80">
          <h2 class="md-title">{{::'navigation.topUp' | translate}}</h2>
          <p>{{::'account.topUp.legend' | translate}}</p>
        </div>
      </md-card-content>
      <div class="md-actions" layout="row" layout-align="end center">
        <md-button
          analytics-on="click" analytics-event="{{::'order.steps.analytics.authenticated' | translate}} - {{::'order.steps.analytics.start' | translate}}" analytics-category="{{::'order.steps.analytics.registration.2' | translate}}" analytics-label="{{dashboard.getActiveCompanyVatNumber()}}"
          ui-sref="order({type: 'top-up'})">
          {{::'account.more' | translate}}
        </md-button>
      </div>
    </md-card>
  </div>
</div>

<wb-order-history layout="column" limit="{{::dashboard.transactionLimit}}"></wb-order-history>

<div layout="column">
  <wb-banners hide show-gt-sm></wb-banners>
</div>
