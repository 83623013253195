<md-dialog>
  <md-dialog-content>
    <h2 class="md-title">{{::'order.productTypes.1.dialog.title' | translate}}</h2>
    <p ng-bind-html="::'order.productTypes.1.content' | translate"></p>
  </md-dialog-content>
  <div class="md-actions">
    <md-button ng-click="cafeteriaCardOrderDialog.closeDialog()" class="md-primary">
      {{::'order.productTypes.1.dialog.close' | translate}}
    </md-button>
  </div>
</md-dialog>
