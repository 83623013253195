<md-card class="complete-csn-list">
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'completeCsnLists.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <div layout="row" layout-align="space-between center">
      <p flex="60">{{::'completeCsnLists.downloadListInfo' | translate}}</p>
      <md-button
        class="md-raised md-accent"
        ng-hide="completeCsnLists.isLoading"
        ng-click="completeCsnLists.downloadCsnFile()"
      >
        {{::'completeCsnLists.downloadList' | translate}}
      </md-button>
      <div class="button-loader-wrapper" ng-show="completeCsnLists.isLoading">
        <md-progress-circular
          class="md-hue-2 button-loader"
          md-diameter="36px"
          md-mode="indeterminate"
        ></md-progress-circular>
      </div>
    </div>
  </md-card-content>
</md-card>
