<md-card>
  <md-toolbar class="md-primary">
    <div class="md-toolbar-tools">
      {{::'order.invoice.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content layout-gt-sm="column">
    <form name="multiWalletCardInvoice.invoiceForm" ng-submit="multiWalletCardInvoice.submit()" novalidate
      method="post">
      <h2 class="wb-invoice-section-header">
        {{::'order.invoice.' + multiWalletCardInvoice.newCardOrderType + '.title' | translate}}
      </h2>

      <div class="wb-table wb-invoice-table">
        <div class="wb-table-header" layout="row">
          <span flex="85">{{::'order.invoice.cardType' | translate}}</span>
          <span flex="15" class="text-right">{{::'order.invoice.qty' | translate}}</span>
        </div>

        <div class="wb-table-item" layout="row">
          <span
            flex="85">{{::'order.invoice.cardType.' + multiWalletCardInvoice.cardTypeId + '.title' | translate}}</span>
          <span flex="15" class="text-right">
            {{ multiWalletCardInvoice.getSize() }}
          </span>
        </div>
      </div>

      <div class="wb-additional-information" layout="row" layout-wrap>
        <div class="item" flex="50" layout="row" layout-align="start start">
          <div class="icon-wrapper" flex>
            <md-icon md-font-icon="zmdi zmdi-time zmdi-hc-lg zmdi-hc-fw"></md-icon>
          </div>
          <div flex="auto">
            <h3>{{::'order.invoice.topUpDate' | translate}}</h3>
            <p>{{::'order.invoice.topUpDate.info' | translate}}</p>
            <md-input-container class="wb-additional-information-topup-date">
              <md-select ng-model="multiWalletCardInvoice.topUpDate">
                <md-option ng-value="day.date" ng-repeat="day in multiWalletCardInvoice.workingDays">
                  {{ day.date | date:'yyyy-MM-dd' }}
                </md-option>
              </md-select>
            </md-input-container>
          </div>
        </div>

        <div layout="column" flex="50">
          <div class="item" layout="row" layout-align="start start">
            <div class="icon-wrapper" flex>
              <md-icon md-font-icon="zmdi zmdi-pin zmdi-hc-lg zmdi-hc-fw"></md-icon>
            </div>
            <div flex="auto">
              <h3>{{::'order.invoice.shippingAddress' | translate}}</h3>

              <p ng-if="!multiWalletCardInvoice.hasCompanyDeliveryAddress()">
                {{ ::multiWalletCardInvoice.company.name }}
                {{ ::multiWalletCardInvoice.company.address.zipCode }},
                {{ ::multiWalletCardInvoice.company.address.city }}
                {{ ::multiWalletCardInvoice.company.address.address }}
              </p>
              <p ng-if="multiWalletCardInvoice.hasCompanyDeliveryAddress()">
                {{ ::multiWalletCardInvoice.company.name }}
                {{ ::multiWalletCardInvoice.company.deliveryAddress.zipCode }},
                {{ ::multiWalletCardInvoice.company.deliveryAddress.city }}
                {{ ::multiWalletCardInvoice.company.deliveryAddress.address }}
              </p>
            </div>
          </div>

          <div class="item" layout="row" layout-align="start start">
            <div class="icon-wrapper" flex>
              <md-icon md-font-icon="zmdi zmdi-account zmdi-hc-lg zmdi-hc-fw"></md-icon>
            </div>
            <div flex="auto">
              <h3>{{::'order.invoice.recipient' | translate}}</h3>

              <p>
                {{ ::multiWalletCardInvoice.company.deliveryLastName }}
                {{ ::multiWalletCardInvoice.company.deliveryFirstName }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div flex-gt-sm="100" layout="row" layout-align="space-between center">
        <md-button type="button" class="md-raised md-background"
          ng-click="order.prevStep(multiWalletCardInvoice.prevState)" aria-label="{{::'order.back' | translate}}">
          {{::'order.back' | translate}}
        </md-button>

        <md-button ng-disabled="!multiWalletCardInvoice.invoiceForm.$valid"
          ng-hide="multiWalletCardInvoice.isProcessing()" class="md-raised md-accent" type="submit">
          {{::'order.sendOrder' | translate}}
        </md-button>
        <div class="button-loader-wrapper" ng-show="multiWalletCardInvoice.isProcessing()">
          <md-progress-circular class="md-hue-2 button-loader" md-diameter="36px" md-mode="indeterminate">
          </md-progress-circular>
        </div>
      </div>
    </form>
  </md-card-content>
</md-card>
