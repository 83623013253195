<div class="wb-table">
  <div class="wb-table-header"
       layout="row"
       layout-align="start center"
       flex>
    <span flex="15">{{::'order.itemList.name' | translate}}</span>
    <span flex="10">{{::'order.itemList.dob' | translate}}</span>
    <span flex="15">{{::'order.itemList.address' | translate}}</span>
    <span flex="10">{{::'order.itemList.city' | translate}}</span>
    <span flex="5" class="text-center">{{::'order.itemList.zip' | translate}}</span>
    <span flex="20">{{::'order.itemList.cardType' | translate}}</span>
    <span flex="10" class="text-right">{{::'order.itemList.amount' | translate}}</span>
    <span flex></span>
  </div>

  <div class="wb-table-item"
       layout="row"
       layout-align="start center"
       ng-repeat="orderItem in itemList.order.items"
       ng-hide="orderItem.fileName.length">
    <span flex="15">{{orderItem.lastName}} {{orderItem.firstName}}</span>
    <span flex="10">{{orderItem.dateOfBirth | date:'yyyy.MM.dd'}}</span>
    <span flex="15">{{orderItem.address.address}}</span>
    <span flex="10">{{orderItem.address.city}}</span>
    <span flex="5" class="text-center">{{orderItem.address.zipCode}}</span>
    <span flex="20">{{'order.invoice.cardType.' + orderItem.cardTypeId + '.title' | translate}}</span>
    <span flex="10" class="text-right">{{orderItem.loadAmount | isoCurrency:'HUF':0}}</span>
    <span flex class="text-right">
      <md-button class="md-icon-button md-button-sm" ng-click="itemList.editItem($index)">
        <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-edit"></md-icon>
      </md-button>

      <md-button class="md-icon-button md-button-sm" ng-click="itemList.deleteItem($index)">
        <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-delete"></md-icon>
      </md-button>
    </span>
  </div>
</div>
