<div class="wb-type-selector" layout="row">
  <md-card class="wb-type-card flex flex-auto {{::typeSelector.order | orderStatusClassName}}" flex="auto">
    <div class="wb-card-header">
      <h1 class="md-title">{{::'order.' + typeSelector.order.type + '.unique.title' | translate}}</h1>
      <h2 class="md-subtitle">{{::'order.' + typeSelector.order.type + '.unique.subtitle' | translate}}</h2>
      <md-button analytics-on="click" analytics-event="kattintas" analytics-category="typeselector" analytics-if="!order.isAuthenticated" ng-click="typeSelector.selectUnique()" class="md-fab {{::typeSelector.order | orderStatusClassName}}" aria-label="{{::'order.more' | translate}}">
        <md-icon md-font-icon="zmdi zmdi-chevron-right"></md-icon>
      </md-button>
    </div>
    <md-card-content>
      <p>{{::'order.' + typeSelector.order.type + '.unique.legend' | translate}}</p>
    </md-card-content>
  </md-card>
  <md-card class="wb-type-card flex flex-auto {{::typeSelector.order | orderStatusClassName}}" flex="auto">
    <div class="wb-card-header">
      <h1 class="md-title">{{::'order.' + typeSelector.order.type + '.bulk.title' | translate}}</h1>
      <h2 class="md-subtitle">{{::'order.' + typeSelector.order.type + '.bulk.subtitle' | translate}}</h2>
      <md-button analytics-on="click" analytics-event="kattintas" analytics-category="typeselector" analytics-if="!order.isAuthenticated" ng-click="typeSelector.selectBulk()" class="md-fab {{::typeSelector.order | orderStatusClassName}}" aria-label="{{::'order.more' | translate}}">
        <md-icon md-font-icon="zmdi zmdi-chevron-right"></md-icon>
      </md-button>
    </div>
    <md-card-content>
      <p>{{::'order.' + typeSelector.order.type + '.bulk.legend' | translate}}</p>
    </md-card-content>
  </md-card>
</div>

<md-card class="wb-card-info-section" ng-click="typeSelector.openComparisonDialog()" ng-if="typeSelector.isNewCardOrder()">
  <md-card-content layout="row" layout-align="start center" md-ink-ripple>
    <md-button class="wb-card-info-open md-fab not-a-button">
      <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-card"></md-icon>
    </md-button>

    <span class="wb-card-info-legend">{{::'order.cardInfo.open' | translate}}</span>
  </md-card-content>
</md-card>
